import React, { ReactNode } from "react";
import { TPropertyPage } from "properties/_models/PropertyMdl";

interface Props {
    details: { icon: string; render: (store: any) => ReactNode }[];
    property: TPropertyPage;
}

export default function PropertyHeaderTextList({ details, property }: Props) {
    return (
        <div className={"flex flex-col"}>
            <div className={"grid md:grid-cols-1 ml-2"}>
                {details.map((detail, index) => {
                    const renderedDetail = detail.render(property);
                    if (!renderedDetail) return null;
                    return (
                        <div key={index} className="flex items-center my-2">
                            <div className="w-10">
                                <img src={detail.icon} alt={"icon details"} />
                            </div>
                            <div className="flex flex-col ml-5">{renderedDetail}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
