const isDev = process.env.NODE_ENV === "development";
const isStaging = process.env.IS_STAGING === "true";
const isProd = process.env.NODE_ENV === "production" && !isStaging;

const languages = {
    en: {
        lang: "en",
        baseUrl: `${process.env.APP_URL}/en`,
        basePath: `/`,
        title: "English",
    },
    fr: {
        lang: "fr",
        baseUrl: `${process.env.APP_URL}/fr`,
        basePath: `/fr`,
        title: "Français",
    },
    es: {
        lang: "es",
        baseUrl: `${process.env.APP_URL}/es`,
        basePath: `/es`,
        title: "Spanish",
    },
    zh: {
        lang: "zh",
        baseUrl: `${process.env.APP_URL}/zh`,
        basePath: `/zh`,
        title: "中文",
    },
};

export type TLang = keyof typeof languages;

const sharedConfig = {
    env: process.env.NODE_ENV,
    isDev,
    isStaging,
    isProd,

    isInMaintenance: false,

    emailName: "zisla",
    email: "info@zisla.com",
    emailNamePipedrive: "zislaPipedrive",
    emailPipedrive: isProd ? "zislaa@pipedrivemail.com" : "contact@lesentrecodeurs.com",
    phone: "(438) 389-8981",
    mexPhone: "+52 998 287 6706",
    caPhone: "1 (800) 558-1768",

    defaultLang: "en" as TLang, // langue par défaut, utilisé si des textes ne sont pas présent dans une autre langue
    languages,

    apiUrl: process.env.API_URL as string,
    appUrl: process.env.APP_URL as string,

    sentry: {
        dsn: isProd
            ? "https://d88bc5d8cdf127ee474f68f92d89af8e@sentry.lesentrecodeurs.com/3"
            : "https://7b36e41cf2e1eab211eb9c07cafefe0c@sentry.lesentrecodeurs.com/4",
    },

    uploadsBaseUrl: "/static/uploads/zisla",

    nesto: {
        apiKey: "m3vmp84wCEGUH39R8xMhKmBEDNBMPuymktMAY3BGw3MT",
        partner: "zisla",
    },

    nbMaxFilesUploading: 50,
    maxFiles: 10,
    maxUploadSize: 20000000,

    utm: {
        queryString: "?utm_medium=referral&utm_source=Zisla&utm_campaign=04131000",
    },
    tinyMce: {
        apiKey: "8mycfs1mzu21dudcyujyurx3bmgyhqp2pro6pg06ybzv2tqt",
    },
    reCaptcha: {
        url: "https://www.google.com/recaptcha/api.js",
        siteKey: isDev ? "6LflHDEpAAAAANhImS0tHSkMygAy0-0QxVVh3tgm" : "6LflHDEpAAAAANhImS0tHSkMygAy0-0QxVVh3tgm",
    },
};

export default sharedConfig;
