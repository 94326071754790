import React from "react";
import { TPropertyCard } from "properties/_models/PropertyMdl";
import { CardGalleryLabelType } from "properties/propertyCard/components/PropertyCardComponents";
import { useTranslation } from "react-i18next";
import { CardFavoriteButton } from "properties/propertyCard/components/CardFavoriteButton";
import { PropertyNewBadge } from "properties/propertyCard/components/PropertyNewBadge";
import { NoSsr } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";

type Props = {
    property: TPropertyCard;
    isLuxury?: boolean;
};

export function PropertyCardGallery({ property, isLuxury }: Props) {
    const { t } = useTranslation();

    return (
        <div className="w-full overflow-hidden h-[277px] group">
            <Helmet>
                <link rel="preload" as="image" href={property.photos?.[0]?.url} />
                <link rel="preload" as="image" href={property.photos?.[1]?.url} />
            </Helmet>
            <LazyLoadImage
                src={property.photos?.[1]?.url}
                alt={"property image"}
                title={`${property.localized.title} photo 2`}
                className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ease-in-out opacity-0 group-hover:opacity-100 rounded-lg"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    // currentTarget.src = new URL(`https://zisla.com/${property.photos?.[1]?.url}`).href;
                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                }}
            />

            <LazyLoadImage
                src={property.photos?.[0]?.url}
                alt={"property image"}
                title={`${property.localized.title} photo 1`}
                className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ease-in-out opacity-100 group-hover:opacity-0 rounded-lg"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    // currentTarget.src = new URL(`https://zisla.com/${property.photos?.[0]?.url}`).href;
                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                }}
            />

            <CardGalleryLabelType
                type={t("property.typeLabels." + property.type)}
                className={clsx({ "-rotate-90 w-[80px] h-[30px] pr-5 pl-1 -left-2 top-6": isLuxury })}
            />
            <NoSsr>
                <PropertyNewBadge date={property.firstDayOnSite} />
            </NoSsr>
            <NoSsr>
                <CardFavoriteButton item={property} resource={MAIN_RESOURCE.PROPERTIES} />
            </NoSsr>
        </div>
    );
}
