import { observable } from "mobx";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";

export class UnitStore {
    @observable unit: TUnitListingMdl;

    constructor(unit: TUnitListingMdl) {
        this.unit = unit;
    }
}
