import React from "react";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useParams } from "react-router";
import { PageNotFound } from "pages/PageNotFound";
import { PropertyView } from "properties/propertyPage/PropertyView";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { propertiesStore } from "properties/_stores/propertiesStore";

require("dayjs/locale/fr");
require("dayjs/locale/en");
require("dayjs/locale/es");
require("dayjs/locale/zh");

export function PropertyPage() {
    const { propertyAlias } = useParams();
    const propertyOrFetch = propertiesStore.getByAliasUrl(propertyAlias);

    const { loading } = useLoadingFromPromise(
        !(propertyOrFetch && "localized" in propertyOrFetch) ? propertyOrFetch : undefined,
    );
    if (!propertyOrFetch) return null;
    if ("localized" in propertyOrFetch) {
        return <PropertyView property={propertyOrFetch} />;
    }
    if (!loading) return null;
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, error, property) => {
                if (error) {
                    return <ErrorBlock error={error} />;
                }
                if (!property) return <PageNotFound />;
                return <PropertyView property={property} />;
            }}
        />
    );
}
