import React from "react";
import { List, MapOutlined } from "@material-ui/icons";
import { usePropertyPageContext } from "properties/PropertyPageContextAlt";
import { PROPERTY_VIEW_STYLE } from "properties/components/BREAKPOINTS_SECTION_CARDS";
import { Button } from "_common/ui/mui/buttons/Button";
import { observer } from "mobx-react-lite";

export const PropertiesViewButtonMapList = observer(() => {
    const { mapOrListSelected, setMapOrListSelected } = usePropertyPageContext();

    return (
        <Button
            onClick={() =>
                setMapOrListSelected(
                    mapOrListSelected === PROPERTY_VIEW_STYLE.MAP ? PROPERTY_VIEW_STYLE.LIST : PROPERTY_VIEW_STYLE.MAP,
                )
            }
            color={"primary"}
            variant={"contained"}
        >
            <>
                {mapOrListSelected === PROPERTY_VIEW_STYLE.MAP ? (
                    <List className="mr_5" fontSize={"default"} />
                ) : (
                    <MapOutlined className="mr_5" fontSize={"default"} />
                )}
            </>
            {mapOrListSelected === PROPERTY_VIEW_STYLE.MAP ? "List" : "Map"}
        </Button>
    );
});
