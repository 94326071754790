import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
import { TImage } from "_common/types/baseTypes";
import GalleryPagination from "properties/propertyPage/propertyView/GalleryPagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { VideoComponent } from "components/base/video/VideoComponent";
import { galleryStore } from "gallery/_store/GalleryStore";
import { observer } from "mobx-react-lite";

interface Props {
    images?: TImage[];
    videos?: string[];
}

export const PropertyViewGallerySwiper = observer(({ images, videos }: Props) => {
    return (
        <>
            <Swiper
                onSlideChange={(e) => {
                    galleryStore.setOpenedGalleryModal(e.realIndex);
                }}
                loop
                slidesPerView={1}
                centeredSlides
                navigation={{
                    prevEl: `.swiper-modal-button-prev.unitViewImageSlider`,
                    nextEl: `.swiper-modal-button-next.unitViewImageSlider`,
                }}
                spaceBetween={20}
                observeParents
                observer
                initialSlide={galleryStore.openedGalleryModal}
                className={"text-center h-full"}
            >
                {images &&
                    images.map((image, idx) => {
                        return (
                            <>
                                {image.url && !image.url.endsWith(".pdf") && (
                                    <SwiperSlide key={idx}>
                                        <LazyLoadImage
                                            className={
                                                "absolute -translate-x-2/4 -translate-y-2/4 h-full object-contain rounded-[var(--border-radius)] left-2/4 top-2/4 select-none"
                                            }
                                            src={image.url}
                                            alt={"property_picture"}
                                        />
                                    </SwiperSlide>
                                )}
                            </>
                        );
                    })}
                {videos &&
                    videos.map((video, idx) => {
                        return (
                            <>
                                <SwiperSlide key={idx}>
                                    <VideoComponent url={video} size={"100%"} />
                                </SwiperSlide>
                            </>
                        );
                    })}
            </Swiper>
            <GalleryPagination
                currentImage={galleryStore.openedGalleryModal}
                totalImages={images?.filter((i) => !i.url?.endsWith(".pdf")).length || (videos?.length as number)}
                prevClass={`swiper-modal-button-prev unitViewImageSlider`}
                nextClass={`swiper-modal-button-next unitViewImageSlider`}
            />
        </>
    );
});
