import React from "react";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { useTranslation } from "react-i18next";
import { rounded } from "_common/_utils/alphaNumUtils";

type Props = {
    surfaces: {
        interiorConstruction?: number;
        exteriorConstruction?: number;
        terraceSurface?: number;
        rooftopSurface?: number;
        yardSize?: number;
        poolSurface?: number;
        storageSurface?: number;
        parkingSurface?: number;
        totalConstructArea?: number;
        lotSurface?: number;
        cus?: number;
        cos?: number;
    };
};

export function UnitDetailsSurfaces({ surfaces }: Props) {
    const { t } = useTranslation();
    return (
        <div className={"text-sm"}>
            {Object.entries(surfaces).map(([surfaceKey, value]) => {
                if (!value) return null;
                return (
                    <div key={surfaceKey} className={"flex flex-col gap-1 lg:flex-row lg:gap-2"}>
                        <div className={"font-bold"}>{t(`unitPage.header.${surfaceKey}`)}</div>
                        <div>
                            {t(`unitPage.header.surfaces`, {
                                surfaceM: rounded(value, 2),
                                surfaceFt: convert(value, UNIT_CONVERSION.M2_TO_FT2_BACK),
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
