import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import facebook from "../../assets/images/networks/facebook.svg";
import whatsapp from "../../assets/images/networks/whatsapp.svg";
import instagram from "../../assets/images/networks/instagram.svg";
import linkedIn from "../../assets/images/networks/linkedIn.svg";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/footerSocial.module.css";

export function FooterSocial() {
    const settings = settingsStore.getOne() as ISettingsMdl;

    const { t } = useTranslation();
    return (
        <>
            {settings?.links && (
                <>
                    <div className="text-xl text-center sm:text-left mb-5 font-semibold text-white">
                        {t("footer.socialNetworks.title")}
                    </div>
                    <div className={clsx(styles.socialContainer, "flex flex-row gap-3")}>
                        {settings.links.fb && (
                            <a href={settings.links.fb} className="w-[42px] sm:w-[32px]">
                                <LazyLoadImage className={"h-full w-full"} src={facebook} />
                            </a>
                        )}
                        {settings.links.wa && (
                            <a className="w-[42px] sm:w-[32px]" href={settings.links.wa}>
                                <LazyLoadImage className={"h-full w-full"} src={whatsapp} />
                            </a>
                        )}
                        {settings.links.ig && (
                            <a className="w-[42px] sm:w-[32px]" href={settings.links.ig}>
                                <LazyLoadImage className={"h-full w-full"} src={instagram} />
                            </a>
                        )}
                        {settings.links.li && (
                            <a className="w-[42px] sm:w-[32px]" href={settings.links.li}>
                                <LazyLoadImage className={"h-full w-full"} src={linkedIn} />
                            </a>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
