import React from "react";
import styles from "../_css/propertyCardPriceValue.module.css";
import { calculateAndFormatPropertyPrice } from "_common/_utils/propertyUtils";
import { NoSsr } from "@material-ui/core";
import { useCurrency } from "_common/currency/CurrencyProvider";
import { useTranslation } from "react-i18next";

type Props = {
    priceMin: number;
    priceInPesosMin: number;
    isInPesosProperty: boolean;
    isNotFrom?: boolean;
    textSize?: string;
};

export function PropertyCardPriceValue({ priceMin, priceInPesosMin, isInPesosProperty, isNotFrom, textSize }: Props) {
    const { currentCurrency } = useCurrency();
    const { t } = useTranslation();
    const priceForCalculate = {
        priceMin: priceMin,
        priceInPesosMin: priceInPesosMin,
        priceIsInPesos: isInPesosProperty,
    };
    const price = calculateAndFormatPropertyPrice(priceForCalculate, currentCurrency);
    // const priceEUR = calculateAndFormatPropertyPrice(priceForCalculate, CURRENCY_OPTIONS.EUR);
    // const priceMXN = calculateAndFormatPropertyPrice(priceForCalculate, CURRENCY_OPTIONS.MXN);
    // const priceUSD = calculateAndFormatPropertyPrice(priceForCalculate, CURRENCY_OPTIONS.USD);
    // const priceCAD = calculateAndFormatPropertyPrice(priceForCalculate, CURRENCY_OPTIONS.CAD);
    if (!price) return null;
    return (
        <NoSsr>
            {!isNotFrom && t("words.from")}
            <div className={`${styles.container} ${textSize}`}>{price}</div>
            {/*<div className={"flex flex-col items-center"}>*/}
            {/*<div className={styles.container}>{priceEUR}</div>*/}
            {/*<div className={styles.container}>{priceMXN}</div>*/}
            {/*<div className={styles.container}>{priceUSD}</div>*/}
            {/*<div className={styles.container}>{priceCAD}</div>*/}
            {/*</div>*/}
        </NoSsr>
    );
}
