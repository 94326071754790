import React from "react";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import phone from "../assets/images/aboutUs/phone.svg";
import whatsApp from "../assets/images/aboutUs/whatsApp.svg";
import email from "../assets/images/aboutUs/email.svg";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PHONE, WHATSAPP_PHONE } from "contact/ContactUsForm";
import { userStore } from "users/_stores/userStore";
import { AC_TAGS } from "users/_models/UserMdl";
import sharedConfig from "_configs/sharedConfig";

export function AboutUsBookConsultation() {
    const { t } = useTranslation();
    const settings = settingsStore.getOne() as ISettingsMdl;

    if (!settings) return null;
    return (
        <div
            className={
                "flex flex-row mt-10 md:mt-20 rounded-lg mb-20 border border-[var(--color-primary)] md:mb-24 px-[25px] py-[40px] md:py-[62px] md:px-[103px]"
            }
        >
            <Container className={"!px-0"}>
                <div className={"flex flex-col md:flex-row"}>
                    <div className={"flex-3 md:pr-14"}>
                        <div
                            className={"font-extrabold text-[32px] leading-[40px]"}
                            dangerouslySetInnerHTML={{
                                __html: t("aboutUsPage.consultation.title", { color: "#5FB8DF" }),
                            }}
                        />
                        <div
                            className={"mt_40 font-normal text-base text-[var(--color-primary)]"}
                            dangerouslySetInnerHTML={{ __html: t("aboutUsPage.consultation.text") }}
                        />
                        <div className={"flex flex-row mt_40"}>
                            <UiButton
                                className={
                                    "!normal-case !text-white h-[62px] !rounded-[35px] !px-10 !py-2.5 !text-base !font-normal !text-center !bg-[var(--color-primary)]"
                                }
                                onClick={() => {
                                    userStore.openContactModalDisplayed(AC_TAGS.L8_CONTACT_US_ABOUT_US, {
                                        title: t("homePage.contactUs.title"),
                                    });
                                }}
                            >
                                {t("aboutUsPage.consultation.freeConsultation")}
                            </UiButton>
                        </div>
                    </div>
                    <div className="hidden lg:flex lg:items-center">
                        <div className="h-[280px] border-r border-[var(--color-primary)]"></div>
                    </div>
                    <div className={"flex-2 flex items-center md:justify-center"}>
                        <div>
                            <div className={"flex-col mt_40 space-y-5"}>
                                <div className={"text-base font-bold"}>{t("aboutUsPage.consultation.orContactUs")}</div>
                                <div className={"flex flex-row items-center flex-1"}>
                                    <LazyLoadImage src={whatsApp} alt={"WhatsApp"} title={"WhatsApp"} />
                                    <div className={"ml_20"}>
                                        <div>{t("aboutUsPage.consultation.whatsApp")}</div>
                                        <a href={"https://wa.me/5149325688"} target="_blank" rel="noreferrer">
                                            {WHATSAPP_PHONE}
                                        </a>
                                    </div>
                                </div>
                                <div className={"flex flex-row items-center flex-1"}>
                                    <LazyLoadImage src={phone} alt={"Phone"} title={"Phone"} />
                                    <div className={"ml_20"}>
                                        <div>{t("aboutUsPage.consultation.phone")}</div>
                                        <div className={"flex flex-col"}>
                                            <a href={"tel:" + settings?.contacts?.phone ?? PHONE}>
                                                {t("aboutUsPage.consultation.phoneLabel")}{" "}
                                                {settings?.contacts?.phone ?? PHONE}
                                            </a>
                                            <a href={"tel:" + settings?.contacts?.mexicanPhone ?? PHONE}>
                                                {t("aboutUsPage.consultation.mexicanPhoneLabel")}{" "}
                                                {settings?.contacts?.mexicanPhone ?? PHONE}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className={"flex flex-row"}>
                                    <LazyLoadImage src={email} alt={"Email"} title={"Email"} />
                                    <div className={"ml_20 flex items-center"}>
                                        <a href={"mailto:" + sharedConfig.email}>
                                            {t("aboutUsPage.consultation.emailUs")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
