import React, { createContext, PropsWithChildren, useContext } from "react";
import { UnitTypeStore } from "unitTypes/_store/UnitTypeStore";

const UnitTypeCtxt = createContext<UnitTypeStore>({} as UnitTypeStore);

type Props = {
    unitTypeStore: UnitTypeStore;
};

export function useUnitTypeStore() {
    return useContext(UnitTypeCtxt);
}

export function UnitTypeContext(props: PropsWithChildren<Props>) {
    return <UnitTypeCtxt.Provider value={props.unitTypeStore}>{props.children}</UnitTypeCtxt.Provider>;
}
