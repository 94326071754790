import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    bathrooms: number;
    powderRooms?: number;
};

export function UnitDetailBathrooms({ bathrooms, powderRooms }: Props) {
    const { t } = useTranslation();
    const text = t("unitPage.header.bath", { count: bathrooms ?? 0 });
    const powder = powderRooms ? ` + ${t("unitPage.header.powder", { count: powderRooms ?? 0 })}` : "";
    return (
        <div className={"text-sm"}>
            {text}
            {powder}
        </div>
    );
}
