import React from "react";

type Props = {
    size?: number;
    color?: string;
};

export function IconPropertyDetails({ color, size }: Props) {
    return (
        <svg
            width={size ? (size * 26) / 28 : 26}
            height={size ?? "28"}
            viewBox="0 0 26 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4286_1009)">
                <path
                    d="M20.6022 26.759H3.28218V13.051C3.28218 12.7085 2.99974 12.4297 2.65281 12.4297C2.30588 12.4297 2.02344 12.7085 2.02344 13.051V27.3781C2.02344 27.7206 2.30588 27.9994 2.65281 27.9994H20.6022C20.9491 27.9994 21.2315 27.7206 21.2315 27.3781C21.2315 27.0356 20.9491 26.7568 20.6022 26.7568V26.759Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M21.8876 13.6719H12.9341C12.5871 13.6719 12.3047 13.9507 12.3047 14.2932C12.3047 14.6357 12.5871 14.9145 12.9341 14.9145H21.8876C22.2345 14.9145 22.517 14.6357 22.517 14.2932C22.517 13.9507 22.2345 13.6719 21.8876 13.6719Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M25.7458 9.12514L13.4163 0.121844C13.1939 -0.0406147 12.8915 -0.0406147 12.6691 0.121844L0.255087 9.18661C-0.0251288 9.39078 -0.082951 9.77936 0.123875 10.056C0.246191 10.2206 0.437449 10.3085 0.630931 10.3085C0.75992 10.3085 0.891132 10.2689 1.00455 10.1877L13.0405 1.39517L24.9963 10.1262C25.2766 10.3304 25.6702 10.2711 25.877 9.99671C26.0838 9.72009 26.0238 9.3315 25.7458 9.12733V9.12514Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M9.37135 15.5059C9.38915 15.5059 9.40694 15.5059 9.42695 15.5037C9.6449 15.4839 9.83616 15.3566 9.93401 15.1634L11.6909 11.6947C11.8466 11.3873 11.7198 11.0141 11.4107 10.8626C11.0993 10.709 10.7213 10.8341 10.5678 11.1393L9.26905 13.7035L8.66637 12.9022C8.45954 12.6277 8.06591 12.5685 7.78569 12.7726C7.5077 12.9768 7.44765 13.3654 7.65448 13.642L8.86652 15.2578C8.98661 15.4159 9.17342 15.5081 9.37135 15.5081V15.5059Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M21.8876 21.0547H12.9341C12.5871 21.0547 12.3047 21.3335 12.3047 21.676C12.3047 22.0185 12.5871 22.2973 12.9341 22.2973H21.8876C22.2345 22.2973 22.517 22.0185 22.517 21.676C22.517 21.3335 22.2345 21.0547 21.8876 21.0547Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M7.65448 21.0304L8.86652 22.6462C8.98661 22.8043 9.17342 22.8965 9.37135 22.8965C9.38915 22.8965 9.40694 22.8965 9.42695 22.8943C9.6449 22.8746 9.83616 22.7472 9.93401 22.554L11.6909 19.0853C11.8466 18.778 11.7198 18.4047 11.4107 18.2533C11.0993 18.0996 10.7213 18.2247 10.5678 18.5299L9.26905 21.0941L8.66637 20.2928C8.45954 20.0184 8.06591 19.9591 7.78569 20.1633C7.5077 20.3674 7.44765 20.756 7.65448 21.0326V21.0304Z"
                    fill={color ?? "#1A3A45"}
                />
            </g>
            <defs>
                <clipPath id="clip0_4286_1009">
                    <rect width="26" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
