import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PropertyViewSmallImg } from "properties/propertyPage/PropertyViewSmallImg";
import { GALLERY_TABS } from "_configs/propertyConfig";
import { PropertyViewSlider } from "properties/propertyPage/propertyView/PropertyViewSlider";
import { observer } from "mobx-react-lite";
import { cn } from "_configs/twconfig";
import { getYouTubeVideoId } from "communities/components/CommunitiesDiscover";
import { galleryStore } from "gallery/_store/GalleryStore";

export const PropertyViewGallery = observer(() => {
    const propertyStore = usePropertyStore();
    const property = propertyStore.property;
    const hasShowroomOrSecondImage =
        (!!property.showroom && property.showroom.length > 0 && !!property.showroom[0]?.url) ||
        property.photos.length > 1;
    const hasVideoOrThirdImage = !!property.video || property.photos.length > 2;
    const has3DFloorOrFourthImage = !!property.visit3D || property.photos.length > 3;

    return (
        <div className={"flex flex-col md:flex-row sm:mb-6 w-full gap-2 max-h-[600px]"}>
            <div
                className={cn("w-full", {
                    "md:w-[calc(100%-290px)]":
                        hasShowroomOrSecondImage || hasVideoOrThirdImage || has3DFloorOrFourthImage,
                })}
            >
                <PropertyViewSlider />
            </div>
            {(hasShowroomOrSecondImage || hasVideoOrThirdImage || has3DFloorOrFourthImage) && (
                <div className={cn("flex md:flex-col rounded gap-1 md:w-[290px]")}>
                    {hasShowroomOrSecondImage && (
                        <PropertyViewSmallImg
                            url={property.showroom?.[0]?.url ?? property.photos[1].url}
                            type={property.showroom?.[0]?.url ? "Showroom" : "Image"}
                            onClick={() => {
                                galleryStore.setOpenedGalleryTab(
                                    property.showroom?.[0]?.url ? GALLERY_TABS.SHOWROOM : GALLERY_TABS.GALLERY,
                                );
                            }}
                            className={"min-h-[75px]"}
                        />
                    )}
                    {hasVideoOrThirdImage && (
                        <PropertyViewSmallImg
                            url={property.video ? getYouTubeVideoId(property.video) : property.photos[2].url}
                            type={property.video ? "Video" : "Image"}
                            onClick={() => {
                                galleryStore.setOpenedGalleryTab(
                                    property.video ? GALLERY_TABS.VIDEO : GALLERY_TABS.GALLERY,
                                );
                            }}
                            className={"min-h-[75px]"}
                        />
                    )}
                    {has3DFloorOrFourthImage && (
                        <PropertyViewSmallImg
                            url={property.visit3D ? property.visit3D : property.photos[3].url}
                            type={property.visit3D ? "3DTour" : "Image"}
                            onClick={() => {
                                galleryStore.setOpenedGalleryTab(
                                    property.visit3D ? GALLERY_TABS.TOUR_3D : GALLERY_TABS.GALLERY,
                                );
                            }}
                            className={"min-h-[75px]"}
                        />
                    )}
                </div>
            )}
        </div>
    );
});
