import React from "react";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { AC_TAGS } from "users/_models/UserMdl";

export function HomePageConsultation() {
    const { t } = useTranslation();

    return (
        <div>
            <div
                className="flex items-center justify-center fixed bottom-5 right-5 text-[12px] w-[110px] h-[65px] bg-[var(--color-secondary)] rounded-[20px] rounded-br-[0] font-semibold cursor-pointer px-[20px] py-[12px] leading-[1.3] z-[var(--zIndex-top-navBar)] md:top-[calc(var(--height-navbarNew)_+_620px_-_65px_-_15px)] md:right-[60px] md:w-[160px] md:text-[16px] md:rounded-[20px] md:rounded-tr-[0]"
                onClick={() => {
                    userStore.openContactModalDisplayed(AC_TAGS.L9_HOME_PAGE, { title: t("homePage.contactUs.title") });
                }}
            >
                <div className="!text-white">{t("homePage.consultation")}</div>
            </div>
        </div>
    );
}
