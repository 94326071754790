import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { CardTypesList } from "home/CardTypesList";
import { useTranslation } from "react-i18next";
import { Button } from "_common/ui/mui/buttons/Button";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { propertiesStore } from "properties/_stores/propertiesStore";

export function HomeCardCitiesSection() {
    propertiesStore.fetchSortedCities();
    const { t } = useTranslation();

    return (
        <>
            <h2 className={"mb_30 headerHome colorPrimary"}>
                {t(`homePage.swiperLinks.cities.title`)}{" "}
                <span className={"colorSecondary"}>{t(`homePage.swiperLinks.cities.title2`)}</span>
            </h2>
            <LoadableFromLoading
                loading={propertiesStore.sortCitiesState}
                renderer={(_status, error) => {
                    if (error) return null;
                    return (
                        <CardTypesList
                            removeGeoZone
                            items={propertiesStore.sortCities}
                            breakPoints={{
                                500: {
                                    slidesPerView: 1.5,
                                },
                                600: {
                                    slidesPerView: 2,
                                },
                                750: {
                                    slidesPerView: 2.5,
                                },
                                900: {
                                    slidesPerView: 3,
                                },
                                1280: {
                                    slidesPerView: 4,
                                },
                            }}
                        />
                    );
                }}
            />
            <div className={"flex items-center justify-center mt_20"}>
                <Link to={URLS.buy()}>
                    <Button variant={"outlined"} color={"primary"} size={"large"}>
                        {t("homePage.viewAllProperties")}
                    </Button>
                </Link>
            </div>
        </>
    );
}
