import React from "react";
import { CollapseSection } from "_common/ui/components/CollapseSection";
import { TPropertyPage } from "properties/_models/PropertyMdl";
import propertyDescription from "../../assets/images/icons/propertyDescription.svg";
import amenities from "../../assets/images/icons/amenities.svg";
import gatedCommunity from "../../assets/images/icons/gatedCommunity.svg";
import payment from "../../assets/images/icons/payment.svg";
import progress from "../../assets/images/icons/progress.svg";
import propertyPhases from "../../assets/images/icons/propertyPhases.svg";
import { useTranslation } from "react-i18next";
import { Separator } from "_common/ui/components/Separator";
import { PropertyDescription } from "properties/propertyPage/PropertyDescription";
import { PropertyPhases } from "properties/propertyPage/PropertyPhases";
import { PropertyAmenities } from "properties/propertyPage/PropertyAmenities";
import FinancingTable from "properties/propertyPage/FinancingTable";
import { PropertyDevelopment } from "properties/propertyPage/PropertyDevelopment";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PropertyCommunities } from "properties/propertyPage/PropertyCommunities";

export function PropertyCollapsibleSections() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const propertySections = [
        {
            title: `${t("propertyPage.section.description")}`,
            icon: propertyDescription,
            component: (property: TPropertyPage) => {
                if (!property.localized.description) return null;
                return <PropertyDescription description={property.localized.description} />;
            },
            key: "property.localized.description",
        },
        {
            title: `${t("propertyPage.section.phases")}`,
            icon: propertyPhases,
            component: (property: TPropertyPage) => {
                if (!property.inventory?.phases?.length) return null;
                return <PropertyPhases />;
            },
        },
        {
            title: `${t("propertyPage.section.amenities")}`,
            icon: amenities,
            component: (property: TPropertyPage) => {
                if (!property.amenities && !property.features && !property.fees) return null;
                return (
                    <PropertyAmenities
                        amenities={property.amenities}
                        features={property.features}
                        fees={property.fees}
                    />
                );
            },
        },
        // {
        //     title: `${t("propertyPage.section.history")}`,
        //     icon: history,
        //     component: () => <div>HISTORIC TABLEAU</div>,
        // },
        {
            title: `${t("propertyPage.section.gatedCommunity")}`,
            icon: gatedCommunity,
            component: (property: TPropertyPage) => {
                if (!property.communities || property.communities.length === 0) return null;
                return <PropertyCommunities />;
            },
        },
        {
            title: `${t("propertyPage.section.payment")}`,
            icon: payment,
            component: (property: TPropertyPage) => {
                if (!property.financing || property.financing.length === 0) return null;
                return <FinancingTable financing={property.financing} />;
            },
        },
        {
            title: `${t("propertyPage.section.development")}`,
            icon: progress,
            component: (property: TPropertyPage) => {
                if (!property.floorPlans.length && !property.constructProgress.length && !property.finishings.length) {
                    return null;
                }
                return <PropertyDevelopment />;
            },
        },
    ];

    return (
        <div className={"mx-2.5 md:mx-0"}>
            <Separator />
            {propertySections.map((section, index) => {
                if (!section?.component(property)) return null;
                return (
                    <div key={index}>
                        <CollapseSection
                            key={index}
                            icon={section.icon}
                            title={section.title}
                            tag={"h2"}
                            heightOfCollapsable={0}
                            titleClassName={"text-base font-semibold"}
                            className={"pl-0 pr-0 py-4"}
                            keyboardArrowDownSize={"small"}
                        >
                            <div className={"text-base pb-[18px]"}>{section.component(property)}</div>
                        </CollapseSection>
                        <Separator />
                    </div>
                );
            })}
        </div>
    );
}
