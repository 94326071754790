import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { Checkbox } from "_common/ui/mui/checkbox/Checkbox";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { TextArea } from "_common/ui/forms/TextArea";
import { FormControlLabel } from "@material-ui/core";
import { useWindowSize } from "_common/_utils/hookWindow";
import { useTranslation } from "react-i18next";

export function PropertyContactFields() {
    const { t } = useTranslation();
    const form = useFormContext();
    const onAgentChange = (value: string) => {
        form.setValue("workingWithAnAgent", value);
    };

    const onNewsletterChange = (value: boolean) => {
        form.setValue("newsletter", value);
    };

    const size = useWindowSize();
    return (
        <div className={"space-y-3"}>
            <div>
                <InputBlock label={t("propertyPage.contactForm.fullName")} required className={"hidden md:block"} />
                <Controller
                    as={Input}
                    name="name"
                    control={form.control}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                    placeholder={t("propertyPage.contactForm.fullName") + (size.width < 680 ? "*" : "")}
                />
            </div>
            <div>
                <InputBlock label={t("propertyPage.contactForm.email")} required className={"hidden md:block"} />
                <Controller
                    as={Input}
                    name="email"
                    control={form.control}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                    placeholder={t("propertyPage.contactForm.email") + (size.width < 680 ? "*" : "")}
                    required
                    rules={{
                        required: t<string>("errors.forms.required"),
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t("errors.forms.emailInvalid"),
                        },
                    }}
                />
            </div>
            <div>
                <div className={"text-sm"}>{t("propertyPage.contactForm.workingWithAgent")}</div>
                <Controller
                    as={
                        <>
                            <Checkbox
                                checked={form.watch("workingWithAnAgent") === "yes"}
                                onChange={() => onAgentChange("yes")}
                            />
                            <label className="mr-2 text-sm">{t("words.yes")}</label>
                            <Checkbox
                                checked={form.watch("workingWithAnAgent") === "no"}
                                onChange={() => onAgentChange("no")}
                            />
                            <label className="mr-2 text-sm">{t("words.no")}</label>
                            <Checkbox
                                checked={form.watch("workingWithAnAgent") === "I am an agent"}
                                onChange={() => onAgentChange("I am an agent")}
                            />
                            <label className="mr-2 text-sm">{t("propertyPage.contactForm.iAmAgent")}</label>
                        </>
                    }
                    name="workingWithAnAgent"
                    control={form.control}
                />
            </div>
            <div>
                <InputBlock label={t("propertyPage.contactForm.phone")} required className={"hidden md:block"} />
                <Controller
                    as={InputPhone}
                    name="phone"
                    control={form.control}
                    placeholder={t("propertyPage.contactForm.phone")}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                />
            </div>
            <div>
                <InputBlock label={t("propertyPage.contactForm.howCanAgentHelp")} />
                <Controller
                    as={TextArea}
                    name="text"
                    control={form.control}
                    rows={3}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                    placeholder={"Message" + (size.width < 680 ? "*" : "")}
                />
            </div>
            <FormControlLabel
                control={
                    <Controller
                        name="newsletter"
                        control={form.control}
                        render={({ onChange, value }) => (
                            <Checkbox
                                checked={value}
                                onChange={(e) => {
                                    onChange(e.target.checked);
                                    onNewsletterChange(e.target.checked);
                                }}
                            />
                        )}
                    />
                }
                label={<div className="text-xs ml-2 max-w-[350px]">{t("contactPage.form.newsletter")}</div>}
            />
        </div>
    );
}
