import React from "react";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";
import { CardContent, CardTitle } from "properties/propertyCard/components/PropertyCardComponents";
import { PropertyCardTitle } from "properties/propertyCard/components/PropertyCardTitle";
import { PropertyCardSubTitle } from "properties/propertyCard/components/PropertyCardSubTitle";
import { PropertyCardSurfaces } from "properties/propertyCard/components/PropertyCardSurfaces";
import { PropertyCardPrice } from "properties/propertyCard/components/PropertyCardPrice";
import { PropertyCardRooms } from "properties/propertyCard/components/PropertyCardBathrooms";

type Props = {
    unitType: TUnitTypeListingMdl;
    isInPesosProperty: boolean;
};

export function UnitTypeCardInfos({ unitType, isInPesosProperty }: Props) {
    return (
        <>
            <div className={"mb-2.5"}>
                <CardTitle>
                    <PropertyCardTitle title={unitType.localized.title} />
                </CardTitle>
                <CardTitle>
                    <PropertyCardSubTitle
                        priceMin={unitType.price.min}
                        priceInPesosMin={unitType.priceInPesos.min}
                        isInPesosProperty={isInPesosProperty}
                        isPriceNotDisplayed={unitType.priceIsNotDisplayed}
                    />
                </CardTitle>
            </div>
            <div className={"mb-2.5"}>
                <CardContent className={"mb-1"}>
                    <div className="flex gap-2">
                        {!!unitType.bedrooms && <PropertyCardRooms min={unitType.bedrooms} />}
                        {!!unitType.bathrooms && <PropertyCardRooms min={unitType.bedrooms} isBath />}
                        {!!unitType.totalConstructArea?.min && (
                            <PropertyCardSurfaces surfaceMin={unitType.totalConstructArea.min} />
                        )}
                    </div>
                </CardContent>
            </div>
            <PropertyCardPrice
                isInPesosProperty={isInPesosProperty}
                isPriceNotDisplayed={unitType.priceIsNotDisplayed}
                priceInPesosMin={unitType.priceInPesos?.min ?? 0}
                priceMin={unitType.price?.min ?? 0}
            />
        </>
    );
}
