import React from "react";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { Inquiry } from "components/misc/contactUs/Inquiry";
import i18next from "i18next";
import { FormControlLabel, MenuItem } from "@material-ui/core";
import { Checkbox } from "_common/ui/mui/checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "_common/_utils/hookWindow";
import { TextArea } from "_common/ui/forms/TextArea";

type Props = {
    hideInquiries?: boolean;
};

export function ContactFields({ hideInquiries }: Props) {
    const { t } = useTranslation();
    const form = useFormContext();
    const size = useWindowSize();
    const onAgentChange = (value: string) => {
        form.setValue("workingWithAnAgent", value);
    };

    const onNewsletterChange = (value: boolean) => {
        form.setValue("newsletter", value);
    };

    return (
        <div className={"space-y-3"}>
            <InputBlock label={t("contactPage.form.name")} required>
                <Controller
                    as={Input}
                    name="name"
                    control={form.control}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                    placeholder={t("contactPage.form.name") + (size.width < 680 ? "*" : "")}
                />
            </InputBlock>
            <InputBlock label={t("contactPage.form.email")} required>
                <Controller
                    as={Input}
                    name="email"
                    control={form.control}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                    placeholder={t("contactPage.form.email") + (size.width < 680 ? "*" : "")}
                    required
                    rules={{
                        required: t<string>("errors.forms.required"),
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t("errors.forms.emailInvalid"),
                        },
                    }}
                />
            </InputBlock>
            <InputBlock label={t("contactPage.form.phone")} required>
                <Controller
                    as={InputPhone}
                    name="phone"
                    control={form.control}
                    placeholder={t("contactPage.form.phone")}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                />
            </InputBlock>
            {!hideInquiries && (
                <div>
                    <InputBlock label={t("contactPage.form.inquiry")} required>
                        <Controller
                            as={
                                <UiSelect className={"w-full"}>
                                    {Inquiry[i18next.language].map((object, key) => (
                                        <MenuItem className={"!text-sm"} value={object} key={key}>
                                            {object}
                                        </MenuItem>
                                    ))}
                                </UiSelect>
                            }
                            defaultValue={Inquiry[i18next.language][0]}
                            name="inquiry"
                            control={form.control}
                        />
                    </InputBlock>
                </div>
            )}
            {form.watch("inquiry") === Inquiry[i18next.language][0] && (
                <div>
                    <div className={"text-sm"}>{t("contactPage.form.agentQuestion")}</div>
                    <Controller
                        as={
                            <>
                                <Checkbox
                                    checked={form.watch("workingWithAnAgent") === "yes"}
                                    onChange={() => onAgentChange("yes")}
                                />
                                <label className="mr-2 text-sm">{t("words.yes")}</label>
                                <Checkbox
                                    checked={form.watch("workingWithAnAgent") === "no"}
                                    onChange={() => onAgentChange("no")}
                                />
                                <label className="mr-2 text-sm">{t("words.no")}</label>
                                <Checkbox
                                    checked={form.watch("workingWithAnAgent") === "I am an agent"}
                                    onChange={() => onAgentChange("I am an agent")}
                                />
                                <label className="mr-2 text-sm">{t("contactPage.form.iAmAgent")}</label>
                            </>
                        }
                        name="workingWithAnAgent"
                        control={form.control}
                    />
                </div>
            )}
            <InputBlock label={t("contactPage.form.message")} required>
                <Controller
                    as={TextArea}
                    name="text"
                    control={form.control}
                    rows={size.width < 680 ? 3 : 5}
                    className={"!border !border-[var(--color-border)] !rounded-[var(--border-radius)]"}
                    placeholder={"Message" + (size.width < 680 ? "*" : "")}
                />
            </InputBlock>
            <FormControlLabel
                control={
                    <Controller
                        name="newsletter"
                        control={form.control}
                        render={({ onChange, value }) => (
                            <Checkbox
                                checked={value}
                                onChange={(e) => {
                                    onChange(e.target.checked);
                                    onNewsletterChange(e.target.checked);
                                }}
                            />
                        )}
                    />
                }
                label={<div className="text-xs ml-2 max-w-[350px]">{t("contactPage.form.newsletter")}</div>}
            />
        </div>
    );
}
