import dayjs from "dayjs";
import { TMeta, TRootComponent } from "pages/_models/PageMdl";
import { INVENTORY_STATUS, TStatusByPhase } from "units/_models/UnitTypeMdl";
import { EMPLOYEE_RIGHTS } from "users/_models/UserMdl";
import { TImage } from "_common/types/baseTypes";
import { TPropertyConstructProgress } from "properties/_models/PropertyConstructProgressMdl";
import { PHASE_STATUS, TPhasesMdl } from "admin/phases/_models/PhasesMdl";
import { IUserSummaryModel } from "api/users/_models/UserModel";
import { SURFACES_TYPE } from "admin/units/_models/UnitMdl";
import { TBuildingMdl } from "admin/buildings/_models/buildingsMdl";
import { TFloorMdl } from "admin/floors/_models/floorsMdl";
import { ICommunityCard } from "api/communities/_models/CommunityModel";

export enum FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH {
    LOCATION = "location",
    PURPOSE = "purpose",
    PUBLISHED = "published",
    STATUS = "status",
    PROPERTY_ID = "propertyId",
}

export const PARTICULARITY = [
    "beachfront",
    "closingInPesos",
    "closingInMultipleCurrencies",
    "rentalProgram",
    "financingAvailable",
    "mortgageCredit",
    "bitcoinAccepted",
    "longTermFinancing",
];

export enum CURRENCY {
    MXN = "MXN",
    US = "US",
}

export enum PROPERTY_PURPOSE {
    BUY = "BUY",
}

export enum PROPERTY_TYPE {
    condo = "condo",
    house = "house",
    villa = "villa",
    land = "land",
    commercial = "commercial",
    hotel = "hotel",
}

export const PROPERTY_TYPE_OPTIONS: PropertyTypesAndOptions[] = [
    ...Object.values(PROPERTY_TYPE),
    "beachfront",
    "luxury",
];
export type PropertyTypesAndOptions = PROPERTY_TYPE | "beachfront" | "luxury";

export const HIDDEN_PROPERTY_TYPE: string[] = [PROPERTY_TYPE.hotel, PROPERTY_TYPE.commercial];

export enum FEES {
    dollarPerSquareMeter = "dollarPerSquareMeter",
    dollarsPerMonth = "dollarsPerMonth",
    dollarsPerSquareFoot = "dollarsPerSquareFoot",
    pesosPerMonth = "pesosPerMonth",
    pesosPerSquareMeter = "pesosPerSquareMeter",
    percentPerMonth = "percentPerMonth",
    percentPerYear = "percentPerYear",
    toBeDetermined = "toBeDetermined",
    seeUnit = "seeUnit",
    noFees = "noFees",
}

export enum PROPERTY_SUBTYPE {
    residential = "residential",
    commercial = "commercial",
    mix = "mix",
    townHouse = "townHouse",
    singleHouse = "singleHouse",
    buildableHouse = "buildableHouse",
}

export enum PREMIUM {
    NONE,
    PROMO,
    PROMO_PLUS,
}

export enum PARKING_TYPE {
    COVERED = "COVERED",
    GARAGE = "GARAGE",
    OUTDOOR = "OUTDOOR",
    STREET = "STREET",
    UNDERGROUND = "UNDERGROUND",
}

export enum SEASONS {
    SPRING = "SPRING",
    SUMMER = "SUMMER",
    AUTUMN = "AUTUMN",
    WINTER = "WINTER",
}

export interface TPremiumPeriod {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
}

export interface TRangeNumber {
    min: number;
    max?: number;
}

export interface TInstalment {
    start: string;
    year: string;
    delivery: string;
    discount: string;
}

export interface TLocation {
    type: "Point";
    coordinates: number[];
}

export interface TAddressMdl {
    street?: string;
    extra?: string;
    city: string;
    postalCode?: string;
    neighbourhood: string;
    country?: string;
    province: string;
    provinceLong: string;
    provinceShort?: string;
}

export interface TFees {
    type: FEES;
    value: number;
    surfaces?: SURFACES_TYPE[number][];
    applyOnUnits?: boolean;
}

export interface TFinancing {
    signin: number;
    building: number;
    delivery: number;
    deeding: number;
    discount: number;
}

export interface TAmenity {
    [key: string]: string | number | boolean | undefined;
}

export interface TFeature {
    [key: string]: string | number | boolean | undefined;
}

export interface TPropertyLocalized {
    title: string;
    description: string;
    urlAlias: string;
    discountText: string;
    meta: TMeta;
    component: TRootComponent;
}

export interface TFloor {
    name?: string;
    units?: TUnitOfInventory[];
    buildingName?: string;
}

export interface TBuilding {
    name: string;
    floors: TFloor[];
}

export interface TPhase {
    numberPhase: number;
    status: PHASE_STATUS;
    monthsNumber?: number;
    deliveryDate?: dayjs.Dayjs;
}

export interface TInventory {
    phases: TPhasesMdl[];
    buildings: TBuildingMdl[];
    floors: TFloorMdl[];
}

export interface TUnitOfInventory {
    unitId: string;
    squareSurface: number;
    price: number;
    status?: INVENTORY_STATUS;
    number?: string;
    phaseIndex?: number;
}

export type TPropertyBaseMdl = {
    _id: string;
    published: boolean;
    purpose: PROPERTY_PURPOSE;
    type: PROPERTY_TYPE;
    subType?: PROPERTY_SUBTYPE;

    price: TRangeNumber;
    priceIsNotDisplayed: boolean;
    priceInPesos: TRangeNumber;
    isFinancing: boolean;
    financing: TFinancing[];
    fees: TFees;
    sold: boolean;
    status: INVENTORY_STATUS;
    statusByPhase?: TStatusByPhase[];
    premiumPlan: PREMIUM;
    premiumPeriod?: TPremiumPeriod;
    discount: boolean;
    address: TAddressMdl;
    location: TLocation;
    visit3D: string;
    video: string;
    video2: string;

    amenities: TAmenity;
    features: TFeature;

    photos: TImage[];
    premiumPhotos: TImage[];
    floorPlans: TImage[];
    constructProgress: TPropertyConstructProgress[];
    finishings: TImage[];
    previousProjects: TImage[];
    previousProjectsVideos: TImage[];
    showroom: TImage[];
    nbOfLevels: number;
    nbOfUnits: number;
    nbOfUnitsAvailable: number;
    nbOfUnitsManual: number;
    inventory?: TInventory;
    bedrooms: TRangeNumber;
    bathrooms: TRangeNumber;
    basementRooms: number;
    carParks: number;
    lockers: number;
    buildingHeight: number;
    squareSurface: TRangeNumber;
    coOwner: boolean;
    coDevelopers: { [employeeId: string]: EMPLOYEE_RIGHTS };
    isUpdateFromConversion: boolean;

    instalments: TInstalment[];

    position: number;
    waterFront: boolean;
    hideInventory?: boolean;
    contactPhone: string;
    contactMail: string;
    contactName: string;
    propertyLink: string;

    isSquareFeet: boolean;

    firstDayOnSite: dayjs.Dayjs;
    lastUpdate: dayjs.Dayjs;
    manuallyUpdate: dayjs.Dayjs;
    createdAt: dayjs.Dayjs;
    updatedAt: dayjs.Dayjs;
};

export type TPropertyMdl = TPropertyBaseMdl & {
    localized: {
        fr: TPropertyLocalized;
        es: TPropertyLocalized;
        en: TPropertyLocalized;
        zh: TPropertyLocalized;
    };
    developer: string;
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
    };
};

export type TPropertyListingMdl = TPropertyBaseMdl & {
    localized: TPropertyLocalized;
    nbOfUnitsFiltered?: number;
    urlAlias: {
        [lang: string]: string;
    };
    developer: IUserSummaryModel | undefined;
    communities: ICommunityCard[];
};

export type TPropertyDashboardListingMdl = TPropertyListingMdl & {
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
        favorited: {
            count: number;
        };
    };
};

export type TPropertyCard = Pick<
    TPropertyListingMdl,
    | "_id"
    | "localized"
    | "address"
    | "type"
    | "price"
    | "priceInPesos"
    | "priceIsNotDisplayed"
    | "features"
    | "bedrooms"
    | "bathrooms"
    | "squareSurface"
    | "photos"
    | "lastUpdate"
    | "nbOfUnits"
    | "nbOfUnitsAvailable"
    | "nbOfUnitsFiltered"
    | "status"
    | "discount"
    | "nbOfUnitsManual"
    | "firstDayOnSite"
    | "subType"
    | "createdAt"
    | "updatedAt"
>;

export type TPropertyPage = TPropertyCard &
    Pick<
        TPropertyListingMdl,
        | "floorPlans"
        | "video"
        | "video2"
        | "manuallyUpdate"
        | "showroom"
        | "constructProgress"
        | "urlAlias"
        | "amenities"
        | "fees"
        | "financing"
        | "subType"
        | "previousProjects"
        | "previousProjectsVideos"
        | "hideInventory"
        | "isSquareFeet"
        | "finishings"
        | "location"
        | "inventory"
        | "visit3D"
    > & {
        communities: ICommunityCard[];
    };

export type TDefaultProperty = Omit<TPropertyMdl, "_id" | "updatedAt" | "createdAt">;

export function createDefaultProperty(): TDefaultProperty {
    return {
        purpose: PROPERTY_PURPOSE.BUY,
        type: PROPERTY_TYPE.house,
        localized: {
            fr: {
                discountText: "",
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
            en: {
                discountText: "",
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
            es: {
                discountText: "",
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
            zh: {
                discountText: "",
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
        },
        amenities: {},
        developer: "",
        coDevelopers: {},
        discount: false,
        features: {},
        floorPlans: [],
        instalments: [],
        location: {
            type: "Point",
            coordinates: [0, 0],
        },
        fees: {
            value: 0,
            type: FEES.toBeDetermined,
            surfaces: ["interiorConstruction", "exteriorConstruction"],
            applyOnUnits: false,
        },
        photos: [],
        showroom: [],
        finishings: [],
        position: 0,
        premiumPlan: PREMIUM.NONE,
        premiumPeriod: { startDate: dayjs().startOf("day"), endDate: dayjs().endOf("day").add(1, "year") },
        lastUpdate: dayjs(),
        nbOfLevels: 0,
        nbOfUnits: 0,
        nbOfUnitsAvailable: 0,
        nbOfUnitsManual: 0,
        price: { min: 0, max: 0 },
        priceInPesos: { min: 0, max: 0 },
        isFinancing: false,
        financing: [
            {
                signin: 0,
                building: 0,
                delivery: 0,
                deeding: 0,
                discount: 0,
            },
        ],
        priceIsNotDisplayed: false,
        bedrooms: { min: 0, max: 0 },
        bathrooms: { min: 0, max: 0 },
        squareSurface: { min: 0, max: 0 },
        published: false,
        sold: false,
        status: INVENTORY_STATUS.AVAILABLE,
        visit3D: "",
        video: "",
        video2: "",
        previousProjectsVideos: [],
        contactPhone: "",
        contactMail: "",
        propertyLink: "",
        contactName: "",
        coOwner: false,
        waterFront: false,
        isUpdateFromConversion: false,
        address: {
            street: "",
            extra: "",
            city: "",
            postalCode: "",
            neighbourhood: "",
            country: "MEX",
            province: "Q.R.",
            provinceLong: "QUINTANA_ROO",
        },
        stats: {
            printed: {
                count: 0,
                date: new Date(),
            },
            visited: {
                count: 0,
                date: new Date(),
            },
        },
        premiumPhotos: [],
        constructProgress: [],
        previousProjects: [],
        basementRooms: 0,
        carParks: 0,
        lockers: 0,
        buildingHeight: 0,
        hideInventory: false,
        firstDayOnSite: dayjs(),
        manuallyUpdate: dayjs(),
        isSquareFeet: false,
    };
}
