import React, { useEffect, useMemo } from "react";
import { PropertyContext } from "properties/propertyPage/PropertyContext";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { TPropertyPage } from "properties/_models/PropertyMdl";
import dayjs from "dayjs";
import i18next from "i18next";
import { PropertyMeta } from "properties/propertyPage/PropertyMeta";
import { PropertyBreadcrumb } from "properties/propertyPage/PropertyBreadcrumb";
import { Container } from "@material-ui/core";
import { PropertyViewGallery } from "properties/propertyPage/PropertyViewGallery";
import { PropertyViewInfo } from "properties/propertyPage/PropertyViewInfo";
import { PropertyContent } from "properties/propertyPage/PropertyContent";
import { GALLERY_NAME_MODAL, PROPERTY_OPTION } from "_configs/propertyConfig";
import { PropertyHeaderLuxury } from "properties/propertyPage/PropertyHeaderLuxury";
import { ContactFormDialog } from "units/ContactFormDialog";
import PropertyViewListGalleryModal from "properties/propertyPage/propertyView/PropertyViewGalleryListModal";
import { PropertyViewGalleryModal } from "properties/propertyPage/propertyView/PropertyViewGalleryModal";
import { galleryStore } from "gallery/_store/GalleryStore";
import { PageTitle } from "_common/ui/pages/PageTitle";

dayjs.locale(i18next.language);

// const LazyPropertyOrUnitGalleryImageModal = lazy(() => import("properties/gallery/PropertyOrUnitGalleryImageModal"));

type Props = {
    property: TPropertyPage;
};

export const PropertyView = ({ property }: Props) => {
    const propertyStore = useMemo(() => new PropertyStore(property), [property]);
    const isLuxury = !!property.features?.[PROPERTY_OPTION.luxury];

    useEffect(() => {
        galleryStore.setModalName(GALLERY_NAME_MODAL.PROPERTY);
    }, []);

    return (
        <PropertyContext propertyStore={propertyStore}>
            <PropertyMeta />
            <PageTitle style={{ display: "none" }}>{property.localized.title}</PageTitle>
            {!isLuxury ? (
                <Container className={"!px-2.5"}>
                    <div className="mt-0 mx-0 mb-2 md:mt-5 flex-col sm:flex-row flex md:justify-between">
                        <PropertyBreadcrumb />
                        <PropertyViewInfo />
                    </div>
                    {!!property.photos.length && <PropertyViewGallery />}
                </Container>
            ) : (
                <PropertyHeaderLuxury />
            )}
            <Container className={"mt-4 !px-2.5"}>
                <PropertyContent />
            </Container>
            <ContactFormDialog />
            {/*List Gallery*/}
            <PropertyViewListGalleryModal />

            {/* 2nd Gallery*/}
            <PropertyViewGalleryModal />
        </PropertyContext>
    );
};
