import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { Recaptcha } from "components/misc/notifications/recaptcha";
import { TContactForm } from "contact/ContactUsForm";
import { Dayjs } from "dayjs";
import { AC_TAGS } from "users/_models/UserMdl";
import { BLOG_TYPE } from "pages/_models/PageMdl";

export type TAskForVisitOrInfoForm = {
    name: string;
    phone: string;
    email: string;
    subject: string;
    visitedDate: Dayjs;
    message?: string;
    propertyId?: string;
    inquiry?: string;
    unitId?: string;
    acTags?: AC_TAGS;
    blogType?: BLOG_TYPE;
    lang?: string;
    url?: string;
    unitNumber?: string;
    attributerChannel: string;
    attributerChanneldrilldown1: string;
    attributerChanneldrilldown2: string;
    attributerChanneldrilldown3: string;
    attributerChanneldrilldown4: string;
    attributerLandingpage: string;
    attributerLandingpagegroup: string;
};

class NotifierStore extends Recaptcha {
    constructor(private apiPath = `${sharedConfig.apiUrl}/notifier`) {
        super();
    }

    async askForInfo(data: TAskForVisitOrInfoForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/askForInfo", { ...data, recaptcha });
    }

    async contact(data: TContactForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/contact", { ...data, recaptcha });
    }
}

const notifierStore = new NotifierStore();
export { notifierStore };
