import React from "react";
import { unitsStore } from "units/_stores/unitsStore";
import { useUnitTypeStore } from "unitTypes/_store/UnitTypeContext";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { CURRENCY_OPTIONS } from "_common/currency/CurrencyTypes";
import { InventoryTable } from "properties/propertyPage/inventory/InventoryTable";
import { InventoryProvider } from "properties/propertyPage/inventory/InventoryContext";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { InventoryUnitTypeHeader } from "unitTypes/InventoryUnitTypeHeader";
import { InventoryHideSoldUnits } from "properties/propertyPage/inventory/InventoryHideSoldUnits";
import { COLUMNS_UNIT_TYPE } from "properties/propertyPage/inventory/InventoryColumns";
import { useCurrency } from "_common/currency/CurrencyProvider";

export function UnitTypeInventory() {
    const { property } = usePropertyStore();
    const { currentCurrency } = useCurrency();
    const { unitType } = useUnitTypeStore();
    const listStore = unitsStore.getListStore(unitType._id);

    return (
        <InventoryProvider initialCurrency={isInPesosProperty(property) ? CURRENCY_OPTIONS.MXN : currentCurrency}>
            <div className={"mt-6 sm:mt-10 mb-10 max-w-[800px] m-auto"}>
                <div className={"mb-6 sm:mb-4 sm:flex sm:justify-between"}>
                    <InventoryUnitTypeHeader count={listStore.count ?? 0} />
                    <InventoryHideSoldUnits listStore={listStore} />
                </div>
                <InventoryTable listStore={listStore} columns={COLUMNS_UNIT_TYPE} />
            </div>
        </InventoryProvider>
    );
}
