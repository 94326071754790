import React from "react";
import styles from "./_css/PropertyGalleyConstructProgress.module.css";
import { VideoComponent } from "components/base/video/VideoComponent";
import dayjs from "dayjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PhaseCollapsible } from "properties/gallery/PhaseCollapsible";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { GALLERY_TABS } from "_configs/propertyConfig";
import { galleryStore } from "gallery/_store/GalleryStore";
import { PropertyViewGalleryModal } from "properties/propertyPage/propertyView/PropertyViewGalleryModal";

export function PropertyGalleyConstructProgress() {
    const propertyStore = usePropertyStore();

    const getIndex = (phaseNumber: number, index: number) => {
        let finalIndex = 0;
        for (let i = 0; i < phaseNumber; i++) {
            finalIndex += propertyStore.property.constructProgress[i].images.length;
        }
        return finalIndex + index;
    };

    return (
        <div className={styles.container}>
            {propertyStore.property.constructProgress.map((progress, phaseNumber) => {
                // const correspondingPhase = props.propertyPhases?.find(
                //     (phase) => phase.deliveryDate && dayjs(progress.date).isBefore(dayjs(phase.deliveryDate)),
                // );

                return (
                    <div key={progress._id}>
                        <div
                            className={
                                "py-1 md:py-5 text-sm items-center text-center text-[var(--color-primary)] bg-[var(--color-secondary-bg)]"
                            }
                        >
                            {dayjs(progress.date).format("MMMM YYYY")}
                        </div>
                        <PhaseCollapsible index={phaseNumber + 1} constructProgress={progress}>
                            {progress?.videos?.length > 0 && (
                                <div>
                                    {progress.videos.map((video) => {
                                        return (
                                            <div
                                                key={video.url}
                                                className={"p-2.5 cursor-pointer"}
                                                onClick={() =>
                                                    galleryStore.setOpenedGalleryTab(GALLERY_TABS.CONSTRUCT_PROGRESS)
                                                }
                                            >
                                                <VideoComponent url={video.url} />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {progress?.images?.length > 0 && (
                                <div className={"flex flex-row flex-wrap justify-between"}>
                                    {progress.images.map((image, index) => {
                                        const currentIndex = getIndex(phaseNumber, index);
                                        return (
                                            <div key={image._id} className={"w-[49%] py-2.5"}>
                                                <LazyLoadImage
                                                    className={"cursor-pointer"}
                                                    src={image.url}
                                                    alt={image?.alt}
                                                    onClick={() => {
                                                        galleryStore.setOpenedGalleryTab(
                                                            GALLERY_TABS.CONSTRUCT_PROGRESS,
                                                        );
                                                        galleryStore.setOpenedGalleryModal(currentIndex);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </PhaseCollapsible>
                    </div>
                );
            })}
            <PropertyViewGalleryModal />
        </div>
    );
}
