import React from "react";
import styles from "./_css/propertyOrUnitPlans.module.css";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isImage } from "_common/_utils/fileUtils";
import { TImage } from "_common/types/baseTypes";

type Props = {
    images: TImage[];
    onCLick: () => void;
};

export function PropertyOrUnitGalleryAndDocuments({ images, onCLick }: Props) {
    if (!images.length) return null;
    return (
        <div className={styles.container}>
            <div className={clsx(styles.gallery, "flex flex-row flex-wrap justify-center")}>
                {images.map((photo, index) => {
                    if (!photo.url) return null;
                    return (
                        <div key={index} className={styles.galleryImage}>
                            {isImage(photo.url) ? (
                                <LazyLoadImage
                                    onClick={onCLick}
                                    className={"cursor-pointer h-full w-full object-cover object-center"}
                                    src={photo.url}
                                    alt={`${photo.title}-${index}`}
                                    title={`${photo.title} photo ${index}`}
                                />
                            ) : (
                                <div className={"relative min-h-[500px]"}>
                                    <embed
                                        src={photo.url + "#toolbar=0&navpanes=0"}
                                        width="100%"
                                        height="100%"
                                        className={"min-h-[500px]"}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
