import dayjs, { Dayjs } from "dayjs";
import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";

export enum PHASE_STATUS {
    NONE = "NONE",
    READY_TO_MOVE_IN = "READY_TO_MOVE_IN",
    UNDER_CONSTRUCTION = "UNDER_CONSTRUCTION",
    AVAILABLE_X_MONTHS_AFTER = "AVAILABLE_X_MONTHS_AFTER",
}

export type TPhasesMdl = {
    _id: string;
    propertyId: string;
    status: PHASE_STATUS;
    inventoryStatus: INVENTORY_STATUS;
    deliveryDate: dayjs.Dayjs;
    seasonYear: number;
    monthsNumber: number;
    numberPhase: number;
    published: boolean;

    createdAt: Dayjs;
    updatedAt: Dayjs;
};

export const defaultPhase: Partial<TPhasesMdl> = {
    status: PHASE_STATUS.UNDER_CONSTRUCTION,
    deliveryDate: dayjs(),
    monthsNumber: 6,
    seasonYear: dayjs().year(),
};
