import React from "react";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import bed from "../../assets/images/icons/UnitModalHeader/bed.svg";
import bath from "../../assets/images/icons/UnitModalHeader/bath.svg";
import size from "../../assets/images/icons/UnitModalHeader/size.svg";
import houseBuilding from "../../assets/images/icons/UnitModalHeader/houseBuilding.svg";
import land from "../../assets/images/icons/land.svg";
import terrace from "../../assets/images/icons/terrace.svg";

import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import PropertyHeaderTextList from "properties/propertyPage/PropertyHeaderTextList";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { PropertyRoomsLabel } from "properties/propertyPage/PropertyRoomsLabel";

export function PropertyDetailsNoInventory() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const details = [
        {
            icon: houseBuilding,
            render: (property: TPropertyListingMdl) => {
                if (!property.type) return null;
                return (
                    <div className={"text-lg capitalize"}>
                        {t("propertyPage.header.propertyType", {
                            type: `${property.type}`,
                        })}
                    </div>
                );
            },
        },
        {
            icon: size,
            render: (property: TPropertyListingMdl) => {
                if (!property.squareSurface.min) return null;
                return (
                    <div className={"text-lg"}>
                        {t("propertyPage.header.totalArea", {
                            m2: property.squareSurface.min,
                            ft2: convert(property.squareSurface.min, UNIT_CONVERSION.M2_TO_FT2_BACK),
                        })}
                    </div>
                );
            },
        },
        {
            icon: terrace,
            render: (property: TPropertyListingMdl) => {
                if (!property.squareSurface.min) return null;
                return (
                    <div className={"text-lg"}>
                        {t("propertyPage.header.terraceArea", {
                            m2: property.squareSurface.min,
                            ft2: convert(property.squareSurface.min, UNIT_CONVERSION.M2_TO_FT2_BACK),
                        })}
                    </div>
                );
            },
        },
        {
            icon: land,
            render: (property: TPropertyListingMdl) => {
                if (!property.squareSurface.min) return null;
                return (
                    <div className={"text-lg"}>
                        {t("propertyPage.header.landArea", {
                            m2: property.squareSurface.min,
                            ft2: convert(property.squareSurface.min, UNIT_CONVERSION.M2_TO_FT2_BACK),
                        })}
                    </div>
                );
            },
        },
        {
            icon: bed,
            render: (property: TPropertyListingMdl) => {
                if (property.bedrooms.min === undefined || property.bedrooms.min === null) return null;
                return <PropertyRoomsLabel min={property.bedrooms.min} max={property.bedrooms.max} />;
            },
        },
        {
            icon: bath,
            render: (property: TPropertyListingMdl) => {
                if (property.bathrooms.min === undefined || property.bathrooms.min === null) return null;
                return <PropertyRoomsLabel min={property.bathrooms.min} max={property.bathrooms.max} isBath />;
            },
        },
    ];

    return <PropertyHeaderTextList details={details} property={property} />;
}
