import { MEXICO_PROVINCES, TCity } from "_common/regions/regions";

export const POPULAR_SEARCHES_CITIES: TCity[] = [
    { name: "Tulum", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 20.208771461477255, lng: -87.46535019999999 },
    { name: "Playa del Carmen", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 20.6295586, lng: -87.0738851 },
    { name: "Cancun", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.161908, lng: -86.8515279 },
    { name: "Puerto vallarta", region: MEXICO_PROVINCES.JALISCO, lat: 21.161908, lng: -86.8515279 },
    { name: "Chelem", region: MEXICO_PROVINCES.YUCATAN, lat: 21.161908, lng: -86.8515279 },
    { name: "Merida", region: MEXICO_PROVINCES.YUCATAN, lat: 21.161908, lng: -86.8515279 },
    { name: "Akumal", region: MEXICO_PROVINCES.QUINTANA_ROO, lat: 21.161908, lng: -86.8515279 },
    { name: "Cabo San Lucas", region: MEXICO_PROVINCES.BAJA_CALIFORNIA_SUR, lat: 21.161908, lng: -86.8515279 },
];
