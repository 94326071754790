import React from "react";
import { TFeatureUnit } from "admin/units/_models/UnitMdl";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

type Props = {
    views: TFeatureUnit;
};

export function UnitViews({ views }: Props) {
    const { t } = useTranslation();
    const viewsToDisplay: string[] = [];
    Object.keys(views).forEach((key) => {
        if (key in views) {
            viewsToDisplay.push(getI18nExpByLang(i18next.language, `unit.views.${key}`));
        }
    });
    return <div className={"text-sm"}>{t("unitPage.header.views", { views: viewsToDisplay.join(",") })}</div>;
}
