import React, { ReactNode } from "react";
import floorIcon from "../assets/images/icons/UnitModalHeader/floor.svg";
import bed from "../assets/images/icons/UnitModalHeader/bed.svg";
import bath from "../assets/images/icons/UnitModalHeader/bath.svg";
import priceSurface from "../assets/images/icons/UnitModalHeader/moneyCircle.svg";
import surface from "../assets/images/icons/UnitModalHeader/size.svg";
import houseBuilding from "../assets/images/icons/UnitModalHeader/houseBuilding.svg";
import garage from "../assets/images/icons/UnitModalHeader/garage.svg";
import views from "../assets/images/icons/UnitModalHeader/sea.svg";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { useTranslation } from "react-i18next";
import { UNIT_TYPE, UNIT_TYPE_FILTERED } from "units/_models/UnitTypeMdl";
import { UnitViews } from "units/UnitViews";
import { TFeatureUnit } from "admin/units/_models/UnitMdl";
import { UnitHeaderPriceSurface } from "units/UnitHeaderPriceSurface";
import { UnitDetailsSurfaces } from "units/UnitDetailsSurfaces";
import { UnitDetailBathrooms } from "units/UnitDetailBathrooms";
import { TFloorMdl } from "admin/floors/_models/floorsMdl";
import { UnitTypeFloorDetail } from "units/UnitTypeFloorDetail";
import { FloorNumber } from "properties/propertyPage/inventory/FloorNumber";

type TItemProps = {
    numberOfFloor?: number;
    floor?: TFloorMdl;
    bedrooms: number;
    bathrooms: number;
    powderRooms?: number;
    surface: number;
    surfaces: {
        interiorConstruction: number;
        exteriorConstruction: number;
        terraceSurface?: number;
        rooftopSurface?: number;
        yardSize?: number;
        poolSurface?: number;
        storageSurface?: number;
        parkingSurface?: number;
        totalConstructArea?: number;
        lotSurface?: number;
        cus?: number;
        cos?: number;
    };
    parkingSurface: number;
    unitType?: UNIT_TYPE;
    priceSurface?: number;
    views: TFeatureUnit | null;
    isUnitType?: boolean;
};

type Props = {
    item: TItemProps;
};

type TDetails = { icon: string; render: (item: TItemProps) => ReactNode };

export function UnitHeaderDetails({ item }: Props) {
    const { t } = useTranslation();

    const detailsLeft: TDetails[] = [
        {
            icon: floorIcon,
            render: () => {
                if (item.isUnitType && !item.numberOfFloor) return null;
                if (!item.isUnitType && !item.floor) return null;
                return (
                    <>
                        {item.isUnitType ? (
                            <UnitTypeFloorDetail numberOfFloor={item.numberOfFloor} />
                        ) : (
                            <FloorNumber floor={item.floor} />
                        )}
                    </>
                );
            },
        },
        {
            icon: bed,
            render: () => {
                if (item.bedrooms === undefined || item.bedrooms === null || item.bedrooms == -1) return null;
                return (
                    <div className={"text-sm"}>
                        {t(
                            `unitPage.header.numberOfRoom${
                                item.bedrooms ? (item.bedrooms > 1 ? "_plural" : "") : "_0"
                            }`,
                            {
                                count: item.bedrooms ?? 0,
                                context: `${item.bedrooms}`,
                            },
                        )}
                    </div>
                );
            },
        },
        {
            icon: bath,
            render: () => {
                if (!item.bathrooms || item.bathrooms == -1) return null;
                return <UnitDetailBathrooms bathrooms={item.bathrooms} powderRooms={item.powderRooms} />;
            },
        },
        {
            icon: priceSurface,
            render: (item) => {
                if (!item.priceSurface) return null;
                return <UnitHeaderPriceSurface priceBySquare={item.priceSurface} />;
            },
        },
    ];

    const detailsRight: TDetails[] = [
        {
            icon: surface,
            render: (item) => {
                if (
                    !item.surfaces.interiorConstruction &&
                    !item.surfaces.exteriorConstruction &&
                    !item.surfaces.terraceSurface &&
                    !item.surfaces.rooftopSurface &&
                    !item.surfaces.yardSize &&
                    !item.surfaces.poolSurface &&
                    !item.surfaces.storageSurface &&
                    !item.surfaces.parkingSurface &&
                    !item.surfaces.totalConstructArea &&
                    !item.surfaces.lotSurface &&
                    !item.surfaces.cus &&
                    !item.surfaces.cos
                ) {
                    return null;
                }
                return <UnitDetailsSurfaces surfaces={item.surfaces} />;
            },
        },
        {
            icon: houseBuilding,
            render: () => {
                if (!item.unitType || !UNIT_TYPE_FILTERED.includes(item.unitType)) return null;
                return <div className={"text-sm"}>{t(`unit.type.label.${item.unitType}`)}</div>;
            },
        },
        {
            icon: garage,
            render: () => {
                if (!item.parkingSurface || isNaN(item.parkingSurface)) return null;
                return (
                    <div className={"text-sm"}>
                        {t("unitPage.header.garage", {
                            count: 1,
                            surfaceM: item.parkingSurface,
                            surfaceFt: convert(item.parkingSurface, UNIT_CONVERSION.M2_TO_FT2_BACK),
                        })}
                    </div>
                );
            },
        },
        {
            icon: views,
            render: (item) => {
                if (!item.views || !Object.keys(item.views).length) return null;
                return <UnitViews views={item.views} />;
            },
        },
    ];
    return (
        <div
            className={
                "flex flex-col md:flex-row rounded-lg border border-[#1A3A45] p-5 gap-11 md:justify-between h-fit"
            }
        >
            <div className={"flex flex-col gap-y-4 lg:gap-x-3 "}>
                {detailsLeft.map((detail, index) => {
                    if (!detail.render(item)) return null;
                    return (
                        <div key={index} className="flex items-center">
                            <div className="w-10">
                                <img src={detail.icon} alt={"icon details"} />
                            </div>
                            <div className="flex flex-col ml-2 md:ml-3 ">{detail.render(item)}</div>
                        </div>
                    );
                })}
            </div>
            <div className={"flex flex-col gap-y-4 lg:gap-x-3"}>
                {detailsRight.map((detail, index) => {
                    if (!detail.render(item)) return null;
                    return (
                        <div key={index} className="flex items-center">
                            <div className="w-10">
                                <img src={detail.icon} alt={"icon details"} />
                            </div>
                            <div className="flex-1">{detail.render(item)}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
