import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";
import { UnitTypePage } from "unitTypes/UnitTypePage";

type Props = {
    unitType?: TUnitTypeListingMdl;
    open: boolean;
    onClose: () => void;
};

export function UnitTypeDialog({ unitType, open, onClose }: Props) {
    if (!unitType) return null;
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xl"} closeCross>
            <UnitTypePage unitType={unitType} />
        </Dialog>
    );
}
