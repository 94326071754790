import React from "react";
import { cn } from "_configs/twconfig";
// import arrowCircle from "../../assets/images/icons/arrowCircleWhite.svg";
import { ReactComponent as ArrowCircle } from "../../assets/images/icons/arrowCircleWhite.svg";
import { PROPERTY_TABS } from "properties/propertyPage/PropertyContent";
import { IconPropertyUnits } from "properties/propertyPage/icons/IconPropertyUnits";
import { IconPropertyDetails } from "properties/propertyPage/icons/IconPropertyDetails";
import { IconBuildableHouse } from "properties/propertyPage/icons/IconBuildableHouse";
import { PROPERTY_SUBTYPE } from "properties/_models/PropertyMdl";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";

interface ToggleSwitchProps {
    value: PROPERTY_TABS;
    onChange?: (value: PROPERTY_TABS) => void;
}

export function SwitchButtonPropertyDetails({ onChange, value }: ToggleSwitchProps) {
    const { property } = usePropertyStore();
    const { t } = useTranslation();
    const options = [
        {
            label: t("propertyPage.swiper.details"),
            value: PROPERTY_TABS.DETAILS,
            iconComponent: <IconPropertyDetails />,
        },
        property.subType === PROPERTY_SUBTYPE.buildableHouse
            ? {
                  label: t("propertyPage.swiper.houseModel"),
                  value: PROPERTY_TABS.BUILDABLE_HOUSE,
                  iconComponent: <IconBuildableHouse />,
              }
            : {
                  label: t("propertyPage.swiper.units"),
                  value: PROPERTY_TABS.INVENTORY,
                  iconComponent: <IconPropertyUnits />,
              },
    ];
    const handleToggle = (value: PROPERTY_TABS) => {
        onChange?.(value);
    };
    return (
        <div className="flex items-center justify-center w-full">
            <div className="relative flex w-full h-10 md:h-16 bg-gray-100 rounded-full p-1 ">
                <div
                    className={cn(
                        "absolute top-1 bottom-1 w-1/2 bg-[color:var(--color-primary)] rounded-full left-1/2 transition-all duration-300 ease-in-out",
                        {
                            "left-1": value === options[0].value,
                        },
                    )}
                />
                {options.map((option, idx) => {
                    const ComponentTag = option?.iconComponent;

                    return (
                        <div
                            key={option.value}
                            onClick={() => handleToggle(option.value)}
                            className={cn(
                                "relative flex-1 flex items-center justify-center gap-1 md:gap-4 rounded-full transition-colors duration-300 text-gray-600 hover:text-gray-800",
                                { "cursor-pointer": option.value !== value },
                            )}
                        >
                            <ArrowCircle
                                className={cn("absolute hidden md:block z-10", {
                                    "cursor-pointer": option.value === value,
                                    "md:hidden": value !== option.value,
                                    "right-1 md:right-4 rotate-180": idx === 0,
                                    "left-1 md:left-4": idx === 1,
                                })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const altOption = options.find((_option) => _option.value !== value);
                                    if (altOption) {
                                        handleToggle(altOption.value);
                                    }
                                }}
                            />
                            <div className={"md:hidden"}>
                                {ComponentTag &&
                                    React.cloneElement(ComponentTag, {
                                        color: value === option.value ? "white" : "#1A3A45",
                                        size: 20,
                                    })}
                            </div>
                            <div className={"hidden md:block"}>
                                {ComponentTag &&
                                    React.cloneElement(ComponentTag, {
                                        color: value === option.value ? "white" : "#1A3A45",
                                    })}
                            </div>
                            <div
                                className={cn("text-xs md:text-xl font-medium z-10", {
                                    "text-[color:var(--color-text-on-primary)] pointer-events-none cursor-not-allowed":
                                        value === option.value,
                                })}
                            >
                                {option.label}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
