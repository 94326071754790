import React from "react";
import { Checkbox as UiCheckbox, CheckboxProps } from "@material-ui/core";
import clsx from "clsx";
import styles from "./_css/checkbox.module.css";

type Props = CheckboxProps & {
    customColor?: string;
};

export function Checkbox(props: Props) {
    return (
        <UiCheckbox
            {...props}
            className={clsx(props.className)}
            classes={{ root: styles.root }}
            icon={
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.25" y="0.25" width="15.5" height="15.5" rx="3.75" stroke="#939B9E" strokeWidth="0.5" />
                </svg>
            }
            checkedIcon={
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                        x="0.25"
                        y="0.25"
                        width="15.5"
                        height="15.5"
                        rx="3.75"
                        fill={props.disabled ? "#939B9E" : props.customColor ?? "#52B5DB"}
                        stroke="#939B9E"
                        strokeWidth="0.5"
                    />
                    <path
                        d="M5 8.91304L7.42308 11L12 5"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            }
        />
    );
}
