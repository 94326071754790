import React, { useState } from "react";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import sortIcon from "../assets/images/icons/sort.svg";
import styles from "./_css/propertiesSort.module.css";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

export function PropertiesSort() {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);
    const [keySort, setKeySort] = useState({
        key: listStore.sort ? Object.keys(listStore.sort)[0] : "position",
        sort: listStore.sort ? Object.values(listStore.sort)[0] : 1,
    });

    function sort(updatedSort: { key: string; sort: number }) {
        setKeySort(updatedSort);
        listStore.setSort({ [updatedSort.key]: updatedSort.sort });
    }

    return (
        <div className={`flex items-center gap-2 ${styles["properties-sort-select"]}`}>
            <img src={sortIcon} className={"h-4 w-4"} alt={"icon sort"} />
            <div className={"flex sm:hidden text-sm md:hidden lg:flex md:text-md"}>{t("propertiesPage.sortBy")}</div>
            <UiSelect
                className="properties-sort-select"
                defaultValue={keySort.key}
                title={"Sort"}
                onChange={(e) => sort({ ...keySort, key: e.target.value as string })}
            >
                <MenuItem value={"position"}>{t("propertiesPage.sort.popular")}</MenuItem>
                <MenuItem value={"deliveryDates"}>{t("propertiesPage.sort.date")}</MenuItem>
                <MenuItem value={"price.min"}>{t("propertiesPage.sort.price")}</MenuItem>
            </UiSelect>
        </div>
    );
}
