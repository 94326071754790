import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";
import { PHASE_STATUS, TPhasesMdl } from "admin/phases/_models/PhasesMdl";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export function usePhaseLabel(phase: TPhasesMdl, count?: number) {
    const { t } = useTranslation();
    dayjs().locale("fr");
    const phaseLabel = count ? `${t("propertyPage.inventory.phaseLabel.phase", { count: count })} : ` : "";

    if (phase.inventoryStatus === INVENTORY_STATUS.SOLD) {
        return `${phaseLabel}${t("propertyPage.inventory.phaseLabel.sold", {
            count: count,
        })}`;
    }

    if (phase.status === PHASE_STATUS.AVAILABLE_X_MONTHS_AFTER || phase.status === PHASE_STATUS.UNDER_CONSTRUCTION) {
        return `${phaseLabel}${t("propertyPage.inventory.phaseLabel.delivery", {
            count: count,
            date: dayjs(phase.deliveryDate).locale(i18next.language).format("MMMM YYYY"),
        })}`;
    }

    return `${phaseLabel}${t("propertyPage.inventory.phaseLabel.ready", {
        count: count,
    })}`;
}
