import React from "react";
import PropertyPriceCardMinMax from "properties/propertyPage/PropertyPriceCardMinMax";

export default function PropertyPriceCards() {
    return (
        <div className="flex flex-col md:flex-row gap-x-3 gap-y-3 md:gap-y-0">
            <PropertyPriceCardMinMax range="min" />
            <PropertyPriceCardMinMax range="max" />
        </div>
    );
}
