import React, { useState } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { unitTypesStore } from "unitTypes/_store/unitTypesStore";
import { UnitTypeCard } from "unitTypes/UnitTypeCard";
import { observer } from "mobx-react-lite";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { UnitTypeDialog } from "unitTypes/UnitTypeDialog";
import { TUnitTypeListingMdl, UNIT_TYPE_PAGE_SIZE } from "units/_models/UnitTypeMdl";
import { useTranslation } from "react-i18next";

export const PropertyUnitTypeList = observer(() => {
    const { property } = usePropertyStore();
    const listStore = unitTypesStore.getListStore(property._id);
    const [pageLimit, setPageLimit] = useState(UNIT_TYPE_PAGE_SIZE);
    const [selectedUnitType, setSelectedUnitType] = useState<TUnitTypeListingMdl | undefined>(undefined);
    const seeViewMore = !!listStore.count && listStore.count > pageLimit;
    const { t } = useTranslation();
    return (
        <>
            <div className={"grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 my-5 sm:my-10"}>
                {listStore.paginatedItems.slice(0, pageLimit).map((unitType) => {
                    if (!unitType) return null;
                    return (
                        <UnitTypeCard
                            onClick={() => setSelectedUnitType(unitType)}
                            key={unitType._id}
                            unitType={unitType}
                            isInPesosProperty={isInPesosProperty(property)}
                        />
                    );
                })}
            </div>
            {seeViewMore && (
                <div className={"text-center underline cursor-pointer"} onClick={() => setPageLimit(200)}>
                    {t("words.seeMore")}
                </div>
            )}
            <UnitTypeDialog
                open={!!selectedUnitType}
                onClose={() => setSelectedUnitType(undefined)}
                unitType={selectedUnitType}
            />
        </>
    );
});
