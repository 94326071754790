import React, { Fragment, lazy } from "react";
import PropertyViewGalleryModalNavbar from "properties/propertyPage/propertyView/PropertyViewGalleryModalNavbar";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import SwiperCore, { Navigation } from "swiper";
import { Dialog } from "@material-ui/core";
import { VideoComponent } from "components/base/video/VideoComponent";
import { GALLERY_NAME_MODAL, GALLERY_TABS } from "_configs/propertyConfig";
import { observer } from "mobx-react-lite";
import PropertyViewConstructProgress from "properties/propertyPage/propertyView/PropertyViewConstructProgress";
import { isImage } from "_common/_utils/fileUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Suspenser from "_common/loaders/Suspenser";
import { galleryStore } from "gallery/_store/GalleryStore";

SwiperCore.use([Navigation]);

const LazyPropertyViewGalleryEmbed = lazy(() =>
    import("properties/propertyPage/propertyView/PropertyViewGalleryEmbed"),
);

const PropertyViewListGalleryModal = observer(() => {
    const propertyStore = usePropertyStore();
    const property = propertyStore.property;

    return (
        <Dialog
            open={
                galleryStore.openedGalleryListTab !== "NONE" && galleryStore.modalName === GALLERY_NAME_MODAL.PROPERTY
            }
            onClose={() => galleryStore.closeGalleryTab()}
            fullScreen
        >
            <div className="flex flex-col relative select-none">
                <PropertyViewGalleryModalNavbar handleClose={() => galleryStore.closeGalleryTab()} />
                <div className={"sm:h-[75px] h-[140px]"} />
                <div className="px-1 md:px-24 lg:px-36">
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.GALLERY &&
                        property.photos.map((picture, index) => (
                            <LazyLoadImage
                                key={index}
                                className="h-[50vw] m-auto cursor-pointer my-3 select-none object-contain"
                                src={picture.url}
                                alt={GALLERY_TABS.GALLERY}
                                onClick={() => {
                                    galleryStore.setOpenedGalleryModal(index);
                                }}
                            />
                        ))}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.SHOWROOM &&
                        property.showroom.map((picture, index) => (
                            <LazyLoadImage
                                key={index}
                                className="h-[50vw] m-auto cursor-pointer my-3 select-none object-contain"
                                src={picture.url}
                                alt={GALLERY_TABS.SHOWROOM}
                                onClick={() => {
                                    galleryStore.setOpenedGalleryModal(index);
                                }}
                            />
                        ))}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.LEVEL_PLAN &&
                        property.floorPlans.map((picture, index) => {
                            return (
                                <Fragment key={index}>
                                    {isImage(picture.url ?? "") ? (
                                        <LazyLoadImage
                                            key={index}
                                            className="h-[50vw] m-auto cursor-pointer my-3 select-none object-cover"
                                            src={picture.url}
                                            alt={GALLERY_TABS.LEVEL_PLAN}
                                            onClick={() => {
                                                galleryStore.setOpenedGalleryModal(index);
                                            }}
                                        />
                                    ) : (
                                        <div className={"h-[calc(100vh-140px)] sm:h-[calc(100vh-75px)]"}>
                                            <Suspenser>
                                                <LazyPropertyViewGalleryEmbed
                                                    key={picture.url}
                                                    src={picture.url + "#toolbar=0&navpanes=0"}
                                                    onClick={() => galleryStore.setOpenedGalleryModal(index)}
                                                    width={"100%"}
                                                    height={"100%"}
                                                />
                                            </Suspenser>
                                        </div>
                                    )}
                                </Fragment>
                            );
                        })}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.VIDEO && (
                        <>
                            <VideoComponent url={property.video} size={"100%"} />
                            {property.video2 && (
                                <div className="mt-3">
                                    <VideoComponent url={property.video2} size={"100%"} />
                                </div>
                            )}
                        </>
                    )}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.PREVIOUS_PROJECTS &&
                        property.previousProjects.map((picture, index) => (
                            <LazyLoadImage
                                key={index}
                                className="h-[50vw] m-auto  cursor-pointer my-3 select-none object-cover"
                                src={picture.url}
                                alt={GALLERY_TABS.PREVIOUS_PROJECTS}
                                onClick={() => {
                                    galleryStore.setOpenedGalleryModal(index);
                                }}
                            />
                        ))}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.FINISHINGS &&
                        property.finishings.map((picture, index) => (
                            <Fragment key={index}>
                                {isImage(picture.url ?? "") ? (
                                    <LazyLoadImage
                                        key={index}
                                        className="h-[50vw] m-auto cursor-pointer my-3 select-none object-cover"
                                        src={picture.url}
                                        alt={GALLERY_TABS.FINISHINGS}
                                        onClick={() => {
                                            galleryStore.setOpenedGalleryModal(index);
                                        }}
                                    />
                                ) : (
                                    <div className={"h-[calc(100vh-140px)] sm:h-[calc(100vh-75px)]"}>
                                        <Suspenser>
                                            <LazyPropertyViewGalleryEmbed
                                                key={picture.url}
                                                src={picture.url + "#toolbar=0&navpanes=0"}
                                                onClick={() => galleryStore.setOpenedGalleryModal(index)}
                                                width={"100%"}
                                                height={"100%"}
                                            />
                                        </Suspenser>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.CONSTRUCT_PROGRESS && (
                        <PropertyViewConstructProgress
                            onClick={(index) => {
                                galleryStore.setOpenedGalleryModal(index);
                            }}
                        />
                    )}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.TOUR_3D && (
                        <iframe
                            className="h-[90vh] select-none w-full"
                            src={property.visit3D}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="3D Tour"
                        />
                    )}
                </div>
            </div>
        </Dialog>
    );
});

export default PropertyViewListGalleryModal;
