import React from "react";

type Props = {
    title: string;
    description: string;
    img: string;
};

export function AboutUsValueCard(props: Props) {
    return (
        <div className="p-5 flex flex-col gap-2 border border-[var(--color-secondary)] rounded-lg h-72 md:min-w-[220px] xl:gap-4 2xl:px-[37px]">
            <div>
                <img height={30} src={props.img} alt={props.img} />
            </div>
            <div className="font-semibold text-base">{props.title}</div>
            <div className="text-[13px] sm:text-sm">{props.description}</div>
        </div>
    );
}
