import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { UnitHeaderPrices } from "units/UnitHeaderPrices";
import { observer } from "mobx-react-lite";
import { unitsStore } from "units/_stores/unitsStore";
import { UnitHeaderDetails } from "units/UnitHeaderDetails";
import _ from "lodash";
import { galleryStore } from "gallery/_store/GalleryStore";
import { Separator } from "_common/ui/components/Separator";
import { useTranslation } from "react-i18next";
import { useUnitTypeStore } from "unitTypes/_store/UnitTypeContext";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";

export const UnitTypeHeader = observer(() => {
    const { property } = usePropertyStore();
    const { unitType } = useUnitTypeStore();
    const { t } = useTranslation();
    const listStore = unitsStore.getListStore(unitType._id);
    const floors = _.groupBy(listStore.items, "floorId");
    const itemForPrice = {
        name: unitType.localized.title,
        price: unitType.price.min,
        priceInPesos: unitType.priceInPesos.min,
        isInPesosUnit: isInPesosProperty(property),
        priceIsNotDisplayed: property.priceIsNotDisplayed,
        fees: property?.fees?.applyOnUnits ? property?.fees : unitType?.fees,
    };
    const itemForDetails = {
        numberOfFloor: Object.keys(floors).length,
        bedrooms: unitType.bedrooms,
        bathrooms: unitType.bathrooms,
        surface: unitType.totalConstructArea?.min ?? 0,
        surfaces: {
            interiorConstruction: unitType.interiorConstruction?.min ?? 0,
            exteriorConstruction: unitType.exteriorConstruction?.min ?? 0,
        },
        parkingSurface: unitType.parkingSurface?.min ?? 0,
        views: unitType.views,
        unitType: unitType.type,
        priceSurface: 0,
        isUnitType: true,
    };
    console.log("unit type header", itemForDetails);
    return (
        <div className="flex flex-row flex-wrap gap-5 md:gap-0">
            <div className={"w-full md:w-1/3 flex flex-col gap-2"}>
                <UnitHeaderPrices item={itemForPrice} />
            </div>
            <div className={"w-full md:w-2/3"}>
                <UnitHeaderDetails item={itemForDetails} />
            </div>
            <button
                className="w-52 text-white bg-[#1A3A45] rounded-full py-2.5 md:hidden"
                onClick={() =>
                    galleryStore.openContactForm({
                        resource: MAIN_RESOURCE.UNIT,
                        title: t("unitPage.form.title", {
                            unit: unitType.localized.title,
                            property: property.localized.title,
                        }),
                    })
                }
            >
                {t("property.galleryNavbar.contact")}
            </button>
            <Separator className={"md:mt-5"} />
        </div>
    );
});
