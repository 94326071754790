import React from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { useFavoriteToggle } from "users/profile/favorite/useFavoriteToggle";
import { Checkbox } from "_common/ui/mui/checkbox/Checkbox";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";

type Props = {
    unit: TUnitListingMdl;
};

export function InventoryFavorite({ unit }: Props) {
    const { isFavorite, toggleFavorite } = useFavoriteToggle(MAIN_RESOURCE.UNIT, unit);
    return (
        <Checkbox
            onClick={() => {
                toggleFavorite();
            }}
            checked={isFavorite}
        />
    );
}
