import React from "react";
import { useParams } from "react-router";
import { communitiesStore } from "communities/_stores/communitiesStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { PageNotFound } from "pages/PageNotFound";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { CommunityView } from "communities/CommunityView";

export function CommunityPage() {
    const { communityAlias } = useParams();
    const communityOrFetch = communitiesStore.getByAliasUrl(communityAlias);
    const { loading } = useLoadingFromPromise(
        !(communityOrFetch && "localized" in communityOrFetch) ? communityOrFetch : undefined,
    );
    if (!communityOrFetch) return null;
    if ("localized" in communityOrFetch) {
        return <CommunityView community={communityOrFetch} />;
    }
    if (!loading) return null;

    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, error, community) => {
                if (error) {
                    return <ErrorBlock error={error} />;
                }
                if (!community) return <PageNotFound />;
                return <CommunityView community={community} />;
            }}
        />
    );
}
