import React from "react";
import { TAGS } from "properties/PROPERTY_TAGS";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { updateSearchFilter } from "properties/searchBar/filters/_utils/filtersUtils";
import { TFilter } from "admin/_common/filters/TFilter";
import { useHistory } from "react-router-dom";
import { Separator } from "_common/ui/components/Separator";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

type Props = {
    geoZoneName?: string;
    mobile?: boolean;
};

export const PropertiesTagsSection = ({ geoZoneName, mobile }: Props) => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);
    const { beachFrontOrLuxury, isBeachfront } = useSearchPageParams();
    const history = useHistory();
    const toggleFilter = (selectFilters: TFilter[]) => {
        const filterInListStore = listStore.filters.find((filter) => filter.id === selectFilters[0].id);
        if (filterInListStore) {
            selectFilters.forEach((filter) => {
                listStore.removeFilterByFilterIdStartBy(filter.id);
            });
            void fetchSearchProperties(propertiesStore, listStore, history);
        } else {
            updateSearchFilter(listStore, selectFilters).then(() => {
                void fetchSearchProperties(propertiesStore, listStore, history);
            });
        }
    };
    return (
        <div className={"w-full md:flex md:flex-col md:items-center"}>
            {mobile ? (
                <div className={"md:text-xl md:text-center mb-2 md:mb-4 text-[var(--color-primary)]"}>
                    {geoZonesStore.geoZone
                        ? t(`propertiesPage.popularSearches.titleWithGeoZone`, { geoZoneName: geoZoneName })
                        : t(`propertiesPage.popularSearches.title`)}
                </div>
            ) : (
                <h2 className={"md:text-xl md:text-center mb-2 md:mb-4 text-[var(--color-primary)]"}>
                    {geoZonesStore.geoZone
                        ? t(`propertiesPage.popularSearches.titleWithGeoZone`, { geoZoneName: geoZoneName })
                        : t(`propertiesPage.popularSearches.title`)}
                </h2>
            )}
            <div
                className={
                    "flex flex-row mt-2 mb-3 md:my-5 gap-2 overflow-auto whitespace-nowrap text-sm md:justify-center md:text-base md:flex-wrap md:max-w-[500px] no-scrollbar"
                }
            >
                {TAGS.map((tag) => {
                    if (geoZonesStore.geoZone?.isCommunity && tag.key === "gatedCommunity") return null;
                    if (!beachFrontOrLuxury && !geoZonesStore.geoZone?.isCommunity && tag.key === "moveInNow") {
                        return null;
                    }
                    const filterInListStore =
                        listStore.filters.find((filter) => filter.id === tag.filters[0].id) ||
                        (isBeachfront && tag.key === "beachfront");

                    return (
                        <div
                            key={tag.key}
                            className={clsx(
                                "py-1 px-2 rounded-md border border-solid border-[color:var(--color-primary)] cursor-pointer",
                                { ["bg-[color:var(--color-primary)] text-white"]: !!filterInListStore },
                            )}
                            onClick={() => toggleFilter(tag.filters)}
                        >
                            {t(`propertiesPage.popularSearches.tags.${tag.key}`)}
                        </div>
                    );
                })}
            </div>
            <Separator className={"md:hidden"} />
        </div>
    );
};
