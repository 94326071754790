import React from "react";
import { useTranslation } from "react-i18next";
import UnitSectionLayout from "units/UnitSectionLayout";
import checkCircle from "../assets/images/icons/checkCircle.svg";

interface Props {
    title: string;
    datas: string[][];
}

export function UnitCheckList({ title, datas }: Props) {
    const { t } = useTranslation();

    return (
        <UnitSectionLayout title={title} activeSeparator>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {datas.map((data, index) => (
                    <div key={index} className="flex items-center my-2">
                        <img src={checkCircle} alt="checkCircleIcon" className="mr-2" />
                        <span className="font-300 text-[#1A3A45]">
                            {data[0] === "privatePool" || data[0] === "privateTerrace"
                                ? `${data[1]} - ${t(`unit.features.${data[0]}`)}`
                                : t(`unit.features.${data[0]}`)}
                        </span>
                    </div>
                ))}
            </div>
        </UnitSectionLayout>
    );
}
