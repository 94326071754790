import { TMarker } from "maps/MapLeaflet";

export class ItemMapper {
    static toMarker(item: any, markerType: "property" | "unit" | "activity", lang = "en"): TMarker | undefined {
        if (!item || !markerType) return undefined;
        if (markerType === "activity" && item?.location?.lat && item?.location?.lng) {
            return {
                id: item?._id ?? item?.localized.title,
                markerType: "activity",
                urlAlias: item?.urlAlias,
                photos: item?.photos,
                title: item?.localized.title,
                description: item?.localized.description,
                coordinates: {
                    lat: item?.location?.lat,
                    lng: item?.location?.lng,
                },
                icon: item.icon,
            } as TMarker;
        }

        if (
            markerType === "unit" &&
            item?.property?.location?.coordinates[1] &&
            item?.property?.location?.coordinates[0]
        ) {
            return {
                id: item._id,
                markerType: "unit",
                title: item?.property?.localized?.title,
                description: item?.property?.localized?.description,
                urlAlias: item?.property?.localized?.urlAlias + "/units/",
                type: item?.property.type,
                photos: item?.photos,
                priceInPesos: item?.priceInPesos,
                price: item.price?.min,
                beds: {
                    min: item?.bedrooms,
                    max: 0,
                },
                squareSurface: item?.property.squareSurface,
                surface: item?.property.min,
                coordinates: {
                    lat: item.property.location.coordinates[1],
                    lng: item.property.location.coordinates[0],
                },
            } as TMarker;
        }

        if (markerType === "property" && item?.location?.coordinates[1] && item?.location?.coordinates[0]) {
            return {
                id: item._id,
                markerType: "property",
                title: item.localized.title,
                description: item.localized.description,
                urlAlias: item.localized.urlAlias,
                nbOfUnitsAvailable: item.nbOfUnitsAvailable,
                type: item.type,
                photos: item.photos,
                priceInPesos: item.priceInPesos?.min,
                price: item.price?.min,
                priceIsNotDisplayed: item.priceIsNotDisplayed,
                beds: { min: item.bedrooms.min, max: item.bedrooms.max },
                squareSurface: item.squareSurface,
                surface: item.squareSurface.min,
                coordinates: { lat: item.location.coordinates[1], lng: item.location.coordinates[0] },
                features: item.features,
            };
        }
        return undefined;
    }
}
