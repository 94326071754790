import React from "react";
import { IPageShortMdl } from "pages/_models/PageMdl";
import styles from "./_css/blogCard.module.css";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dayjs from "dayjs";
import i18next from "i18next";
import { useForceRefreshPage } from "_common/_utils/hookUtils";
import { Link } from "react-router-dom";

type Props = {
    blog: IPageShortMdl;
};

export function BlogCard(props: Props) {
    const { refreshPage } = useForceRefreshPage();

    return (
        <div className={clsx(styles.container)}>
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    refreshPage(`/${i18next.language}${props.blog.localized.url}`);
                }}
                to={`/${i18next.language}${props.blog.localized.url}`}
            >
                <LazyLoadImage
                    className={styles.image}
                    src={props.blog.localized.image}
                    alt={props.blog.localized.image}
                />
                <div className={clsx(styles.content, "")}>
                    <div className={clsx(styles.date, "colorSecondary mb_5")}>
                        {dayjs(props.blog.localized.publishedAt).format("MMMM D, YYYY")}
                    </div>
                    <div className={clsx(styles.title, "mb_5")}>{props.blog.localized.title}</div>
                    <div
                        className={clsx(styles.text, "textHome")}
                        dangerouslySetInnerHTML={{ __html: props.blog.localized.description ?? "" }}
                    />
                </div>
            </Link>
        </div>
    );
}
