import React from "react";
import { TPropertyCard } from "properties/_models/PropertyMdl";
import { PropertyCardGallery } from "properties/propertyCard/components/PropertyCardGallery";
import { PropertyCardHeader } from "properties/propertyCard/components/PropertyCardHeader";
import { CardContainer, CardGalleryContainer } from "properties/propertyCard/components/PropertyCardComponents";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { NoSsr } from "@material-ui/core";
import { PropertyCardInfos } from "properties/propertyCard/components/PropertyCardInfos";
import { refreshPage } from "_common/_utils/pageUtils";

type Props = {
    property: TPropertyCard;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    refresh?: boolean;
};

export function PropertyCardFeatured({ property, onMouseEnter, onMouseLeave, refresh }: Props) {
    return (
        <CardContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link
                to={URLS.property(property.localized.urlAlias)}
                onClick={(e) => {
                    if (refresh) {
                        e.preventDefault();
                        refreshPage(URLS.property(property.localized.urlAlias));
                    }
                }}
            >
                <NoSsr>
                    <PropertyCardHeader property={property} />
                </NoSsr>
                <CardGalleryContainer>
                    <PropertyCardGallery property={property} />
                </CardGalleryContainer>
                <PropertyCardInfos property={property} />
            </Link>
        </CardContainer>
    );
}
