import React from "react";
import { useTranslation } from "react-i18next";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { rounded } from "_common/_utils/alphaNumUtils";
import { numberFormatter } from "_common/_utils/currencyUtils";

type Props = {
    priceBySquare: number;
};

export function UnitHeaderPriceSurface({ priceBySquare }: Props) {
    const { t } = useTranslation();
    return (
        <div className={"flex text-sm"}>
            <div>
                <div>
                    {t("unitPage.header.priceBySquareM", {
                        price: numberFormatter(rounded(priceBySquare, 2)),
                    })}
                </div>
                <div>
                    {t("unitPage.header.priceBySquareFt", {
                        price: numberFormatter(convert(priceBySquare, UNIT_CONVERSION.M2_TO_FT2_BACK)),
                    })}
                </div>
            </div>
        </div>
    );
}
