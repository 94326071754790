import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import extandImage from "../assets/images/communities/extandImage.svg";
import GalleryPagination from "../properties/propertyPage/propertyView/GalleryPagination";
import { TImage } from "_common/types/baseTypes";
import ShareButton from "properties/propertyPage/propertyView/ShareButton";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import ViewFavoriteButton from "properties/propertyPage/propertyView/ViewFavoriteButton";
import { cn } from "_configs/twconfig";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NAME = "unitViewImageSlider";

type Props = {
    onClick: () => void;
    images: TImage[];
    unit?: TUnitListingMdl;
};

SwiperCore.use([Navigation]);

export function UnitViewSlider({ onClick, images, unit }: Props) {
    const [currentImage, setCurrentImage] = useState(0);

    return (
        <div
            id={NAME}
            className={cn(" relative w-full", {
                "lg:w-[calc(100%-35%)]": images.length > 1,
            })}
        >
            <Swiper
                onSlideChange={(e) => {
                    setCurrentImage(e.realIndex);
                }}
                loop
                slidesPerView={1}
                centeredSlides
                navigation={{
                    prevEl: `.swiper-button-prev.${NAME}`,
                    nextEl: `.swiper-button-next.${NAME}`,
                }}
                spaceBetween={20}
                observeParents
                observer
            >
                {images.map((photo, idx) => (
                    <SwiperSlide key={idx}>
                        <div className={"relative cursor-pointer"} onClick={onClick}>
                            <LazyLoadImage
                                className={"w-full h-[250px] md:h-[500px] object-cover rounded-2xl select-none"}
                                src={photo.url}
                                alt={"unit.photo"}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    // currentTarget.src = new URL(`https://zisla.com/${photo.url}`).href;
                                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                                }}
                            />

                            <div
                                className={
                                    "absolute bottom-[8px] md:bottom-5 right-[7px] md:right-5 z-10 text-white cursor-pointer bg-[var(--color-primary)] rounded"
                                }
                            >
                                <img src={extandImage} alt={"extand-image"} />
                            </div>
                            <GalleryPagination
                                currentImage={currentImage}
                                totalImages={images.length}
                                prevClass={`swiper-modal-button-prev ${NAME}`}
                                nextClass={`swiper-modal-button-next ${NAME}`}
                                className="grid items-center absolute left-[7px] opacity-70 bottom-[8px] w-16 h-5 md:w-20 md:h-6 rounded-[5px] bg-[#1A3E4D] z-50 text-center"
                                noArrow
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={`swiper-button-prev ${NAME}`} />
            <div className={`swiper-button-next ${NAME}`} />
            <div className="sm:hidden absolute top-2 right-2 z-50 flex gap-x-2">
                <ShareButton />
                {!!unit && <ViewFavoriteButton item={unit} resource={MAIN_RESOURCE.UNIT} />}
            </div>
        </div>
    );
}
