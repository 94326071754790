import React from "react";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { PropertyCardListing } from "properties/propertyCard/PropertyCardListing";
import { useTranslation } from "react-i18next";
import { useCommunityStore } from "communities/CommunityContext";

SwiperCore.use([Navigation]);

const NAME = "communitiesCardSlider";

export function CommunitiesCardSlider() {
    const { t } = useTranslation();
    const { community } = useCommunityStore();

    return (
        <>
            <CommunitiesTitle
                title={`${t("communitiesPage.propertiesSliderTitle")} ${community.localized.title}`}
                delimiter={3}
            />
            <div id={NAME} className={"relative"}>
                <Swiper
                    loop
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    slidesPerView={3}
                    spaceBetween={20}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        690: {
                            slidesPerView: 1.5,
                        },
                        1180: {
                            slidesPerView: 2.5,
                        },
                        1400: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {community.properties.map((property) => (
                        <>
                            {property && (
                                <SwiperSlide key={property._id} className={"h-auto"}>
                                    <PropertyCardListing property={property} key={property?._id} />
                                </SwiperSlide>
                            )}
                        </>
                    ))}
                </Swiper>
                <div className={`swiper-button-prev ${NAME}`} />
                <div className={`swiper-button-next ${NAME}`} />
            </div>
        </>
    );
}
