import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_PURPOSE, PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { PropertiesStore } from "properties/_stores/propertiesStore";
import { TLang } from "_configs/sharedConfig";
import { MEXICO_PROVINCES } from "_common/regions/regions";
import { reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";

export function usePropertiesPageMeta(propertiesStore: PropertiesStore, listStore: ListStore<TPropertyListingMdl>) {
    const { t } = useTranslation();
    const { isAtLeastProvincePage, cityForMeta, isNeighborhoodPage, isLuxury, isBeachfront } = useSearchPageParams();
    let propertyType: PROPERTY_TYPE | undefined;

    const type = listStore.filters.find((filter: TFilter) => filter.id === "type");

    if (type) {
        const filters = JSON.parse(JSON.stringify(type.value));
        propertyType = filters[0];
    }
    let metaTitle = t("propertiesPage.meta.BUY.title.generic");
    let metaDescription = t("propertiesPage.meta.BUY.description.generic");
    let title = t("propertiesPage.meta.BUY.h1.generic");
    let seoText = t("propertiesPage.meta.BUY.seo.generic");
    let geoZoneName;
    if (isAtLeastProvincePage) {
        geoZoneName = propertiesStore.addressParams?.neighbourhood
            ? propertiesStore.addressParams.neighbourhood
            : propertiesStore.addressParams?.city &&
              propertiesStore.addressParams.city.toLowerCase() === cityForMeta?.toLowerCase()
            ? propertiesStore.addressParams.city
            : propertiesStore.addressParams.regionLong &&
              Object.values(MEXICO_PROVINCES)
                  .map((regions) => reformatUrlForOriginalName(regions))
                  .includes(propertiesStore.addressParams.regionLong.toLowerCase())
            ? propertiesStore.addressParams.regionLong
            : cityForMeta;

        metaTitle = t("propertiesPage.meta.geoZone.BUY.title.generic", {
            geoZoneName: geoZoneName,
        });
        metaDescription = t("propertiesPage.meta.geoZone.BUY.description.generic", {
            geoZoneName: geoZoneName,
        });
        title = t("propertiesPage.meta.geoZone.BUY.h1.generic", {
            geoZoneName: geoZoneName,
        });
        seoText = t("propertiesPage.meta.geoZone.BUY.seo.generic", {
            geoZoneName: geoZoneName,
        });
        if (propertyType) {
            metaTitle = t("propertiesPage.meta.geoZone.BUY.title." + propertyType, {
                geoZoneName: geoZoneName,
            });
            metaDescription = t("propertiesPage.meta.geoZone.BUY.description." + propertyType, {
                geoZoneName: geoZoneName,
            });
            title = t("propertiesPage.meta.geoZone.BUY.h1." + propertyType, {
                geoZoneName: geoZoneName,
            });
            seoText = t("propertiesPage.meta.geoZone.BUY.seo." + propertyType, {
                geoZoneName: geoZoneName,
            });
            if (isBeachfront) {
                metaTitle = t("propertiesPage.meta.geoZone.BUY.title.beachfront." + propertyType, {
                    geoZoneName: geoZoneName,
                });
                metaDescription = t("propertiesPage.meta.geoZone.BUY.description.beachfront." + propertyType, {
                    geoZoneName: geoZoneName,
                });
                title = t("propertiesPage.meta.geoZone.BUY.h1.beachfront." + propertyType, {
                    geoZoneName: geoZoneName,
                });
                seoText = t("propertiesPage.meta.geoZone.BUY.seo.beachfront." + propertyType, {
                    geoZoneName: geoZoneName,
                });
            } else if (isLuxury) {
                metaTitle = t("propertiesPage.meta.geoZone.BUY.title.luxury." + propertyType, {
                    geoZoneName: geoZoneName,
                });
                metaDescription = t("propertiesPage.meta.geoZone.BUY.description.luxury." + propertyType, {
                    geoZoneName: geoZoneName,
                });
                title = t("propertiesPage.meta.geoZone.BUY.h1.luxury." + propertyType, {
                    geoZoneName: geoZoneName,
                });
                seoText = t("propertiesPage.meta.geoZone.BUY.seo.luxury." + propertyType, {
                    geoZoneName: geoZoneName,
                });
            } else if (isNeighborhoodPage) {
                metaTitle = t("propertiesPage.meta.geoZone.BUY.title.neighborhood." + propertyType, {
                    neighborhood: propertiesStore.addressParams.neighbourhood,
                    city: propertiesStore.addressParams.city,
                });
                metaDescription = t("propertiesPage.meta.geoZone.BUY.description.neighborhood." + propertyType, {
                    neighborhood: propertiesStore.addressParams.neighbourhood,
                    city: propertiesStore.addressParams.city,
                });
                title = t("propertiesPage.meta.geoZone.BUY.h1.neighborhood." + propertyType, {
                    neighborhood: propertiesStore.addressParams.neighbourhood,
                    city: propertiesStore.addressParams.city,
                });
                seoText = t("propertiesPage.meta.geoZone.BUY.seo.neighborhood." + propertyType, {
                    neighborhood: propertiesStore.addressParams.neighbourhood,
                    city: propertiesStore.addressParams.city,
                });
            }
        } else if (isBeachfront) {
            metaTitle = t("propertiesPage.meta.geoZone.BUY.title.beachfront.generic", {
                geoZoneName: geoZoneName,
            });
            metaDescription = t("propertiesPage.meta.geoZone.BUY.description.beachfront.generic", {
                geoZoneName: geoZoneName,
            });
            title = t("propertiesPage.meta.geoZone.BUY.h1.beachfront.generic", {
                geoZoneName: geoZoneName,
            });
            seoText = t("propertiesPage.meta.geoZone.BUY.seo.beachfront.generic", {
                geoZoneName: geoZoneName,
            });
        } else if (isLuxury) {
            metaTitle = t("propertiesPage.meta.geoZone.BUY.title.luxury.generic", {
                geoZoneName: geoZoneName,
            });
            metaDescription = t("propertiesPage.meta.geoZone.BUY.description.luxury.generic", {
                geoZoneName: geoZoneName,
            });
            title = t("propertiesPage.meta.geoZone.BUY.h1.luxury.generic", {
                geoZoneName: geoZoneName,
            });
            seoText = t("propertiesPage.meta.geoZone.BUY.seo.luxury.generic", {
                geoZoneName: geoZoneName,
            });
        } else if (isNeighborhoodPage) {
            metaTitle = t("propertiesPage.meta.geoZone.BUY.title.neighborhood.generic", {
                neighborhood: propertiesStore.addressParams.neighbourhood,
                city: propertiesStore.addressParams.city,
            });
            metaDescription = t("propertiesPage.meta.geoZone.BUY.description.neighborhood.generic", {
                neighborhood: propertiesStore.addressParams.neighbourhood,
                city: propertiesStore.addressParams.city,
            });
            title = t("propertiesPage.meta.geoZone.BUY.h1.neighborhood.generic", {
                neighborhood: propertiesStore.addressParams.neighbourhood,
                city: propertiesStore.addressParams.city,
            });
            seoText = t("propertiesPage.meta.geoZone.BUY.seo.neighborhood.generic", {
                neighborhood: propertiesStore.addressParams.neighbourhood,
                city: propertiesStore.addressParams.city,
            });
        }
    } else {
        if (propertyType) {
            metaTitle = t("propertiesPage.meta.BUY.title." + propertyType);
            metaDescription = t("propertiesPage.meta.BUY.description." + propertyType);
            title = t("propertiesPage.meta.BUY.h1." + propertyType);
            seoText = t("propertiesPage.meta.BUY.seo." + propertyType);
            if (isBeachfront) {
                metaTitle = t("propertiesPage.meta.BUY.title.beachfront." + propertyType);
                metaDescription = t("propertiesPage.meta.BUY.description.beachfront." + propertyType);
                title = t("propertiesPage.meta.BUY.h1.beachfront." + propertyType);
                seoText = t("propertiesPage.meta.BUY.seo.beachfront." + propertyType);
            } else if (isLuxury) {
                metaTitle = t("propertiesPage.meta.BUY.title.luxury." + propertyType);
                metaDescription = t("propertiesPage.meta.BUY.description.luxury." + propertyType);
                title = t("propertiesPage.meta.BUY.h1.luxury." + propertyType);
                seoText = t("propertiesPage.meta.BUY.seo.luxury." + propertyType);
            }
        } else if (isBeachfront) {
            metaTitle = t("propertiesPage.meta.BUY.title.beachfront.generic");
            metaDescription = t("propertiesPage.meta.BUY.description.beachfront.generic");
            title = t("propertiesPage.meta.BUY.h1.beachfront.generic");
            seoText = t("propertiesPage.meta.BUY.seo.beachfront.generic");
        } else if (isLuxury) {
            metaTitle = t("propertiesPage.meta.BUY.title.luxury.generic");
            metaDescription = t("propertiesPage.meta.BUY.description.luxury.generic");
            title = t("propertiesPage.meta.BUY.h1.luxury.generic");
            seoText = t("propertiesPage.meta.BUY.seo.luxury.generic");
        }
    }
    // Custom title for geozone
    if (geoZonesStore.geoZone) {
        if (propertyType || isBeachfront || isLuxury) {
            let typeOrBeachfrontOrLuxury = propertyType as PROPERTY_TYPE | "luxury" | "beachfront";
            if (isBeachfront) typeOrBeachfrontOrLuxury = "beachfront";
            if (isLuxury) typeOrBeachfrontOrLuxury = "luxury";
            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                    typeOrBeachfrontOrLuxury
                ]?.title
            ) {
                title =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                        typeOrBeachfrontOrLuxury
                    ]?.title;
            }

            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                    typeOrBeachfrontOrLuxury
                ]?.meta?.title
            ) {
                metaTitle =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                        typeOrBeachfrontOrLuxury
                    ]?.meta?.title ?? "";
            }

            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                    typeOrBeachfrontOrLuxury
                ]?.text
            ) {
                seoText =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                        typeOrBeachfrontOrLuxury
                    ]?.text;
            }

            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                    typeOrBeachfrontOrLuxury
                ]?.meta?.description
            ) {
                metaDescription =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.[
                        typeOrBeachfrontOrLuxury
                    ]?.meta?.description ?? "";
            }
        } else {
            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.title) {
                title = geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.title;
            }

            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.text) {
                seoText = geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.text;
            }

            if (geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.meta?.title) {
                metaTitle =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.meta?.title ??
                    "";
            }

            if (
                geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.meta?.description
            ) {
                metaDescription =
                    geoZonesStore.geoZone.localized?.[i18next.language as TLang]?.[PROPERTY_PURPOSE.BUY]?.meta
                        ?.description ?? "";
            }
        }
    }

    return { title, metaTitle, seoText, metaDescription, geoZoneName };
}
