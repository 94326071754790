import { FEES, TFees, TFinancing, TRangeNumber } from "properties/_models/PropertyMdl";
import { TMeta, TRootComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { THtmlResponse, TImage } from "_common/types/baseTypes";
import { TFeatureUnit, TParking } from "admin/units/_models/UnitMdl";

export const UNIT_TYPE_PAGE_SIZE = 6;

export enum PRICING {
    CUMULATE = "CUMULATE",
    BASE_ONLY = "BASE_ONLY",
}

export enum UNIT_TYPE {
    commercial = "commercial",
    showroom = "showroom",
    bedrooms = "bedrooms",
    penthouse = "penthouse",
    garden = "garden",
    h = "h",
    hOne = "hOne",
    hTwo = "hTwo",
    hThree = "hThree",
    hFour = "hFour",
    swimup = "swimup",
    microlot = "microlot",
    macrolot = "macrolot",
    mixte = "mixte",
    residential = "residential",
    commercialLand = "commercialLand",
    suite = "suite",
    buildableModel = "buildableModel",
    none = "none",
}

export const UNIT_TYPE_FILTERED: string[] = [UNIT_TYPE.penthouse, UNIT_TYPE.garden, UNIT_TYPE.swimup];

export type TStatusByPhase = {
    deliveryDate?: dayjs.Dayjs;
    status: INVENTORY_STATUS;
};

export type TNbAvailableByPhase = {
    deliveryDate?: dayjs.Dayjs;
    numberOfUnitAvailable: number;
};

export enum INVENTORY_STATUS {
    AVAILABLE = "AVAILABLE",
    RESERVED = "RESERVED",
    SOLD = "SOLD",
    BLOCKED = "BLOCKED",
    NONE = "NONE",
}

export interface TUnitLocalized {
    title: string;
    description: string;
    urlAlias: string;
    discountText?: string;
    meta: TMeta;
    component: TRootComponent;
}

export type TUnitTypeBaseMdl = {
    _id: string;
    property: string;
    sold: boolean;
    published: boolean;

    price: TRangeNumber;
    priceInPesos: TRangeNumber;
    priceIsInPesos: boolean;
    priceIsNotDisplayed: boolean;
    pricing: PRICING;
    fees: TFees;
    type: UNIT_TYPE;
    parkings: TParking[];
    storage: boolean | number;
    status: INVENTORY_STATUS;
    reserved: boolean;
    maidQuarter: boolean;
    discount: boolean;
    features: TFeatureUnit;
    views: TFeatureUnit | null;
    financing: TFinancing[];

    photos?: TImage[];
    floorPlans?: TImage[];
    furnitures?: TImage[];
    showroom?: TImage[];
    constructProgress: TImage[];
    bathrooms: number;
    powderRooms: number;
    bedrooms: number;
    basementBedrooms?: number;

    // SURFACES
    interiorConstruction: TRangeNumber;
    exteriorConstruction: TRangeNumber; // old terraceSurface
    terraceSurface: TRangeNumber;
    rooftopSurface: TRangeNumber;
    yardSize: TRangeNumber;
    poolSurface: TRangeNumber;
    storageSurface: TRangeNumber;
    parkingSurface: TRangeNumber;
    totalConstructArea: TRangeNumber; // old squareSurface if condo
    lotSurface: TRangeNumber; // old squareSurface if not condo
    cus: TRangeNumber;
    cos: TRangeNumber;
    // SURFACES

    nbOfLevels: number;
    unitDescription: string;
    numberOfUnit: number;
    numberOfAvailableUnits: number;
    privatePool: TRangeNumber;
    privateTerrace: TRangeNumber;

    variant: string;
    isUpdateFromConversion: boolean;

    visit3D: string;
    video: string;
    video2: string;

    position: number;
    deliveryDates: dayjs.Dayjs[];
    firstDayOnSite: dayjs.Dayjs;

    htmlResponse?: THtmlResponse;

    createdAt: Dayjs;
    updatedAt: Dayjs;

    placeholdersUnitsIds?: string[];
};

export type TUnitTypeMdl = TUnitTypeBaseMdl & {
    localized: {
        fr: TUnitLocalized;
        es: TUnitLocalized;
        en: TUnitLocalized;
        zh: TUnitLocalized;
    };
    property: string;
};

export type TUnitTypeListingMdl = TUnitTypeBaseMdl & {
    localized: TUnitLocalized;
    nbOfAvailableUnits: number;
};

export type TUnitDashboardListingMdl = TUnitTypeListingMdl & {
    _localized: {
        fr: TUnitLocalized;
        es: TUnitLocalized;
        en: TUnitLocalized;
        zh: TUnitLocalized;
    };
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
        favorited: {
            count: number;
        };
    };
};

export type TUnitTypeBackMdl = Pick<
    TUnitTypeMdl,
    | "_id"
    | "price"
    | "priceInPesos"
    | "pricing"
    | "property"
    | "localized"
    | "published"
    | "photos"
    | "floorPlans"
    | "bedrooms"
    | "bathrooms"
    | "type"
    | "variant"
    | "priceIsInPesos"
    | "lotSurface"
    | "totalConstructArea"
>;

export function createDefaultUnit(
    propertyId: string,
    financing?: TFinancing[],
    fees?: TFees,
): Omit<TUnitTypeMdl, "_id"> {
    return {
        cos: { min: 0, max: 0 },
        cus: { min: 0, max: 0 },
        exteriorConstruction: { min: 0, max: 0 },
        interiorConstruction: { min: 0, max: 0 },
        lotSurface: { min: 0, max: 0 },
        parkingSurface: { min: 0, max: 0 },
        poolSurface: { min: 0, max: 0 },
        rooftopSurface: { min: 0, max: 0 },
        storageSurface: { min: 0, max: 0 },
        terraceSurface: { min: 0, max: 0 },
        totalConstructArea: { min: 0, max: 0 },
        yardSize: { min: 0, max: 0 },
        localized: {
            fr: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            es: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            en: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            zh: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
        },
        sold: false,
        published: false,
        property: propertyId,

        price: { min: 0, max: 0 },
        pricing: PRICING.CUMULATE,
        priceInPesos: { min: 0, max: 0 },
        privatePool: { min: 0, max: 0 },
        privateTerrace: { min: 0, max: 0 },
        priceIsInPesos: false,
        financing: financing ?? [
            {
                signin: 0,
                building: 0,
                delivery: 0,
                deeding: 0,
                discount: 0,
            },
        ],
        fees: fees ?? { type: FEES.toBeDetermined, value: 0 },
        priceIsNotDisplayed: false,
        type: UNIT_TYPE.showroom,
        parkings: [],
        deliveryDates: [],
        storage: false,
        reserved: false,
        maidQuarter: false,
        status: INVENTORY_STATUS.AVAILABLE,
        discount: false,
        features: {},
        views: {},
        bathrooms: 0,
        powderRooms: 0,
        bedrooms: 0,
        basementBedrooms: 0,
        nbOfLevels: 0,
        unitDescription: "",
        isUpdateFromConversion: false,
        numberOfUnit: 0,
        numberOfAvailableUnits: 0,

        photos: [],
        floorPlans: [],
        constructProgress: [],
        furnitures: [],
        showroom: [],

        visit3D: "",
        video: "",
        video2: "",
        variant: "",

        position: 0,

        firstDayOnSite: dayjs(),
        createdAt: dayjs(),
        updatedAt: dayjs(),
    };
}
