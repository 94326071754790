import React from "react";
import { observer } from "mobx-react-lite";
import { PhaseEarliestDisplay } from "properties/propertyPage/PhaseEarliestDisplay";
import { useUnitTypeStore } from "unitTypes/_store/UnitTypeContext";
import { unitsStore } from "units/_stores/unitsStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import _ from "lodash";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { getPropertyStatusFromUnits } from "_common/_utils/inventoryUtils";
import dayjs from "dayjs";

export const PhaseEarlyUnitType = observer(() => {
    const { unitType } = useUnitTypeStore();
    const { property } = usePropertyStore();
    const listStore = unitsStore.getListStore(unitType._id);
    const allPhase = property.inventory?.phases ?? [];
    const unitsGroupedByPhase = _.groupBy(listStore.items.filter(Boolean), "phaseId");
    const phasesAvailable = allPhase
        .filter((phase) => unitsGroupedByPhase[phase._id]?.length > 0)
        .sort((a, b) => (dayjs(a.deliveryDate).isBefore(dayjs(b.deliveryDate)) ? 1 : -1));
    return (
        <PhaseEarliestDisplay
            status={getPropertyStatusFromUnits(listStore.items.filter(Boolean) as TUnitListingMdl[])}
            phases={phasesAvailable}
        />
    );
});
