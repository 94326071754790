import React from "react";
import UnitSectionLayout from "units/UnitSectionLayout";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { TImage } from "_common/types/baseTypes";

SwiperCore.use([Navigation]);
const NAME = "unitViewImageSlider";

interface Props {
    floorPlans?: TImage[];
}

export function UnitFloorPlans({ floorPlans }: Props) {
    if (!floorPlans || !floorPlans.length) return null;
    return (
        <UnitSectionLayout title="Floor Plan" activeSeparator>
            <div className="flex justify-center items-center">
                <div id={NAME} className={"relative w-full lg:w-[calc(100%-40%)] max-w-[750px]"}>
                    <Swiper
                        loop
                        slidesPerView={1}
                        centeredSlides
                        navigation={{
                            prevEl: `.swiper-button-prev.${NAME}`,
                            nextEl: `.swiper-button-next.${NAME}`,
                        }}
                        spaceBetween={20}
                        observeParents
                        observer
                        className={"h-full"}
                    >
                        {floorPlans.map((floorPlan, idx) => (
                            <SwiperSlide key={idx}>
                                <div className="cursor-pointer aspect-video">
                                    <img
                                        className="h-[230px] w-full md:h-[600px] object-contain rounded-2xl select-none"
                                        src={floorPlan.url}
                                        alt={"floorPlan.photo"}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {floorPlans.length > 1 && (
                        <>
                            <div className={`swiper-button-prev ${NAME}`} />
                            <div className={`swiper-button-next ${NAME}`} />
                        </>
                    )}
                </div>
            </div>
        </UnitSectionLayout>
    );
}
