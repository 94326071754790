import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { UnitPage } from "units/UnitPage";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";

type Props = {
    unit: TUnitListingMdl;
    open: boolean;
    onClose: () => void;
};

export function UnitPageDialog({ unit, open, onClose }: Props) {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
            <UnitPage unit={unit} onClose={onClose} />
        </Dialog>
    );
}
