import arrowLeft from "../../../assets/images/icons/arrowLeft.svg";
import arrowRight from "../../../assets/images/icons/arrowRight.svg";
import React from "react";

interface Props {
    currentImage: number;
    totalImages: number;
    prevClass: string;
    nextClass: string;
    noArrow?: boolean;
    className?: string;
}

export default function GalleryPagination({
    currentImage,
    totalImages,
    prevClass,
    nextClass,
    noArrow,
    className,
}: Props) {
    return (
        <div
            className={className ? className : "absolute bottom-10 left-1/2 transform -translate-x-1/2 opacity-80 z-50"}
        >
            <div className="flex gap-x-5 items-center md:h-6 rounded-[5px] bg-[#1A3E45] px-3 justify-center">
                {!noArrow && (
                    <img
                        src={arrowLeft}
                        alt="PreviousImage"
                        className={`text-white text-center cursor-pointer ${prevClass}`}
                    />
                )}
                <p className="text-white text-center">
                    {currentImage + 1}/{totalImages}
                </p>
                {!noArrow && (
                    <img
                        src={arrowRight}
                        alt="NextImage"
                        className={`text-white text-center cursor-pointer ${nextClass}`}
                    />
                )}
            </div>
        </div>
    );
}
