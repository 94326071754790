import React, { useEffect, useMemo } from "react";
import { CardTypesList } from "home/CardTypesList";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useTranslation } from "react-i18next";
import { usePropertyPageContext } from "properties/PropertyPageContextAlt";
import { Container } from "@material-ui/core";
import { BREAKPOINTS_SECTION_CARDS } from "properties/components/BREAKPOINTS_SECTION_CARDS";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { propertiesStore } from "properties/_stores/propertiesStore";

type Props = {
    geoZoneName?: string;
    geoZoneId?: string;
};

export function PropertiesTypesSection({ geoZoneName, geoZoneId }: Props) {
    useEffect(() => {
        propertiesStore.fetchSortedTypes(geoZoneId);
    }, [geoZoneId]);
    const { t } = useTranslation();
    const { mapOrListSelected } = usePropertyPageContext();
    const { propertyTypeLocalizedUrl, beachFrontOrLuxury } = useSearchPageParams();
    const breakPoints = useMemo(() => BREAKPOINTS_SECTION_CARDS[mapOrListSelected], [mapOrListSelected]);
    return (
        <Container className={"!px-0 lg:!px-12"}>
            <LoadableFromLoading
                noSpinner
                noText
                loading={propertiesStore.sortTypesState}
                renderer={(_status, error) => {
                    const filteredTypes = propertiesStore.sortTypes.filter(
                        (typeOrOption) =>
                            typeOrOption.propertyType !== propertyTypeLocalizedUrl &&
                            typeOrOption.propertyType !== beachFrontOrLuxury,
                    );
                    if (error || !filteredTypes.length) return null;
                    return (
                        <>
                            <h2 className={"md:mb-4 text-xl md:text-center text-[var(--color-primary)]"}>
                                {geoZoneName
                                    ? t(
                                          `propertiesPage.propertyTypeSection.titleGeozone${
                                              propertyTypeLocalizedUrl || beachFrontOrLuxury ? "Other" : ""
                                          }`,
                                          { geoZoneName: geoZoneName },
                                      )
                                    : t(`propertiesPage.propertyTypeSection.title`)}
                            </h2>
                            <CardTypesList removeGeoZone={false} items={filteredTypes} breakPoints={breakPoints} />
                        </>
                    );
                }}
            />
        </Container>
    );
}
