import { BaseResourceStore, TInitialState } from "_common/resources/BaseResourceStore";
import {
    FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH,
    PROPERTY_PURPOSE,
    TPropertyCard,
    TPropertyDashboardListingMdl,
    TPropertyListingMdl,
    TPropertyPage,
} from "properties/_models/PropertyMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import i18next from "i18next";
import { action, computed, observable, toJS } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import {
    getInitialStateValue,
    getResourceInitialStateValue,
    putPromiseResourceResultInInitialState,
    putPromiseResultInInitialState,
} from "_common/_utils/initialStateUtils";
import { ListStore } from "_common/list/ListStore";
import { TMapCoordinates, TMapLocation } from "maps/Map";
import { DEFAULT_LOCATION, DEFAULT_MAP_COORDINATES, DEFAULT_ZOOM } from "_common/_utils/searchUtils";
import { TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { THomeCard } from "_common/propertyTypes/propertyTypes";
import _ from "lodash";
import { LatLng } from "leaflet";
import { WithRequiredProperty } from "_common/types/GenericTypes";
import { TLang } from "_configs/sharedConfig";

export const PROPERTIES_NAVIGATION_HISTORY_KEY = "PROPERTIES_NAVIGATION_HISTORY";

export class PropertiesStore extends BaseResourceStore<TPropertyListingMdl> {
    purpose = PROPERTY_PURPOSE.BUY;

    @observable count = 0;
    @observable countUnits = 0;
    @observable addressParams: {
        region: string | undefined;
        regionLong: string | undefined;
        city: string | undefined;
        address: string | undefined;
        neighbourhood: string | undefined;
    } = {
        region: "",
        city: "",
        address: "",
        neighbourhood: "",
        regionLong: "",
    };
    @observable searchParams: {
        autocomplete: google.maps.places.Autocomplete | null;
        location: TMapLocation | LatLng;
        zoom: number;
        mapCoordinates: TMapCoordinates;
    } = {
        autocomplete: null,
        location: DEFAULT_LOCATION,
        zoom: DEFAULT_ZOOM,
        mapCoordinates: DEFAULT_MAP_COORDINATES,
    };
    @observable featuredPropertiesState: LoadingStateMdl<TPropertyListingMdl[]> = new LoadingStateMdl();
    @observable featuredProperties: TPropertyListingMdl[] = [];
    @observable sortCitiesState: LoadingStateMdl<THomeCard[]> = new LoadingStateMdl();
    @observable sortCities: THomeCard[] = [];
    @observable sortTypesState: LoadingStateMdl<THomeCard[]> = new LoadingStateMdl();
    @observable sortTypes: THomeCard[] = [];
    @observable items: TPropertyListingMdl[] = [];
    @observable mapPropertyIdSelected: string | undefined = undefined;
    @observable propertiesSearchState: LoadingStateMdl<TPropertyListingMdl[]> = new LoadingStateMdl<
        TPropertyListingMdl[]
    >();
    @observable propertiesSearch: TPropertyListingMdl[] = [];
    abortControllers = {
        deepList: {
            ctrl: new AbortController(),
            state: new LoadingStateMdl(),
        },
        list: {
            ctrl: new AbortController(),
            state: new LoadingStateMdl(),
        },
    };
    setMapPropertySelected = _.debounce((propertyId?: string) => {
        this.mapPropertyIdSelected = propertyId;
    }, 400);

    constructor() {
        super("properties");
        // when(
        //     () => geoZonesStore?.geoZone?.osmId === "root",
        //     () => {
        //         this.setLocationFromSearch(geoZonesStore.defaultGeoZone!);
        //     },
        // );
        this.onInit();
    }

    @computed get propertiesSearchResults() {
        return this.propertiesSearch;
    }

    @computed get getArrCache() {
        const q = this.items.length;
        if (this.listsStores[PROPERTY_PURPOSE.BUY]?.filters.length > 2) {
            return this.items;
        }
        return Object.values(this.cache);
    }

    static areDynamicsFiltersInFilters(filters: TFilter[]) {
        return this.getDynamicsFiltersFromFilters(filters).length > 0;
    }

    static getDynamicsFiltersFromFilters(filters: TFilter[]) {
        return filters.filter(
            (filter) =>
                !Object.values(FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH).includes(
                    filter.id as FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH,
                ),
        );
    }

    @action
    setZoomForMobile() {
        this.searchParams.zoom = 2;
    }

    @action setSearchParams(
        params: Partial<{ [key in "autocomplete" | "location" | "zoom" | "mapCoordinates"]: any | number }>,
    ) {
        this.searchParams = {
            ...this.searchParams,
            ...params,
        };
    }

    @action resetItems() {
        this.items = [];
    }

    @action setLocation(location?: TMapLocation) {
        if (location) {
            this.searchParams.location = location;
        } else {
            this.searchParams.location = DEFAULT_LOCATION;
        }
    }

    @action setBounds(bounds?: TMapCoordinates) {
        if (bounds) {
            this.searchParams.mapCoordinates = bounds;
        } else {
            this.searchParams.location = DEFAULT_LOCATION;
        }
    }

    @action setAutocomplete(autocomplete: google.maps.places.Autocomplete | null) {
        this.searchParams.autocomplete = autocomplete;
    }

    @action
    putItemInCache(item: TPropertyListingMdl) {
        super.putItemInCache(item);
        const itemIdex = this.items.findIndex((_item) => _item._id === item._id);
        if (itemIdex === -1) this.items.push(item);
    }

    list(
        offset = 0,
        limit?: number,
        _listId?: string,
        sort?: { [key: string]: number },
        filters?: TFilter[],
        countForStats = true,
    ) {
        if (this.abortControllers.list.state.isLoading) {
            this.abortControllers.list.ctrl.abort();
        }
        this.abortControllers.list.ctrl = new AbortController();
        this.abortControllers.list.state.startLoading();
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        if (!filters) filters = [];
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/listing?offset=${offset}&limit=${limit}${sortParam}${filtersParam}&lang=${
            i18next.language
        }&browser=${countForStats && __BROWSER__}`;
        const promise = fetchUtils
            .get<{ count: number; items: TPropertyCard[] }>(url, {
                signal: this.abortControllers.list.ctrl.signal,
            })
            .then(
                ({ data: { count, items } }) => {
                    this.abortControllers.list.state.setSuccess();
                    return {
                        count,
                        items: items.map((item) => {
                            const reformattedItem = this.reformatItem(item);
                            this.putItemInCache(reformattedItem);
                            return reformattedItem;
                        }),
                    };
                },
                (e) => this.abortControllers.list.state.setError(e),
            );
        return promise;
    }

    listForDeveloper(sort?: { [key: string]: number }, filters?: TFilter[], token?: string) {
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        let filtersParam = "";
        if (!filters) filters = [];
        filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/dashboardListing${token ? `/${token}` : ""}?${sortParam}${filtersParam}&lang=${
            i18next.language
        }`;
        return fetchUtils.get<TPropertyDashboardListingMdl[]>(url).then(({ data }) => {
            data.map((property) => {
                const reformattedItem = this.reformatItem(property);
                this.putItemInCache(reformattedItem);
                return reformattedItem;
            });
            return { count: data.length, items: data };
        });
    }

    deepList(filters?: TFilter[]) {
        if (this.abortControllers.deepList.state.isLoading) {
            this.abortControllers.deepList.ctrl.abort();
        }
        this.abortControllers.deepList.ctrl = new AbortController();
        this.abortControllers.deepList.state.startLoading();
        if (!filters) filters = [];
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/deepListing?${filtersParam}&lang=${i18next.language}`;
        const promise = fetchUtils
            .get<{ count: number; items: TPropertyListingMdl[] }>(url, {
                signal: this.abortControllers.deepList.ctrl.signal,
            })
            .then(
                ({ data: { count, items } }) => {
                    this.abortControllers.deepList.state.setSuccess();
                    return {
                        count,
                        items: items.map((item) => {
                            const reformattedItem = this.reformatItem(item);
                            this.putItemInCache(reformattedItem);
                            return reformattedItem;
                        }),
                    };
                },
                (e) => this.abortControllers.deepList.state.setError(e),
            );
        return promise;
    }

    fetchPremiumProperties(limit?: number) {
        if (!this.featuredPropertiesState.isLoading && !this.featuredPropertiesState.isSucceeded) {
            this.featuredPropertiesState.startLoading();
            const url = `${this.apiPath}/listing?limit=${limit}&lang=${i18next.language}`;
            const promise = fetchUtils
                .get<{ count: number; items: TPropertyListingMdl[] }>(url)
                .then(({ data: { items } }) => {
                    const properties = items.map((item) => {
                        const reformattedItem = this.reformatItem(item);
                        this.putItemInCache(reformattedItem);
                        return reformattedItem;
                    });
                    this.featuredPropertiesState.setSuccess(properties);
                    this.featuredProperties = properties;
                    return properties;
                });
            putPromiseResourceResultInInitialState(
                "featuredProperties",
                promise.then((properties) => properties),
            );
            return promise;
        }
        return;
    }

    fetchSortedCities() {
        if (!this.sortCitiesState.isLoading && !this.sortCitiesState.isSucceeded) {
            this.sortCitiesState.startLoading();
            const url = `${this.apiPath}/sortedCities?lang=${i18next.language}`;
            const promise = fetchUtils.get<THomeCard[]>(url);
            promise
                .then(
                    action(({ data }) => {
                        this.sortCities = data;
                        this.sortCitiesState.setSuccess(data);
                    }),
                )
                .catch((e) => console.error(e));
            putPromiseResultInInitialState("sortedCities", promise);
        }
        return this.sortCitiesState;
    }

    fetchSortedTypes(geoZoneId?: string) {
        if (!this.sortTypesState.isLoading) {
            const geoZoneIdParam = geoZoneId ? `/${geoZoneId}` : "";
            this.sortTypesState.startLoading();
            const url = `${this.apiPath}/sortedTypes${geoZoneIdParam}?lang=${i18next.language}`;
            const promise = fetchUtils.get<THomeCard[]>(url);
            promise
                .then(
                    action(({ data }) => {
                        this.sortTypes = data;
                        this.sortTypesState.setSuccess(data);
                    }),
                )
                .catch((e) => console.error(e));
            putPromiseResultInInitialState("sortedTypes", promise);
        }
        return this.sortTypesState;
    }

    fetchPropertiesForSearch(value?: string, limit = 5) {
        if (!this.propertiesSearchState.isLoading) {
            this.propertiesSearchState.startLoading();
            const filters: TFilter[] = [];
            value ? filters.push({ id: "localized.en.title", type: TFilterType.STRING, value }) : "";
            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const url = `${this.apiPath}/listing?limit=${limit}&lang=${i18next.language}${filtersParam}&searchBar=true`;
            return fetchUtils.get<{ items: TPropertyListingMdl[] }>(url).then(({ data }) => {
                console.log("results data items", data.items);
                this.propertiesSearch = data.items;
                console.log("propertiesSearch", toJS(this.propertiesSearch));
                this.propertiesSearchState.setSuccess(data.items);
            });
        }
    }

    getByAliasUrl(urlAlias: string, lang: string = i18next.language, wantedLanguage = "") {
        if (!wantedLanguage) {
            for (const propertyId of Object.keys(this.cache)) {
                if (
                    this.cache[propertyId]?.localized.urlAlias === urlAlias &&
                    !!this.cache[propertyId]?.constructProgress
                ) {
                    return this.cache[propertyId];
                }
            }
        }
        const url = `${this.apiPath}/urlAlias/${urlAlias}/${lang}/${wantedLanguage}`;
        return fetchUtils
            .get<TPropertyPage>(url)
            .then(({ data }) => data)
            .catch((e) => console.error(e));
    }

    async getUnitsCount(filters?: TFilter[]) {
        if (!filters) return null;
        const filtersParam = `?filters=${JSON.stringify(filters)}`;
        const url = `${this.apiPath}/counting${filtersParam}`;
        return fetchUtils
            .get<{ numberOfUnits: number }>(url)
            .then(({ data }) => {
                this.countUnits = data.numberOfUnits;
            })
            .catch((e) => console.error(e));
    }

    setLocationFromSearch(geoZone: TGeoZoneMdl | Pick<TGeoZoneMdl, "address" | "name">) {
        if (geoZone) {
            this.addressParams.city = geoZone.address.city;
            this.addressParams.region = geoZone.address.province;
            this.addressParams.regionLong = geoZone.address?.provinceLong;
            this.addressParams.neighbourhood = geoZone.address.neighbourhood;
            this.addressParams.address = geoZone.name;
        }
    }

    addPropertyNavigationHistory(property: TPropertyPage) {
        let propertyHistory = localStorage.getItem(PROPERTIES_NAVIGATION_HISTORY_KEY);
        if (!propertyHistory) propertyHistory = "[]";
        if (!JSON.parse(propertyHistory).find((_property: TPropertyListingMdl) => _property._id === property._id)) {
            localStorage.setItem(
                PROPERTIES_NAVIGATION_HISTORY_KEY,
                JSON.stringify([
                    ...JSON.parse(propertyHistory),
                    { ..._.omit(_.pick(property, ["_id", "urlAlias", "localized"]), "localized.description") },
                ]),
            );
        } else {
            const arrayPropertyHistory: Partial<TPropertyListingMdl>[] = JSON.parse(propertyHistory);
            const index = arrayPropertyHistory.findIndex(
                (propertyInStorage: Partial<TPropertyListingMdl>) => propertyInStorage._id === property._id,
            );
            if (index < 0) return null;
            arrayPropertyHistory.splice(index, 1);
            arrayPropertyHistory.push({
                ..._.omit(_.pick(property, ["_id", "urlAlias", "localized"]), "localized.description"),
            });

            localStorage.setItem(PROPERTIES_NAVIGATION_HISTORY_KEY, JSON.stringify(arrayPropertyHistory));
        }
    }

    @action
    protected onReset() {
        this.items = [];
        this.cache = {};
        this.count = 0;
        this.countUnits = 0;
        this.listsStores = {};
        this.featuredPropertiesState = new LoadingStateMdl();
        super.onReset();
    }

    protected reformatItem(item: TPropertyListingMdl) {
        const formattedItem = { ...item };
        if (formattedItem.localized && "en" in formattedItem.localized) {
            formattedItem.localized = formattedItem.localized[i18next.language as TLang];
        }
        return super.reformatItem(formattedItem);
    }

    protected onInit(_fromRootCtor?: boolean) {
        super.onInit(_fromRootCtor);
        const initialState = getResourceInitialStateValue(this.purpose + this.name) as
            | TInitialState<TPropertyListingMdl>
            | undefined;
        if (initialState) {
            for (const listId in initialState.list ?? {}) {
                const pages: { [offset: number]: TPropertyListingMdl[] } = {};
                const numberOfPage = initialState?.list[listId]?.page ?? 1;
                for (let i = 0; i < numberOfPage; i++) {
                    if (numberOfPage - 1 === i) {
                        pages[i] = initialState?.items;
                    } else {
                        pages[i] = [];
                    }
                }
                this.listsStores[listId] = new ListStore(
                    listId,
                    this,
                    {
                        count: initialState.list[listId].count,
                        page: initialState.list[listId].page,
                        pages,
                    },
                    undefined,
                    undefined,
                    initialState?.list[listId]?.initialFilters,
                );
            }
        }
        void this.getUnitsCount(initialState?.list[PROPERTY_PURPOSE.BUY]?.initialFilters);

        const initialCenterState = getInitialStateValue<{ center: TMapLocation | LatLng; zoom: number }>("location");
        if (initialCenterState) {
            this.searchParams.location = initialCenterState.center;
            this.searchParams.zoom = initialCenterState.zoom;
        }

        const countInitialState = getInitialStateValue<{ count: number }>("properties");
        if (countInitialState) {
            this.count = countInitialState.count;
        }

        const initialGeoZoneState = getResourceInitialStateValue("geoZone") as
            | TInitialState<WithRequiredProperty<TGeoZoneMdl, "_id">>
            | undefined;
        if (initialGeoZoneState) {
            if (initialGeoZoneState.items[0]) {
                this.addressParams = {
                    region: initialGeoZoneState.items[0].address.province,
                    regionLong: initialGeoZoneState.items[0].address.provinceLong,
                    city: initialGeoZoneState.items[0].address.city,
                    address: initialGeoZoneState.items[0].name,
                    neighbourhood: initialGeoZoneState.items[0].address?.neighbourhood,
                };
            }
        }

        const initialFeaturedProperties = getResourceInitialStateValue("featuredProperties") as
            | TInitialState<TPropertyListingMdl>
            | undefined;
        if (initialFeaturedProperties) {
            this.featuredProperties = initialFeaturedProperties.items;
            this.featuredPropertiesState.setSuccess(initialFeaturedProperties.items);
        }
    }
}

const propertiesStore = new PropertiesStore();
export { propertiesStore };
