import { Swiper, SwiperSlide } from "swiper/react";
import { PropertyNewBadge } from "properties/propertyCard/components/PropertyNewBadge";
import extandImage from "../../../assets/images/communities/extandImage.svg";
import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import ShareButton from "properties/propertyPage/propertyView/ShareButton";
import ViewFavoriteButton from "properties/propertyPage/propertyView/ViewFavoriteButton";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { PropertyBreadcrumb } from "properties/propertyPage/PropertyBreadcrumb";
import { useTranslation } from "react-i18next";

const NAME = "propertyViewImageSlider";

type Props = {
    currentImage: number;
    setCurrentImage: (idx: number) => void;
    onClick: () => void;
};

SwiperCore.use([Navigation]);

export default function PropertyViewLuxurySlider({ currentImage, setCurrentImage, onClick }: Props) {
    const { property } = usePropertyStore();
    const { t } = useTranslation();
    return (
        <>
            <Swiper
                onSlideChange={(e) => setCurrentImage(e.realIndex)}
                loop
                slidesPerView={1}
                centeredSlides
                navigation={{
                    prevEl: `.swiper-button-prev.${NAME}`,
                    nextEl: `.swiper-button-next.${NAME}`,
                }}
                spaceBetween={20}
                observeParents
                observer
                initialSlide={currentImage}
            >
                {property.photos?.map((picture, idx) => (
                    <SwiperSlide key={idx}>
                        <div className={"relative cursor-pointer h-[400px] sm:h-[calc(100vh-110px)]"} onClick={onClick}>
                            <img
                                className={"w-full h-[400px] sm:h-[calc(100vh-110px)] object-cover select-none"}
                                src={picture.url}
                                alt={"proprety_picture"}
                            />
                            {idx === 0 && (
                                <div className="absolute top-1/2 right-0 transform -translate-y-1/2 w-4/5 sm:w-3/5 md:w-1/3 z-10">
                                    <div className="p-4 bg-[#1A3A45] bg-opacity-50">
                                        <p className="text-white text-sm md:text-lg lg:text-2xl">
                                            {t("property.luxuryCollection")}
                                        </p>
                                        <p className="text-white text-xl md:text-2xl lg:text-4xl">
                                            {property.localized.title}
                                        </p>
                                        <p className="text-white text-sm md:text-lg lg:text-2xl">
                                            {t("property.luxuryCollectionLocation")} {property.address.city}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div
                                className={
                                    "absolute bottom-5 right-5 z-10 text-white cursor-pointer bg-[var(--color-primary)] rounded hidden md:block"
                                }
                            >
                                <img src={extandImage} alt={"extand-image"} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <PropertyNewBadge date={property.firstDayOnSite} className="top-16 z-10" />
            <div className={`swiper-button-prev ${NAME}`} />
            <div className={`swiper-button-next ${NAME}`} />

            <div className="absolute top-5 left-10 hidden md:block z-10">
                <PropertyBreadcrumb />
            </div>
            <div className="absolute top-5 right-2 z-50 flex gap-x-2">
                <ShareButton />
                <ViewFavoriteButton item={property} resource={MAIN_RESOURCE.PROPERTIES} />
            </div>
        </>
    );
}
