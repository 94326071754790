import { ReactNode } from "react";
import { action, observable } from "mobx";
import _ from "lodash";

export type TPanelOption = {
    children: ReactNode;
    closeable?: boolean;
    onClose?: () => void;
    withSpinner?: boolean;
    opened: boolean;
    stickyCross?: boolean;
    className?: string;
    withoutCross?: boolean;
};

class PanelStore {
    @observable option: TPanelOption | undefined;

    @action open(option: TPanelOption) {
        this.option = option;
    }

    setOption(option: TPanelOption) {
        this.option = option;
    }

    @action close() {
        if (this.option) {
            this.setOption({ ...this.option, opened: false });
        }
        _.debounce(() => (this.option = undefined), 200);
    }
}

const panelStore = new PanelStore();
export { panelStore };
