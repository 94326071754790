import React from "react";
import { SwitchButton } from "_common/ui/components/SwitchButton";
import { useInventoryContext } from "properties/propertyPage/inventory/InventoryContext";
import { CURRENCY_OPTIONS } from "_common/currency/CurrencyTypes";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { useTranslation } from "react-i18next";
import { PROPERTY_SUBTYPE } from "properties/_models/PropertyMdl";
import { useCurrency } from "_common/currency/CurrencyProvider";

export function InventoryToggleCurrency() {
    const { property } = usePropertyStore();
    const { currency, setCurrency } = useInventoryContext();
    const { currentCurrency } = useCurrency();
    const { t } = useTranslation();
    const isBuildableHouse = property.subType === PROPERTY_SUBTYPE.buildableHouse;

    let currentCurrencyToggle;

    switch (currentCurrency) {
        case "USD":
            currentCurrencyToggle = "US$";
            break;
        case "CAD":
            currentCurrencyToggle = "CA$";
            break;
        case "EUR":
            currentCurrencyToggle = "€";
            break;
        default:
            currentCurrencyToggle = "MX$";
            break;
    }

    return (
        <div className={"w-[calc(30vw-26px)] sm:w-auto"}>
            <SwitchButton
                className={"w-[90px] m-auto"}
                labelOn={isInPesosProperty(property) ? "MX$" : currentCurrencyToggle}
                labelOff={isInPesosProperty(property) ? currentCurrencyToggle : "MX$"}
                value={isInPesosProperty(property) ? currency === CURRENCY_OPTIONS.MXN : currency === currentCurrency}
                onChange={() => {
                    setCurrency(currency === CURRENCY_OPTIONS.MXN ? currentCurrency : CURRENCY_OPTIONS.MXN);
                }}
            />
            {isBuildableHouse && (
                <div className={"text-[10px] sm:text-xs mt-1 whitespace-nowrap"}>
                    {t("propertyPage.inventory.houseAndLotPrice")}
                </div>
            )}
        </div>
    );
}
