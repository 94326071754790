import React from "react";
import { ICommunityCard } from "api/communities/_models/CommunityModel";
import { CommunitiesSectionLogo } from "communities/components/CommunitiesSectionLogo";
import { Button } from "_common/ui/mui/buttons/Button";
import { useTranslation } from "react-i18next";
import house from "../../../assets/images/communities/house.svg";
import doubleHouse from "../../../assets/images/communities/doubleHouse.svg";
import surface from "../../../assets/images/communities/surface.svg";
import beachFront from "../../../assets/images/communities/beachFront.svg";
import gated from "../../../assets/images/communities/gated.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { URLS } from "_configs/URLS";
import { useHistory } from "react-router";

type Props = {
    community: ICommunityCard;
};

SwiperCore.use([Navigation]);

export function PropertyCollapsibleCommunityCard({ community }: Props) {
    const NAME = "propertiesCommunitiesImageSlider";
    const { t } = useTranslation();
    const history = useHistory();

    const LOGO_ARRAY = [
        {
            src: house,
            alt: "house",
            text: `${community.propertiesCount} ${t("communitiesPage.propertiesSale")}`,
        },
        ...(!community.unitsCount
            ? []
            : [
                  {
                      src: doubleHouse,
                      alt: "doubleHouse",
                      text: `${community.unitsCount} ${t("communitiesPage.unitsSale")}`,
                  },
              ]),
        {
            src: surface,
            alt: "surface",
            text: `${t("communitiesPage.totalSurface")} ${community.totalSurface}`,
        },
        ...(community.features && community.features.beachfront
            ? [
                  {
                      src: beachFront,
                      alt: "beachFront",
                      text: t("communitiesPage.beachFront"),
                  },
              ]
            : []),
        ...(community.features && community.features.gatedCommunity
            ? [
                  {
                      src: gated,
                      alt: "beachFront",
                      text: t("communitiesPage.gated"),
                  },
              ]
            : []),
    ];
    return (
        <div className={"flex flex-col gap-3"}>
            <h2 className={"mb-2 md:mb-0"}>
                {t("communitiesPage.gated")} {t("communitiesPage.community")} : {community.localized.title}
            </h2>
            <div id={NAME} className={"relative"}>
                <Swiper
                    loop
                    slidesPerView={1}
                    centeredSlides
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    spaceBetween={20}
                >
                    {community.photos?.map((picture, idx) => (
                        <SwiperSlide key={idx}>
                            <div className={"relative flex justify-center"}>
                                <img
                                    className={"rounded-[15px] w-full !max-h-[500px] object-cover"}
                                    src={picture.url}
                                    alt={"community_picture"}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className={`swiper-button-prev ${NAME}`} />
                <div className={`swiper-button-next ${NAME}`} />
            </div>
            <div>
                <p>{community.localized.description}</p>
            </div>
            <div
                className={
                    "grid grid-rows-3 w-full grid-col-1 p-5 gap-6 border border-[var(--color-border-card)] rounded-lg self-start md:mt-0 md:grid-flow-col md:flex-1 md:mt-4"
                }
            >
                {LOGO_ARRAY.map((section, index) => {
                    return <CommunitiesSectionLogo {...section} key={index} size={26} />;
                })}
            </div>

            <Button
                variant={"contained"}
                onClick={() => history.push(URLS.community(community.localized.urlAlias))}
                className={"!px-[24px] !py-[12px] w-[200px] !mt-4"}
            >
                {t("propertyPage.learnMore")}
            </Button>
        </div>
    );
}
