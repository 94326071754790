import React from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { userStore } from "users/_stores/userStore";
import { ContactFormBtn } from "components/misc/contactUs/ContactFormBtn";
import { AC_TAGS } from "users/_models/UserMdl";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";
import { cn } from "_configs/twconfig";
import { ContactFields } from "contact/ContactFields";
import { BLOG_TYPE } from "pages/_models/PageMdl";

export const PHONE = "1-877-515 (4752)";
export const WHATSAPP_PHONE = "+1-514-932-5688";

export type TContactForm = {
    name: string;
    email: string;
    phone: string;
    subject: string;
    text: string;
    company?: string;
    inquiry?: string;
    dayjs?: string;
    acTags?: AC_TAGS;
    blogType?: BLOG_TYPE;
    blogTitle?: string;
    lang?: string;
    url?: string;
    workingWithAnAgent?: "yes" | "no" | "I am an agent" | "";
    newsletter?: boolean;
};

type Props = {
    className?: string;
    title?: string;
    tag?: AC_TAGS;
    hideInquiries?: boolean;
};

export function ContactUsForm({ className, hideInquiries, title, tag }: Props) {
    const { t } = useTranslation();

    const form = useForm<TContactForm>({
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            text: "",
            subject: "",
            inquiry: "",
            workingWithAnAgent: "",
            newsletter: false,
        },
        mode: "onBlur",
    });

    return (
        <FormProvider {...form}>
            <div
                className={cn(
                    `p-4 md:p-5 flex-3 shadow-[0px_0px_5px_0px_#1A3A4540] rounded-md h-fit md:mt-0`,
                    className,
                )}
            >
                <div className={"flex flex-col flex-1"}>
                    <h2 className={" text-[var(--color-primary)] mb-4"}>{title ?? t("contactPage.form.title")}</h2>
                    <ContactFields hideInquiries={hideInquiries} />
                </div>
                <div className={"flex-1 flex flex-col"}>
                    <ContactFormBtn acTags={tag ?? AC_TAGS.L4_CONTACT_US} />
                </div>
            </div>
            <HiddenFieldsAttributerForm />
        </FormProvider>
    );
}
