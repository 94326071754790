import React from "react";
import bed from "../../../assets/images/icons/bed.svg";
import bath from "../../../assets/images/icons/bath.svg";
import { useBedroomsNumberLabel } from "properties/propertyPage/_hooks/useNumberOfBedrooms";

type Props = {
    min: number;
    max?: number;
    isBath?: boolean;
};

export function PropertyCardRooms({ min, max, isBath }: Props) {
    const bedroomLabel = useBedroomsNumberLabel(min, max, isBath);
    if (min < 0) return null;
    return (
        <div className="flex gap-1 items-center whitespace-nowrap">
            <div className="flex items-center justify-center">{<img src={isBath ? bath : bed} alt={"bed icon"} />}</div>
            <>{bedroomLabel}</>
        </div>
    );
}
