import React from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import i18next from "i18next";

type Props = {
    unit: TUnitListingMdl;
};

export function InventoryViewsCell({ unit }: Props) {
    if (!unit.views) return null;
    const viewsToDisplay: string[] = [];
    Object.keys(unit.views).forEach((key) => {
        if (key in unit.views!) {
            viewsToDisplay.push(getI18nExpByLang(i18next.language, `unit.views.${key}`));
        }
    });
    return (
        <>
            {viewsToDisplay.length === 0 && <>-</>}
            {viewsToDisplay.map((view) => {
                return (
                    <div key={view} className={"truncate"}>
                        {view}
                    </div>
                );
            })}
        </>
    );
}
