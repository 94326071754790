import React from "react";

type Props = {
    size?: number;
    color?: string;
};

export function IconPropertyUnits({ color, size }: Props) {
    return (
        <svg
            width={size ? (size * 36) / 26 : 36}
            height={size ?? "26"}
            viewBox="0 0 36 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4514_18004)">
                <path
                    d="M15.0622 22.9105C15.3927 22.9105 15.6618 22.6516 15.6618 22.3336V17.7019C15.6618 17.3839 15.3927 17.125 15.0622 17.125H9.79492C9.4644 17.125 9.19531 17.3839 9.19531 17.7019V22.3336C9.19531 22.6516 9.4644 22.9105 9.79492 22.9105C10.1255 22.9105 10.3945 22.6516 10.3945 22.3336V18.2768H14.4647V22.3336C14.4647 22.6516 14.7338 22.9105 15.0643 22.9105H15.0622Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M28.7525 22.9109C29.083 22.9109 29.3521 22.652 29.3521 22.334V18.7644C29.3521 18.4464 29.083 18.1875 28.7525 18.1875H24.873C24.5425 18.1875 24.2734 18.4464 24.2734 18.7644V22.334C24.2734 22.652 24.5425 22.9109 24.873 22.9109C25.2036 22.9109 25.4727 22.652 25.4727 22.334V19.3413H28.1529V22.334C28.1529 22.652 28.422 22.9109 28.7525 22.9109Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M16.7763 10.8582C16.7763 10.5401 16.5072 10.2812 16.1767 10.2812H7.64649C7.31596 10.2812 7.04688 10.5401 7.04688 10.8582C7.04688 11.1762 7.31596 11.4351 7.64649 11.4351H16.1767C16.5072 11.4351 16.7763 11.1762 16.7763 10.8582Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M34.0036 15.7161C33.6731 15.7161 33.404 15.975 33.404 16.293V24.8448H20.2316V16.293C20.2316 15.975 19.9625 15.7161 19.632 15.7161C19.3015 15.7161 19.0324 15.975 19.0324 16.293V24.8448H3.12891V12.116C3.12891 11.798 2.85983 11.5391 2.5293 11.5391C2.19877 11.5391 1.92969 11.798 1.92969 12.116V25.4197C1.92969 25.7377 2.19877 25.9966 2.5293 25.9966H34.0015C34.332 25.9966 34.6011 25.7377 34.6011 25.4197V16.291C34.6011 15.973 34.332 15.7141 34.0015 15.7141L34.0036 15.7161Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M35.7578 12.7034L27.1705 6.59174C26.9586 6.44088 26.6704 6.44088 26.4586 6.59174L24.1724 8.21852L12.7819 0.113141C12.57 -0.0377137 12.2819 -0.0377137 12.07 0.113141L0.243024 8.53042C-0.0239405 8.72001 -0.0790285 9.08084 0.118017 9.3377C0.234549 9.49059 0.416763 9.57213 0.601096 9.57213C0.723985 9.57213 0.848992 9.53544 0.957049 9.46001L12.4238 1.29552L23.1639 8.93814L17.8712 12.7054C17.6042 12.895 17.5491 13.2558 17.7462 13.5127C17.9432 13.7675 18.3182 13.8226 18.5852 13.633L26.8145 7.77615L35.0438 13.633C35.1519 13.7084 35.2747 13.7451 35.3998 13.7451C35.5841 13.7451 35.7642 13.6636 35.8828 13.5107C36.0799 13.2538 36.0227 12.893 35.7578 12.7034Z"
                    fill={color ?? "#1A3A45"}
                />
            </g>
            <defs>
                <clipPath id="clip0_4514_18004">
                    <rect width="36" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
