import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { observer } from "mobx-react-lite";
import { galleryStore } from "gallery/_store/GalleryStore";
import PropertyContactForm from "properties/propertyPage/PropertyContactForm";

export const ContactFormDialog = observer(() => (
    <Dialog open={galleryStore.contactFormIsOpen} onClose={() => galleryStore.closeContactForm()}>
        <PropertyContactForm options={galleryStore.optionsForm} />
    </Dialog>
));
