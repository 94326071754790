import React, { lazy } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import Suspenser from "_common/loaders/Suspenser";
import { PropertyGalleyConstructProgress } from "properties/gallery/PropertyGalleyConstructProgress";
import { PropertyOrUnitGalleryAndDocuments } from "properties/propertyPage/PropertyOrUnitGalleryAndDocuments";
import { VideosGalleryProperty } from "gallery/videosGalleryProperty";
import { useTranslation } from "react-i18next";
import { GALLERY_TABS } from "_configs/propertyConfig";
import { galleryStore } from "gallery/_store/GalleryStore";

const LazyPropertyOrUnitFloorPlans = lazy(() => import("properties/propertyPage/PropertyOrUnitFloorPlans"));

export function PropertyDevelopment() {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const { property } = propertyStore;
    const isPreviousProjects =
        (!!property?.previousProjects && property?.previousProjects.length > 0) ||
        (!!property?.previousProjectsVideos && property?.previousProjectsVideos.length > 0);

    return (
        <div className={"flex flex-col gap-8"}>
            {!!property?.floorPlans?.length && property.floorPlans.length > 0 && (
                <div className="relative">
                    <div
                        className={"absolute z-50 w-full h-full"}
                        onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.LEVEL_PLAN)}
                    ></div>
                    <Suspenser>
                        <LazyPropertyOrUnitFloorPlans
                            floorPlans={property.floorPlans}
                            resource={RESOURCE.PROPERTIES}
                            onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.LEVEL_PLAN)}
                        />
                    </Suspenser>
                </div>
            )}
            {!!property.constructProgress && property.constructProgress.length > 0 && (
                <div>
                    <h2 className={"text-base font-normal text-[var(--color-primary)] pb-5"}>
                        {t("unitPage.gallery.tabs.CONSTRUCT_PROGRESS")}
                    </h2>
                    <PropertyGalleyConstructProgress />
                </div>
            )}

            {!!property?.finishings && property?.finishings.length > 0 && (
                <div className="relative">
                    <div
                        className={"absolute z-50 w-full h-full"}
                        onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.FINISHINGS)}
                    ></div>
                    <h2 className={"text-base font-normal text-[var(--color-primary)] pb-5"}>
                        {t("propertyPage.gallery.tabs.FINISHINGS")}
                    </h2>
                    <PropertyOrUnitGalleryAndDocuments
                        images={property.finishings}
                        onCLick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.FINISHINGS)}
                    />
                </div>
            )}

            {isPreviousProjects && (
                <div>
                    <h2 className={"text-base font-normal text-[var(--color-primary)] pb-5"}>
                        {t("propertyPage.gallery.tabs.PREVIOUS_PROJECTS")}
                    </h2>
                    <PropertyOrUnitGalleryAndDocuments
                        images={property.previousProjects}
                        onCLick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.PREVIOUS_PROJECTS)}
                    />
                    <VideosGalleryProperty />
                </div>
            )}
        </div>
    );
}
