import React from "react";
import PhoneInput from "react-phone-input-2";
import "./_css/inputPhone.css";
import { cn } from "_configs/twconfig";

type Props = {
    value?: string;
    onChange?: (value: string) => void;
    inputClass?: string;
    buttonClass?: string;
    regions?: string[];
    preferredCountries?: string[];
    country?: string;
    placeholder?: string;
    className?: string;
    hidden?: boolean;
};

export const InputPhone = React.forwardRef<HTMLInputElement, Props>(({ className, ...props }: Props, ref) => {
    return (
        <div ref={ref} hidden={props.hidden} className={"relative w-full"}>
            <PhoneInput
                placeholder={props.placeholder ?? "Phone"}
                value={props.value}
                onChange={props.onChange}
                inputClass={cn(className, "border border-solid border-gray-300 rounded-md p-2 w-full")}
                containerClass={cn(className, "relative w-full")}
                buttonClass={cn(className, "!bg-white")}
                regions={props.regions ?? ["america", "europe"]}
                preferredCountries={props.preferredCountries ?? ["mx", "ca", "us"]}
                country={props.country ?? "ca"}
            />
        </div>
    );
});
