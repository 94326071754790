import React from "react";
import { URLS } from "_configs/URLS";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { reformatStringForUrls, reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";
import { useCommunityStore } from "communities/CommunityContext";
import { GEO_ZONE_TYPE } from "geoZones/_models/GeoZoneMdl";

export function CommunitiesBreadcrumb() {
    const { t } = useTranslation();
    const { community } = useCommunityStore();
    const { geoZone } = community;
    if (!community.geoZone) return null;
    const isCity = geoZone.type === GEO_ZONE_TYPE.CITY;
    const isNeighbourhood = geoZone.type === GEO_ZONE_TYPE.NEIGHBORHOOD;

    const items = [
        {
            itemLabel: t("propertyPage.research"),
            url: URLS.buy(),
            forceReload: true,
        },
    ];

    items.push({
        itemLabel: geoZone.address.provinceLong ?? reformatUrlForOriginalName(geoZone.address.province),
        url: URLS.buy(
            undefined,
            reformatStringForUrls(geoZone.address.provinceLong ?? reformatStringForUrls(geoZone.address.province)),
        ),
        forceReload: true,
    });
    if (isCity || isNeighbourhood) {
        items.push({
            itemLabel: geoZone.address.city,
            url: URLS.buy(
                undefined,
                reformatStringForUrls(geoZone.address.province),
                reformatStringForUrls(geoZone.address.city),
            ),
            forceReload: true,
        });
    }

    if (isNeighbourhood) {
        items.push({
            itemLabel: geoZone.address.neighbourhood,
            url: URLS.buy(
                undefined,
                reformatStringForUrls(geoZone.address.province),
                reformatStringForUrls(geoZone.address.city),
                reformatStringForUrls(geoZone.address.neighbourhood),
            ),
            forceReload: true,
        });
    }

    return (
        <Breadcrumb
            items={items}
            currentItem={{
                itemLabel: community.localized.title,
            }}
            noHome
            noMarginBottom
        />
    );
}
