import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { PropertyViewGallerySwiper } from "properties/propertyPage/propertyView/PropertyViewGallerySwiper";
import { galleryStore, TGalleryImage } from "gallery/_store/GalleryStore";
import UnitViewGalleryNavbar from "units/UnitViewGalleryNavbar";
import { Dialog } from "@material-ui/core";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { observer } from "mobx-react-lite";
import { GALLERY_NAME_MODAL } from "_configs/propertyConfig";

interface Props {
    galleryImages: TGalleryImage[];
    item: TUnitListingMdl;
}

SwiperCore.use([Navigation]);
export const UnitGalleryModal = observer(({ galleryImages, item }: Props) => {
    return (
        <Dialog
            open={galleryStore.openedGalleryModal > -1 && galleryStore.modalName === GALLERY_NAME_MODAL.UNIT}
            onClose={() => galleryStore.setOpenedGalleryModal(-1)}
            fullScreen
        >
            <div className="flex flex-col h-full relative select-none">
                <UnitViewGalleryNavbar
                    handleClose={() => galleryStore.setOpenedGalleryModal(-1)}
                    galleryImages={galleryImages}
                    item={item}
                />
                <div className={"sm:h-[75px] h-[140px]"} />
                <div className="relative h-full py-1">
                    {galleryImages.map((galleryImage) => {
                        if (galleryStore.openedGalleryListTab !== galleryImage.tabName) return null;
                        return <PropertyViewGallerySwiper key={galleryImage.tabName} images={galleryImage.images} />;
                    })}
                </div>
            </div>
        </Dialog>
    );
});
