import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { IUnitModel } from "api/units/_models/UnitModel";
import { TFloorMdl } from "admin/floors/_models/floorsMdl";

export function getPropertyStatusFromUnits(units: Pick<TUnitListingMdl | IUnitModel, "status">[]): INVENTORY_STATUS {
    let status = INVENTORY_STATUS.SOLD;
    if (units && units.length) {
        const isAtLeastOneAvailableUnit = units.some(
            (unit) => unit.status && unit.status === INVENTORY_STATUS.AVAILABLE,
        );
        const isAtLeastOneReservedUnit = units.some((unit) => unit.status && unit.status === INVENTORY_STATUS.RESERVED);
        status = isAtLeastOneAvailableUnit
            ? INVENTORY_STATUS.AVAILABLE
            : isAtLeastOneReservedUnit
            ? INVENTORY_STATUS.RESERVED
            : INVENTORY_STATUS.SOLD;
    }
    return status;
}

export function getNumberOfFloorFromName(floor: TFloorMdl): number {
    const reformatName = floor?.name?.replace(/\b(floor|Floor|Lot|lot)\s*(\d+)\b/, "$2");
    if (!isNaN(Number(reformatName))) {
        return Number(reformatName);
    }
    if (reformatName === "Ground") {
        return 0;
    }
    return floor?.positionInBuilding;
}
