import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TFilter } from "admin/_common/filters/TFilter";
import i18next from "i18next";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH } from "properties/_models/PropertyMdl";

export class UnitsStore extends BaseResourceStore<TUnitListingMdl> {
    abortControllers = {
        list: {
            ctrl: new AbortController(),
            state: new LoadingStateMdl(),
        },
    };
    constructor() {
        super("units");
    }

    static getDynamicsFiltersFromFilters(filters: TFilter[]) {
        return filters.filter(
            (filter) =>
                !Object.values(FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH).includes(
                    filter.id as FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH,
                ),
        );
    }

    list(
        offset = 0,
        limit?: number,
        _listId?: string,
        sort?: { [key: string]: number },
        filters?: TFilter[],
        countForStats = true,
    ) {
        if (this.abortControllers.list.state.isLoading) {
            this.abortControllers.list.ctrl.abort();
        }
        this.abortControllers.list.ctrl = new AbortController();
        this.abortControllers.list.state.startLoading();
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        if (!filters) filters = [];
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/listing?offset=${offset}&limit=${limit}${sortParam}${filtersParam}&lang=${
            i18next.language
        }&browser=${countForStats && __BROWSER__}`;
        const promise = fetchUtils
            .get<{ count: number; items: TUnitListingMdl[] }>(url, {
                signal: this.abortControllers.list.ctrl.signal,
            })
            .then(
                ({ data: { count, items } }) => {
                    this.abortControllers.list.state.setSuccess();
                    return {
                        count,
                        items: items.map((item) => {
                            const reformattedItem = this.reformatItem(item);
                            this.putItemInCache(reformattedItem);
                            return reformattedItem;
                        }),
                    };
                },
                (e) => this.abortControllers.list.state.setError(e),
            );
        return promise;
    }
}

const unitsStore = new UnitsStore();
export { unitsStore };
