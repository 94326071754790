import { PROPERTY_TYPE, TAddressMdl, TPropertyListingMdl, TPropertyPage } from "properties/_models/PropertyMdl";
import _ from "lodash";
import { GALLERY_TABS, PROPERTY_OPTION } from "_configs/propertyConfig";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls, removeAccentFromString } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";
import { convertPrices, formatPrice } from "_common/_utils/currencyUtils";
import { CURRENCY_OPTIONS, PRICE_CONVERSION } from "_common/currency/CurrencyTypes";

export function getParentGeoZoneByProperty(property: { address: TAddressMdl }, lang?: string) {
    return URLS.buy(
        lang,
        property.address?.provinceShort
            ? reformatStringForUrls(property.address?.provinceShort)
            : i18next
                  .t("provinces.short." + removeAccentFromString(property.address.province).replace(" ", ""))
                  .toLowerCase(),
        reformatStringForUrls(property.address.city),
        property.address.neighbourhood ? reformatStringForUrls(property.address.neighbourhood) : undefined,
    );
}

export function flattenObject(
    baseObject?: any,
    path = "",
    output: {
        [key: string]: string | number | undefined | null | boolean;
    } = {},
) {
    if (
        baseObject === undefined ||
        baseObject === null ||
        typeof baseObject !== "object" ||
        _.isArray(baseObject) ||
        _.isDate(baseObject)
    ) {
        output[path] = baseObject;
    } else {
        for (const key in baseObject) {
            flattenObject(baseObject[key], path !== "" ? path + "." + key : key, output);
        }
    }
    return output;
}

export function reformatImagesForGallery(item: TPropertyPage) {
    if (item.photos?.length > 3 && item.photos[0].url !== item.photos[2].url) {
        const title = item?.video ? GALLERY_TABS.VIDEO : GALLERY_TABS.GALLERY;
        item.photos.splice(2, 0, { ...item.photos[0], title: title });
        if (item?.video || item.floorPlans.length > 0) {
            const title = item?.video ? GALLERY_TABS.GALLERY : GALLERY_TABS.LEVEL_PLAN;
            item.photos.splice(4, 0, { ...item.photos[0], title: title });
        }
        if (item?.video && item.floorPlans.length > 0) {
            const title = GALLERY_TABS.LEVEL_PLAN;
            item.photos.splice(6, 0, { ...item.photos[0], title: title });
        }
    }
    return item;
}

export function isLandOrCommercial(propertyType?: PROPERTY_TYPE) {
    if (!propertyType) return false;
    return propertyType === PROPERTY_TYPE.land || propertyType === PROPERTY_TYPE.commercial;
}

export function isInPesosProperty(property: Pick<TPropertyListingMdl, "features">): boolean {
    return !!property?.features?.[PROPERTY_OPTION.closingInPesos];
}

export function isLuxury(property: Pick<TPropertyListingMdl, "features">): boolean {
    return !!property?.features?.[PROPERTY_OPTION.luxury];
}

export function calculateAndFormatPropertyPrice(
    property: {
        priceMin: number;
        priceInPesosMin?: number;
        priceIsInPesos?: boolean;
    },
    currencyDisplay: CURRENCY_OPTIONS,
) {
    const { priceMin, priceInPesosMin, priceIsInPesos } = property;
    const minPrice = priceIsInPesos ? priceInPesosMin || 0 : priceMin || 0;
    switch (currencyDisplay) {
        case CURRENCY_OPTIONS.MXN:
            return priceIsInPesos
                ? formatPrice(minPrice, false, false, CURRENCY_OPTIONS.MXN)
                : formatPrice(
                      convertPrices(minPrice, PRICE_CONVERSION.USD_TO_PESOS),
                      false,
                      false,
                      CURRENCY_OPTIONS.MXN,
                  );
        case CURRENCY_OPTIONS.USD:
            // return formatPrice(minPrice);
            return priceIsInPesos
                ? formatPrice(convertPrices(minPrice, PRICE_CONVERSION.PESOS_TO_USD))
                : formatPrice(minPrice);
        case CURRENCY_OPTIONS.CAD:
            return formatPrice(
                convertPrices(minPrice, priceIsInPesos ? PRICE_CONVERSION.PESOS_TO_CAD : PRICE_CONVERSION.USD_TO_CAD),
                false,
                false,
                CURRENCY_OPTIONS.CAD,
            );
        case CURRENCY_OPTIONS.EUR:
            return formatPrice(
                convertPrices(minPrice, priceIsInPesos ? PRICE_CONVERSION.PESOS_TO_EUR : PRICE_CONVERSION.USD_TO_EUR),
                false,
                false,
                CURRENCY_OPTIONS.EUR,
            );
        default:
            return "";
    }
}
