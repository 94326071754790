import React from "react";
import { useTranslation } from "react-i18next";
import arrow from "../assets/images/rightArrowTiny.svg";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";

type Props = {
    unitType: TUnitTypeListingMdl;
};

export function UnitTypeCardHeader({ unitType }: Props) {
    const { t } = useTranslation();
    return (
        <div className={"flex flex-row justify-between"}>
            <div className={"text-sm"}>{t("property.unitsAvailable", { count: unitType.nbOfAvailableUnits })}</div>
            <div className={"flex flex-row items-center"}>
                {t("words.viewAll")}
                <img src={arrow} alt={"arrow"} className={"ml_5"} />
            </div>
        </div>
    );
}
