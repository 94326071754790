import React from "react";
import { Meta } from "_common/_utils/Meta";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";
import { URLS_ALTERNATES } from "_configs/URLS_ALTERNATES";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

export function PropertyMeta() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const metaTitle = t("propertyPage.meta.title", {
        title: property.localized.title,
        purpose: t(`propertyPage.meta.purpose.BUY`),
        city: property.address.city,
        province: property.address.province,
        type: t(`property.types.${property.type}`),
    });

    const description = t("propertyPage.meta.description", {
        title: property.localized.title,
        purpose: t(`propertyPage.meta.purpose.BUY`),
        address: `${property.address.city} ${property.address.neighbourhood ?? ""} ${property.address.postalCode}
                ${property.address.province}`,
        price: formatPrice(property.price.min, false),
        propertyType: t(`property.types.${property.type}`),
        metaDescription:
            property.localized.meta?.title && property.localized.meta.title !== ""
                ? property.localized.meta?.description
                : t("meta.description"),
    });

    const title =
        property.localized.meta?.title && property.localized.meta.title !== ""
            ? property.localized.meta.title
            : metaTitle;
    const image = property.localized.meta?.image ?? property?.photos?.[0]?.url;
    const url = URLS.property(property.localized.urlAlias);
    const alternateUrls = [];

    for (const lang of Object.keys(sharedConfig.languages)) {
        alternateUrls.push({
            url: URLS_ALTERNATES.property(property?.urlAlias?.[lang] ?? property.localized.urlAlias, lang),
            lang: lang as TLang,
        });
    }

    return <Meta title={title} description={description} image={image} url={url} alternateUrls={alternateUrls} />;
}
