import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import extandImage from "../../../assets/images/communities/extandImage.svg";
import GalleryPagination from "properties/propertyPage/propertyView/GalleryPagination";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import ShareButton from "properties/propertyPage/propertyView/ShareButton";
import ViewFavoriteButton from "properties/propertyPage/propertyView/ViewFavoriteButton";
import { PropertyNewBadge } from "properties/propertyCard/components/PropertyNewBadge";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { galleryStore } from "gallery/_store/GalleryStore";
import { observer } from "mobx-react-lite";
import { GALLERY_TABS } from "_configs/propertyConfig";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NAME = "propertyViewImageSlider";

SwiperCore.use([Navigation]);

export const PropertyViewSlider = observer(() => {
    const { property } = usePropertyStore();
    const [currentImage, setCurrentImage] = useState(0);
    return (
        <div id={NAME} className={"relative w-full max-h-[230px] md:max-h-[600px]"}>
            <Swiper
                onSlideChange={({ realIndex }) => setCurrentImage(realIndex)}
                loop
                slidesPerView={1}
                centeredSlides
                navigation={{
                    prevEl: `.swiper-button-prev.${NAME}`,
                    nextEl: `.swiper-button-next.${NAME}`,
                }}
                spaceBetween={20}
                observeParents
                observer
            >
                {property.photos?.map((picture, idx) => (
                    <SwiperSlide key={idx}>
                        <div
                            className={"relative cursor-pointer aspect-video"}
                            onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.GALLERY)}
                        >
                            <LazyLoadImage
                                className={"h-[230px] w-full md:h-[600px] object-cover rounded-2xl select-none"}
                                src={picture.url}
                                alt={"proprety_picture"}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    // currentTarget.src = new URL(`https://zisla.com/${picture.url}`).href;
                                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                                }}
                            />
                            <PropertyNewBadge date={property.firstDayOnSite} className="top-12" />

                            <div
                                className={
                                    "absolute bottom-5 right-5 z-10 text-white cursor-pointer bg-[var(--color-primary)] rounded hidden md:block"
                                }
                            >
                                <img src={extandImage} alt={"extand-image"} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={`swiper-button-prev ${NAME}`} />
            <div className={`swiper-button-next ${NAME}`} />

            <div className="absolute top-2 right-2 z-50 flex gap-x-2">
                <ShareButton />
                <ViewFavoriteButton item={property} resource={MAIN_RESOURCE.PROPERTIES} />
            </div>

            <div className="hidden md:block">
                <GalleryPagination
                    currentImage={currentImage}
                    totalImages={property.photos.length}
                    prevClass={`swiper-modal-button-prev ${NAME}`}
                    nextClass={`swiper-modal-button-next ${NAME}`}
                    className="grid items-center absolute left-[7px] opacity-70 bottom-[8px] w-16 h-5 md:w-20 md:h-6 rounded-[5px] bg-[#1A3E4D] z-50 text-center"
                    noArrow
                />
            </div>
        </div>
    );
});
