import React from "react";
import { unitTypesStore } from "unitTypes/_store/unitTypesStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertyUnitTypeList } from "properties/propertyPage/PropertyUnitTypeList";
import { unitsStore } from "units/_stores/unitsStore";
import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";
import { combineLoadingStates } from "_common/loaders/loadingStateUtils";

export function PropertyUnitTypeListLoader() {
    const { property } = usePropertyStore();
    const unitTypeListStore = unitTypesStore.getListStore(property._id, Number.MAX_SAFE_INTEGER, [
        { id: "property", type: TFilterType.ID, value: property._id },
    ]);
    const unitListStore = unitsStore.getListStore(
        property._id,
        Number.MAX_SAFE_INTEGER,
        [
            { id: "propertyId", type: TFilterType.ID, value: property._id },
            { id: "status", type: TFilterType.ENUM, value: INVENTORY_STATUS.AVAILABLE },
        ],
        undefined,
        undefined,
        { floorPosition: 1 },
    );
    return (
        <LoadableFromLoading
            loading={combineLoadingStates([unitTypeListStore.currentLoadingState, unitListStore.currentLoadingState])}
            renderer={() => {
                return <PropertyUnitTypeList />;
            }}
        />
    );
}
