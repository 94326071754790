import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PhaseEarliestDisplay } from "properties/propertyPage/PhaseEarliestDisplay";

export function PropertyViewInfo() {
    const { property } = usePropertyStore();

    return (
        <div className={"flex flex-col sm:flex-row items-baseline gap-2"}>
            <div className={"text-xl mr-2"}>{property.localized.title}</div>
            {!!property.inventory?.phases?.length && (
                <PhaseEarliestDisplay status={property.status} phases={property.inventory.phases} />
            )}
        </div>
    );
}
