import { action, autorun, computed, observable } from "mobx";
import { tokenStore } from "users/_stores/tokenStore";
import { AC_TAGS, IFavoritesSearchMdl, IUserMdl, USER_ROLES } from "users/_models/UserMdl";
import { authStore } from "users/auth/_stores/authStore";
import { fetchUtils, TFilesData } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { TFilter } from "admin/_common/filters/TFilter";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import dayjs from "dayjs";
import { TPropertyCard } from "properties/_models/PropertyMdl";
import { BLOG_TYPE } from "pages/_models/PageMdl";
import i18next from "i18next";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";
import { ItemMapper } from "maps/ItemMapper";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";

export enum FAVORITES_TYPE {
    PROPERTIES = "properties",
    UNITS = "units",
    SEARCHES = "searches",
    LANDS = "lands",
    COMMERCIAL_SPACES = "commercialSpaces",
    HOTELS = "hotels",
    UNIT_TYPES = "unitTypes",
}

export type TAcOptions = {
    blogType?: BLOG_TYPE;
    blogName?: string;
    title?: string;
};

class UserStore {
    @observable user: IUserMdl | undefined = undefined;
    @observable favoritesStates: {
        [key: string]: LoadingStateMdl<(TPropertyCard | TUnitListingMdl | TUnitTypeListingMdl)[]>;
    } = {};
    @observable favorites: { [key: string]: (TPropertyCard | TUnitListingMdl | TUnitTypeListingMdl)[] } = {};
    @observable allFavoritesState = new LoadingStateMdl<(TPropertyCard | TUnitListingMdl | TUnitTypeListingMdl)[]>();
    @observable allFavorites: (TPropertyCard | TUnitListingMdl | TUnitTypeListingMdl)[] = [];
    @observable favoritesSearchesState = new LoadingStateMdl<IFavoritesSearchMdl[]>();
    @observable favoritesSearches: IFavoritesSearchMdl[] = [];
    @observable favoritesPropertiesStates = new LoadingStateMdl<TPropertyCard[]>();
    @observable favoritesProperties: TPropertyCard[] = [];
    @observable favoritesUnitsStates = new LoadingStateMdl<TUnitListingMdl[]>();
    @observable favoritesUnitTypesStates = new LoadingStateMdl<TUnitTypeListingMdl[]>();
    @observable favoritesUnits: TUnitListingMdl[] = [];
    @observable favoritesUnitTypes: TUnitTypeListingMdl[] = [];
    @observable isSignInModalDisplayed = false;
    @observable isContactModalDisplayed = false;
    @observable acTagsForContactModal: AC_TAGS | undefined = undefined;
    @observable developersState = new LoadingStateMdl<IUserMdl[]>();
    @observable allUsers: IUserMdl[] = [];
    @observable acOptions: TAcOptions | undefined;
    @observable userSelected: { id: string; name: string; email: string } | undefined;
    @observable mapFavoritesSelected: string | undefined = undefined;

    private readonly _setDataFromToken = action((_token: string | undefined) => {
        const tokenData = tokenStore.getTokenData<IUserMdl>();
        if (!tokenData) {
            if (this.isLogged) authStore.signOut();
        } else {
            this.user = tokenData;
            analyticsUtils.setUserInfo(this.user.email);
        }
    });

    constructor() {
        autorun(() => {
            const token = tokenStore.token;
            this._setDataFromToken(token);
        });
        Object.values(JSON.parse(JSON.stringify(FAVORITES_TYPE))).forEach((favoriteType) => {
            this.favoritesStates[favoriteType as string] = new LoadingStateMdl();
            this.favorites[favoriteType as string] = [];
        });
    }

    @computed get isLogged() {
        return this.user !== undefined;
    }

    @computed get isAdmin() {
        return this.user?.roles.includes(USER_ROLES.ADMIN);
    }

    @computed get isDeveloper() {
        return this.isAdmin || this.user?.roles.includes(USER_ROLES.DEVELOPER);
    }

    @computed get isMasterOrDeveloper() {
        return (
            this.isAdmin ||
            this.user?.roles.includes(USER_ROLES.DEVELOPER) ||
            this.user?.roles.includes(USER_ROLES.MASTER)
        );
    }

    @computed get allFavoritesSortedByDate() {
        return this.allFavorites.sort((a, b) => {
            const dateA = dayjs(a.createdAt).toDate().getTime();
            const dateB = dayjs(b.createdAt).toDate().getTime();
            return dateB - dateA;
        });
    }

    @computed get favoritePropertiesMarkers() {
        return this.allFavoritesSortedByDate
            .filter((item) => "developer" in item)
            .map((item) => ItemMapper.toMarker(item, "property"))
            .filter((i) => !!i);
    }

    @computed get favoriteUnitsMarkers() {
        return this.allFavoritesSortedByDate
            .filter((item) => "propertyId" in item)
            .map((item) => ItemMapper.toMarker(item, "unit"))
            .filter((i) => !!i);
    }

    @computed get favoritesMarkers() {
        return [...this.favoriteUnitsMarkers, ...this.favoritePropertiesMarkers];
    }

    async deleteAccount() {
        await fetchUtils.delete(sharedConfig.apiUrl + "/users/" + this.user?._id);
        authStore.signOut();
    }

    fetchAllUsers(role?: USER_ROLES) {
        if (!this.developersState.isLoading) {
            this.developersState.startLoading();
            const promise = fetchUtils.get<{ count: number; items: IUserMdl[] }>(sharedConfig.apiUrl + "/users");
            promise
                .then(
                    action(({ data }) => {
                        this.allUsers = data.items.filter((item) => (role ? item.roles.includes(role) : !!item));
                        this.developersState.setSuccess(data.items);
                    }),
                )
                .catch(() => this.developersState.setError);
        }
    }

    fetchAllFavorites(userId = this.userSelected?.id) {
        if (!this.allFavoritesState.isLoading) {
            this.allFavoritesState.startLoading();
            fetchUtils
                .get<(TPropertyCard | TUnitListingMdl)[]>(
                    sharedConfig.apiUrl +
                        "/users/allFavoritesWithoutTypeDistinction/" +
                        `${i18next.language}` +
                        (this.userSelected?.id ? `/${this.userSelected?.id}` : userId ? `/${userId}` : ""),
                )
                .then(
                    action(({ data }) => {
                        this.allFavorites = data;

                        this.allFavoritesState.setSuccess(data);
                    }),
                )
                .catch(() => this.allFavoritesState.setError);
        }
    }

    fetchFavoritesSearches(userId?: string) {
        if (!this.favoritesSearchesState.isLoading) {
            this.favoritesSearchesState.startLoading();
            const promise = fetchUtils.get<IFavoritesSearchMdl[]>(
                sharedConfig.apiUrl +
                    "/users/allFavorites/" +
                    FAVORITES_TYPE.SEARCHES +
                    `/${i18next.language}` +
                    (this.userSelected?.id ? `/${this.userSelected?.id}` : userId ? `/${userId}` : ""),
            );
            promise
                .then(
                    action(({ data }) => {
                        this.favoritesSearches = data;
                        this.favoritesSearchesState.setSuccess(data);
                    }),
                )
                .catch(() => this.favoritesSearchesState.setError);
        }
        return this.favoritesSearchesState;
    }

    @action
    setMapFavoriteSelected(itemId: string | undefined) {
        this.mapFavoritesSelected = itemId;
    }

    @action openSignInWithModal() {
        this.isSignInModalDisplayed = true;
    }

    @action closeSignInWithModal() {
        this.isSignInModalDisplayed = false;
    }

    @action openContactModalDisplayed(tags?: AC_TAGS, acOptions?: TAcOptions) {
        this.isContactModalDisplayed = true;
        this.acTagsForContactModal = tags;
        this.acOptions = acOptions;
    }

    @action closeContactModalDisplayed() {
        this.isContactModalDisplayed = false;
    }

    @action togglePropertyToFavorites(property: TPropertyCard, isAllFavorites = false) {
        return fetchUtils
            .patch(sharedConfig.apiUrl + "/users/favorites/property/" + property._id, { userId: this.userSelected?.id })
            .then(
                action(() => {
                    const favoriteToDelete = (isAllFavorites ? this.allFavorites : this.favoritesUnits).find(
                        (favoriteProperty) => favoriteProperty._id === property._id,
                    ) as TPropertyCard;

                    if (favoriteToDelete) {
                        this.favoritesProperties.splice(this.favoritesProperties.indexOf(favoriteToDelete), 1);
                        const index = this.allFavorites.findIndex((item) => item._id === favoriteToDelete._id);
                        this.allFavorites.splice(index, 1);
                    } else {
                        this.favoritesProperties.push(property);
                    }
                }),
            );
    }

    @action toggleUnitsToFavorites(unit: TUnitListingMdl, isAllFavorites = false) {
        return fetchUtils
            .patch(sharedConfig.apiUrl + "/users/favorites/unit/" + unit._id, { userId: this.userSelected?.id })
            .then(
                action(() => {
                    const favoriteToDelete = (isAllFavorites ? this.allFavorites : this.favoritesUnits).find(
                        (favoriteUnit) => favoriteUnit._id === unit._id,
                    ) as TUnitListingMdl;

                    if (favoriteToDelete) {
                        this.favoritesUnits.splice(this.favoritesUnits.indexOf(favoriteToDelete), 1);
                        const index = this.allFavorites.findIndex((item) => item._id === favoriteToDelete._id);
                        this.allFavorites.splice(index, 1);
                    } else {
                        this.favoritesUnits.push(unit);
                    }
                }),
            );
    }

    @action removeFavorite(id: string, resource: RESOURCE) {
        const url = resource === RESOURCE.PROPERTIES ? "/users/favorites/property/" : "/users/favorites/unit/";
        return fetchUtils.patch(sharedConfig.apiUrl + url + id, { userId: this.userSelected?.id }).then(
            action(() => {
                const favoriteToDelete = this.allFavorites.find(
                    (favoriteUnit) => favoriteUnit._id === id,
                ) as TUnitListingMdl;

                if (favoriteToDelete) {
                    this.favoritesUnits.splice(this.favoritesUnits.indexOf(favoriteToDelete), 1);
                    const index = this.allFavorites.findIndex((item) => item._id === favoriteToDelete._id);
                    this.allFavorites.splice(index, 1);
                }
            }),
        );
    }

    @action toggleUnitTypesToFavorites(unit: TUnitTypeListingMdl, isAllFavorites = false) {
        return fetchUtils
            .patch(sharedConfig.apiUrl + "/users/favorites/unitType/" + unit._id, { userId: this.userSelected?.id })
            .then(
                action(() => {
                    const favoriteToDelete = (isAllFavorites ? this.allFavorites : this.favoritesUnitTypes).find(
                        (favoriteUnitType) => favoriteUnitType._id === unit._id,
                    ) as TUnitTypeListingMdl;

                    if (favoriteToDelete) {
                        this.favoritesUnitTypes.splice(this.favoritesUnitTypes.indexOf(favoriteToDelete), 1);
                        const index = this.allFavorites.findIndex((item) => item._id === favoriteToDelete._id);
                        this.allFavorites.splice(index, 1);
                    } else {
                        this.favoritesUnitTypes.push(unit);
                    }
                }),
            );
    }

    @action deleteSearchToFavorite(favorite: IFavoritesSearchMdl) {
        return fetchUtils
            .patch(sharedConfig.apiUrl + "/users/favorites/deleteSearch", { favorite, userId: this.userSelected?.id })
            .then(
                action(({ data }) => {
                    const favoriteToDelete = this.favoritesSearches.find(
                        (savedFavorite) => JSON.stringify(data) === JSON.stringify(savedFavorite),
                    );
                    if (favoriteToDelete) {
                        this.favoritesSearches.splice(this.favoritesSearches.indexOf(favoriteToDelete), 1);
                    }
                }),
            );
    }

    @action patchSearchToFavorite(
        filters: TFilter[],
        search: string,
        region?: string,
        city?: string,
        neighborhood?: string,
    ) {
        return fetchUtils
            .patch(sharedConfig.apiUrl + "/users/favorites/search", {
                filters,
                search,
                region,
                city,
                neighborhood,
                userId: this.userSelected?.id,
            })
            .then(
                action(() => {
                    this.favoritesSearches.push({
                        notification: true,
                        filters: JSON.stringify(filters),
                        lastSeenDate: dayjs().toDate(),
                        search,
                        region,
                        city,
                    });
                }),
            );
    }

    @action updateSearch(searchId: string) {
        const searchIndex = this.favoritesSearches.findIndex((search) => search._id === searchId);
        if (searchIndex < 0) return;
        return fetchUtils.patch(sharedConfig.apiUrl + "/users/favorites/search/" + searchId).then(
            action(() => {
                let search = this.favoritesSearches[searchIndex];
                search = { ...search, notification: !search?.notification };
                this.favoritesSearches.splice(searchIndex, 1, search);
            }),
        );
    }

    @action setUserSelected(user?: { id: string; name: string; email: string }) {
        if (this.user && this.user?.roles.includes(USER_ROLES.ADMIN)) {
            this.userSelected = user;
            Object.values(JSON.parse(JSON.stringify(FAVORITES_TYPE))).forEach((favoriteType) => {
                this.favoritesStates[favoriteType as string] = new LoadingStateMdl();
                this.favorites[favoriteType as string] = [];
            });
        }
    }

    save(user: IUserMdl, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(user, files) : user;
        return fetchUtils.patch(sharedConfig.apiUrl + "/users/" + this.user?._id, body, !!files).then(({ data }) => {
            tokenStore.refreshToken();
            this.user = data as IUserMdl;
        });
    }
}

export const userStore = new UserStore();
