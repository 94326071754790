import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, SwiperOptions } from "swiper";
import { THomeCard } from "_common/propertyTypes/propertyTypes";
import { NavSrpsLink } from "_common/ui/links/NavSrpsLink";
import { useTranslation } from "react-i18next";
import { CardTypeOrCity } from "home/CardTypeOrCity";
import { usePropertyPageContext } from "properties/PropertyPageContextAlt";
import "swiper/swiper-bundle.min.css";

type Props = {
    items: THomeCard[];
    breakPoints?: { [width: number]: SwiperOptions };
    removeGeoZone?: boolean;
};

SwiperCore.use([Autoplay, Navigation]);

export function CardTypesList({ items, breakPoints, removeGeoZone }: Props) {
    const { mapOrListSelected } = usePropertyPageContext();
    const { t } = useTranslation();

    return (
        <div id="homeCard">
            <Swiper
                onInit={(swiper) => {
                    console.info("CardTypesList", swiper?.currentBreakpoint, swiper.params.breakpoints);
                }}
                key={mapOrListSelected}
                loop={items.length < 4}
                navigation
                slidesPerView={"auto"}
                spaceBetween={10}
                breakpoints={breakPoints}
                className={"!py-5"}
                observeParents
                observer
            >
                {items.map((item, idx) => {
                    if (!item.url || !item.nbOfProperties) return null;
                    return (
                        <SwiperSlide key={idx}>
                            <NavSrpsLink to={item.url} propertyType={item?.propertyType} removeGeoZone={removeGeoZone}>
                                <CardTypeOrCity
                                    img={item.img}
                                    text={item?.titleLabelKey ? t(item.titleLabelKey) : item.name}
                                />
                            </NavSrpsLink>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}
