import React from "react";
import { getNumberOfFloorFromName } from "_common/_utils/inventoryUtils";
import { useTranslation } from "react-i18next";
import { TFloorMdl } from "admin/floors/_models/floorsMdl";

type Props = {
    floor?: TFloorMdl;
};

export function FloorNumber({ floor }: Props) {
    const { t } = useTranslation();
    if (!floor) return null;
    const floorNumber = getNumberOfFloorFromName(floor);
    return (
        <div className={"text-sm"}>
            {t("unitPage.header.floor", {
                floor: floorNumber,
                context: `${floorNumber}`,
            })}
        </div>
    );
}
