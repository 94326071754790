import React, { useState } from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { Button } from "_common/ui/mui/buttons/Button";
import { useTranslation } from "react-i18next";
import { UnitPageDialog } from "units/UnitPageDialog";
import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";

type Props = {
    unit: TUnitListingMdl;
};

export function InventoryViewUnit({ unit }: Props) {
    const { t } = useTranslation();
    const [unitPageIsOpen, setUnitPageIsOpen] = useState(false);
    const unitStatus = unit.status == INVENTORY_STATUS.AVAILABLE || unit.status == INVENTORY_STATUS.RESERVED;
    return (
        <>
            {unitStatus && (
                <>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        className={
                            "!m-auto !text-xs !sm:text-sm sm:!px-7 !border-[color:var(--color-secondary)] !underline !whitespace-nowrap"
                        }
                        onClick={() => setUnitPageIsOpen(true)}
                    >
                        {t("propertyPage.inventory.viewUnit")}
                    </Button>
                    <UnitPageDialog unit={unit} open={unitPageIsOpen} onClose={() => setUnitPageIsOpen(false)} />
                </>
            )}
        </>
    );
}
