import React, { lazy } from "react";
import Suspenser from "_common/loaders/Suspenser";

const LazyMapRenderLeaflet = lazy(() => import("../maps/MapListingProperties"));

export const PropertiesViewMap = () => {
    return (
        <>
            <Suspenser
                noSpinner
                noText
                fallback={
                    <div
                        className={
                            "relative w-full h-[calc(100vh-165px)] flex overflow-hidden rounded-[5px] z-1 md:h-[calc(100vh-210px)] bg-[#AAD3DF]"
                        }
                    />
                }
            >
                <LazyMapRenderLeaflet />
            </Suspenser>
        </>
    );
};
