import React from "react";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { UnitList } from "properties/propertyPage/inventory/UnitList";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { TableCell, TableRow } from "@material-ui/core";
import { TColumnInventory } from "properties/propertyPage/inventory/InventoryColumns";
import { useTranslation } from "react-i18next";
import { ListStore } from "_common/list/ListStore";
import dayjs from "dayjs";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

type Props = {
    listStore: ListStore<any>;
    columns: TColumnInventory[];
};

export const BuildingsList = observer(({ listStore, columns }: Props) => {
    const propertyStore = usePropertyStore();
    const buildings = propertyStore.property.inventory?.buildings || [];
    const sortedUnits = listStore.paginatedItems.sort((a, b) => {
        const dateComparison = dayjs(a.phase.deliveryDate).isBefore(dayjs(b.phase.deliveryDate))
            ? -1
            : dayjs(a.phase.deliveryDate).isAfter(dayjs(b.phase.deliveryDate))
            ? 1
            : 0;
        console.log("isSame", dayjs(a.phase.deliveryDate).isSame(dayjs(b.phase.deliveryDate)));
        if (!dayjs(a.phase.deliveryDate).isSame(dayjs(b.phase.deliveryDate))) return dateComparison;
        const buildingA = buildings.find((building) => building._id === a.buildingId);
        const buildingB = buildings.find((building) => building._id === b.buildingId);
        if (buildingA && buildingB) {
            const nameA = buildingA.name;
            const nameB = buildingB.name;

            const isNumberA = !isNaN(Number(nameA));
            const isNumberB = !isNaN(Number(nameB));
            if (isNumberA && isNumberB) {
                console.log(nameA, isNumberA, nameB, isNumberB, Number(nameA) < Number(nameB) ? -1 : 1);
                return Number(nameA) < Number(nameB) ? -1 : 1;
            } else {
                return nameA.localeCompare(nameB);
            }
        } else {
            return 0;
        }
    });
    console.log("sortedUnits", sortedUnits);
    const unitsGroupedByBuilding = _.groupBy(sortedUnits.filter(Boolean) as TUnitListingMdl[], "buildingId");
    const { t } = useTranslation();
    return (
        <>
            {listStore.count === 0 && (
                <TableRow className={"h-[30px]"}>
                    <TableCell
                        colSpan={columns.length}
                        align={"center"}
                        className={"!border-b-0 !border-t !border-[var(--color-primary)]"}
                    >
                        {t("propertyPage.inventory.noUnits")}
                    </TableCell>
                </TableRow>
            )}
            {Object.entries(unitsGroupedByBuilding).map(([buildingId, units]) => {
                return <UnitList buildingId={buildingId} units={units} key={buildingId} columns={columns} />;
            })}
        </>
    );
});
