import React, { useState } from "react";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { i18nextInstance } from "shared/_common/i18n/IntlProvider";
import { pagesStore } from "pages/_stores/pagesStore";
import styles from "./_css/langSelector.module.css";
import clsx from "clsx";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useHistory } from "react-router-dom";
import * as H from "history";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { PARAMS_NORTH_PREFIX, PARAMS_PAGE_PREFIX, PARAMS_ZOOM_PREFIX } from "_common/_utils/searchUtils";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { KeyboardArrowDown } from "@material-ui/icons";

export async function changeLang(lang: TLang, history: H.History) {
    const oldLang = i18nextInstance.language as TLang;
    if (oldLang === lang) return;
    const langConfig = sharedConfig.languages[lang];
    const { pathname, hash, search } = history.location;
    if (pathname === "/") {
        window.location.href = langConfig.baseUrl;
    } else {
        const page = pagesStore.getByUrlSync(pathname, oldLang);
        if (page?.localized?.[lang]?.url) {
            window.location.href = langConfig.baseUrl + page.localized[lang]?.url + search + hash;
        } else if (!page && pathname.split("/").length >= 3) {
            const oldTranslatedRoute = pathname.split("/")[2];
            const route = Object.entries(i18nextInstance.getDataByLanguage(oldLang)?.translation.routes ?? "").find(
                ([_key, value]) => value === oldTranslatedRoute,
            );
            if (route && route[0]) {
                const arrNewPathname = [...pathname.split("/")];
                if (route[0] === "properties") {
                    ((propertiesStore.getByAliasUrl(arrNewPathname[3], oldLang, lang) as unknown) as Promise<
                        TPropertyListingMdl
                    >).then(
                        async (data) => {
                            window.location.href = `/${lang}/${getI18nExpByLang(lang, "routes.properties")}/${
                                data.localized.urlAlias
                            }`;
                        },
                        () => {
                            window.location.href = langConfig.baseUrl;
                        },
                    );
                } else {
                    arrNewPathname[2] = getI18nExpByLang(lang, `routes.${route[0]}`);
                    const localizedBeachFrontUrl = reformatStringForUrls(
                        getI18nExpByLang(oldLang, "property.typesUrl.beachfront"),
                    );
                    const localizedLuxuryUrl = reformatStringForUrls(
                        getI18nExpByLang(oldLang, "property.typesUrl.luxury"),
                    );
                    if (
                        arrNewPathname?.[3] &&
                        (!arrNewPathname[3].startsWith(PARAMS_PAGE_PREFIX) ||
                            !arrNewPathname[3].startsWith(PARAMS_ZOOM_PREFIX) ||
                            !arrNewPathname[3].startsWith(PARAMS_NORTH_PREFIX) ||
                            arrNewPathname[3] !== localizedBeachFrontUrl ||
                            arrNewPathname[3] !== localizedLuxuryUrl)
                    ) {
                        const propertyType = Object.keys(PROPERTY_TYPE).find(
                            (propertyType) =>
                                getI18nExpByLang(oldLang, "property.typesUrl." + propertyType).toLowerCase() ===
                                arrNewPathname[3].toLowerCase(),
                        );

                        if (propertyType) {
                            arrNewPathname[3] = getI18nExpByLang(
                                lang,
                                "property.typesUrl." + propertyType,
                            ).toLowerCase();
                        }
                    } else if (
                        arrNewPathname?.[5] &&
                        (!arrNewPathname[5].startsWith(PARAMS_PAGE_PREFIX) ||
                            !arrNewPathname[5].startsWith(PARAMS_ZOOM_PREFIX) ||
                            !arrNewPathname[5].startsWith(PARAMS_NORTH_PREFIX) ||
                            arrNewPathname[5] !== localizedBeachFrontUrl ||
                            arrNewPathname[5] !== localizedLuxuryUrl)
                    ) {
                        const propertyType = Object.keys(PROPERTY_TYPE).find(
                            (propertyType) =>
                                getI18nExpByLang(oldLang, "property.typesUrl." + propertyType).toLowerCase() ===
                                arrNewPathname[5].toLowerCase(),
                        );
                        if (propertyType) {
                            arrNewPathname[5] = getI18nExpByLang(
                                lang,
                                "property.typesUrl." + propertyType,
                            ).toLowerCase();
                        }
                    }

                    if (arrNewPathname.includes(localizedBeachFrontUrl)) {
                        arrNewPathname.splice(
                            arrNewPathname.indexOf(localizedBeachFrontUrl),
                            1,
                            reformatStringForUrls(getI18nExpByLang(lang, "property.typesUrl.beachfront")),
                        );
                    }
                    if (arrNewPathname.includes(localizedLuxuryUrl)) {
                        arrNewPathname.splice(
                            arrNewPathname.indexOf(localizedLuxuryUrl),
                            1,
                            reformatStringForUrls(getI18nExpByLang(lang, "property.typesUrl.luxury")),
                        );
                    }
                    arrNewPathname.splice(0, 2);
                    const newPath = arrNewPathname.join("/");
                    window.location.href = langConfig.baseUrl + "/" + newPath + search + hash;
                }
            } else {
                window.location.href = langConfig.baseUrl;
            }
        } else {
            window.location.href = langConfig.baseUrl;
        }
    }
}

export function LangSelector() {
    const [opened, setOpened] = useState(false);
    const history = useHistory();

    return (
        <div onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)} className={styles.container}>
            <div className={clsx("flex flex-row items-center position_relative", styles.flag)}>
                {i18nextInstance.language.toUpperCase()}
                <KeyboardArrowDown />
                <div
                    className={clsx(styles.subMenuContainer, {
                        ["hidden"]: !opened,
                    })}
                >
                    <ul className={styles.list}>
                        {Object.entries(sharedConfig.languages).map(([lang, { title }]) => (
                            <li key={lang}>
                                <div
                                    className={styles.link}
                                    onClick={() => {
                                        setOpened(false);
                                        void changeLang(lang as TLang, history);
                                    }}
                                >
                                    {title}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
