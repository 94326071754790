import React from "react";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { useTranslation } from "react-i18next";

type Props = {
    priceMin: number;
    priceInPesosMin: number;
    isInPesosProperty: boolean;
    isPriceNotDisplayed: boolean;
};

export function PropertyCardSubTitle({ priceMin, priceInPesosMin, isInPesosProperty, isPriceNotDisplayed }: Props) {
    const { t } = useTranslation();

    return (
        <>
            {isPriceNotDisplayed ? (
                <>{t("propertiesPage.card.priceOnRequest")}</>
            ) : (
                <>
                    {t("words.from")}
                    {isInPesosProperty ? (
                        <>{formatPesosPrice(priceInPesosMin, false, isInPesosProperty)}</>
                    ) : (
                        <>{formatPrice(priceMin)}</>
                    )}
                </>
            )}
        </>
    );
}
