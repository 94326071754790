import React, { useState } from "react";
import styles from "./_css/menu.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { NoSsr } from "@material-ui/core";
import { URLS } from "_configs/URLS";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import { MenuUser } from "main/header/menu/MenuUser";
import { Button } from "_common/ui/mui/buttons/Button";
import { TypeSubMenu } from "main/header/menu/TypeSubMenu";
import { MENU_ITEMS } from "main/header/menu/MENU_ITEMS";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";

export function Menu() {
    const { t } = useTranslation();
    const [menuKeyHovered, setMenuKeyHovered] = useState<string | undefined>(undefined);

    const isLogged = __BROWSER__ && userStore.isLogged;
    const { city, neighbourhood, province } = geoZonesStore.address;

    return (
        <nav>
            <ul className={clsx(styles.menu, "flex flex-row items-center")}>
                <li
                    className={clsx("pr_30 flex items-center justify-center position_relative", styles.menuItem)}
                    onMouseEnter={() => {
                        setMenuKeyHovered("buy");
                    }}
                    onMouseLeave={() => setMenuKeyHovered(undefined)}
                >
                    <Link
                        to={URLS.buy(
                            i18next.language,
                            reformatStringForUrls(province),
                            reformatStringForUrls(city),
                            neighbourhood ? reformatStringForUrls(neighbourhood) : undefined,
                        )}
                    >
                        {t("menu.buy")}
                    </Link>
                    <div
                        className={clsx(styles.subMenuContainer, "w-max", {
                            ["hidden"]: menuKeyHovered !== "buy",
                        })}
                    >
                        <TypeSubMenu />
                    </div>
                </li>
                {MENU_ITEMS.map((menuItem) => {
                    return (
                        <li
                            key={menuItem.key}
                            className={clsx(
                                "pr_30 flex items-center justify-center position_relative",
                                styles.menuItem,
                            )}
                        >
                            <Link to={menuItem.itemLink(i18next.language as TLang)}>{t(menuItem.itemLabelKey)}</Link>
                        </li>
                    );
                })}
                <NoSsr
                    fallback={
                        <li key={"signIn"} className={clsx(styles.signIn, "")}>
                            <Link to={URLS.auth.signIn()}>
                                <Button variant={"outlined"}>{t("menu.signIn")}</Button>
                            </Link>
                        </li>
                    }
                >
                    {isLogged ? (
                        <li
                            onMouseEnter={() => setMenuKeyHovered("profil")}
                            onMouseLeave={() => setMenuKeyHovered(undefined)}
                            key={"profile"}
                            className={clsx("ph_10 position_relative ", styles.menuItem)}
                        >
                            <Link to={URLS.user.profile()}>{t("menu.profile")}</Link>
                            <div
                                className={clsx(styles.subMenuContainer, {
                                    ["hidden"]: menuKeyHovered !== "profil",
                                })}
                            >
                                <MenuUser />
                            </div>
                        </li>
                    ) : (
                        <li key={"signIn"}>
                            <Link to={URLS.auth.signIn()}>
                                <Button variant={"outlined"}>{t("menu.signIn")}</Button>
                            </Link>
                        </li>
                    )}
                </NoSsr>
            </ul>
        </nav>
    );
}
