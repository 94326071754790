import React from "react";
import { TPropertyCard } from "properties/_models/PropertyMdl";
import { CardContent, CardTitle } from "properties/propertyCard/components/PropertyCardComponents";
import { PropertyCardTitle } from "properties/propertyCard/components/PropertyCardTitle";
import { PropertyCardSubTitle } from "properties/propertyCard/components/PropertyCardSubTitle";
import { isInPesosProperty, isLandOrCommercial } from "_common/_utils/propertyUtils";
import { PropertyCardSurfaces } from "properties/propertyCard/components/PropertyCardSurfaces";
import { PropertyCardAddress } from "properties/propertyCard/components/PropertyCardAddress";
import { PropertyCardPrice } from "properties/propertyCard/components/PropertyCardPrice";
import { PropertyCardRooms } from "properties/propertyCard/components/PropertyCardBathrooms";

type Props = {
    property: TPropertyCard;
};

export function PropertyCardInfos({ property }: Props) {
    return (
        <>
            <div className={"mb-2.5"}>
                <CardTitle>
                    <PropertyCardTitle title={property.localized.title} />
                </CardTitle>
                <CardTitle>
                    <PropertyCardSubTitle
                        priceMin={property.price.min}
                        priceInPesosMin={property.priceInPesos.min}
                        isInPesosProperty={isInPesosProperty(property)}
                        isPriceNotDisplayed={property.priceIsNotDisplayed}
                    />
                </CardTitle>
            </div>
            <div className={"mb-2.5"}>
                <CardContent className={"mb-1"}>
                    <div className="flex gap-2">
                        {!isLandOrCommercial(property.type) && typeof property?.bedrooms?.min === "number" && (
                            <PropertyCardRooms min={property.bedrooms.min} max={property.bedrooms?.max} />
                        )}
                        {!!property?.squareSurface?.min && (
                            <PropertyCardSurfaces surfaceMin={property.squareSurface.min} />
                        )}
                    </div>
                </CardContent>
                <PropertyCardAddress address={property.address} />
            </div>
            <PropertyCardPrice
                isInPesosProperty={isInPesosProperty(property)}
                isPriceNotDisplayed={property.priceIsNotDisplayed}
                priceInPesosMin={property.priceInPesos?.min ?? 0}
                priceMin={property.price?.min ?? 0}
            />
        </>
    );
}
