import React from "react";
import { useBedroomsNumberLabel } from "properties/propertyPage/_hooks/useNumberOfBedrooms";

type Props = {
    min: number;
    max?: number;
    isBath?: boolean;
};

export function PropertyRoomsLabel({ min, max, isBath }: Props) {
    const roomLabel = useBedroomsNumberLabel(min, max, isBath);
    return (
        <div className={"text-lg"}>
            <>{roomLabel}</>
        </div>
    );
}
