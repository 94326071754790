import { observable } from "mobx";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";

export class UnitTypeStore {
    @observable unitType: TUnitTypeListingMdl;

    constructor(unit: TUnitTypeListingMdl) {
        this.unitType = unit;
    }
}
