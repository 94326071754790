import React from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { useTranslation } from "react-i18next";

type Props = {
    unit: TUnitListingMdl;
};

export function InventoryRoomsCell({ unit }: Props) {
    const { t } = useTranslation();
    const key =
        unit.powderRooms && unit.powderRooms > 0
            ? "propertyPage.inventory.bedBathPowder"
            : "propertyPage.inventory.bedBath";
    return (
        <div className={"text-xs sm:text-sm whitespace-nowrap"}>
            {t(key, { bed: unit.bedrooms, bath: unit.bathrooms })}
        </div>
    );
}
