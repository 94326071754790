import React from "react";
import { PaginationItem, PaginationItemProps } from "@material-ui/lab";

// Utilisation de React.forwardRef pour gérer correctement les refs.
const CustomLink = React.forwardRef<HTMLAnchorElement, any>(({ rel, ...props }, ref) => {
    return <a ref={ref} {...props} rel={rel} />;
});

interface CustomPaginationItemProps extends PaginationItemProps {
    rel?: string;
}

const CustomPaginationItem = ({ rel, ...rest }: CustomPaginationItemProps) => {
    return <PaginationItem {...rest} component={CustomLink} />;
};

export default CustomPaginationItem;
