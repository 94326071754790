import React from "react";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { ComponentRenderer } from "components/ComponentRenderer";
import { PageRendererContext } from "pages/PageContext";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { Container, NoSsr } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { useGetItemsForBreadCrumb } from "_common/_utils/pageUtils";
import { PageMeta } from "pages/PageMeta";
import { useHomePage } from "_common/_utils/hookUtils";
import { ArticleSideBar } from "blog/ArticleSideBar";
import { BlogHeader } from "pages/blog/BlogHeader";
import { BlogFeaturedPropertiesSection } from "pages/blog/BlogFeaturedPropertiesSection";
import { ShareArticle } from "pages/blog/ShareArticle";
import { BlogExplorePropertiesInGeoZone } from "pages/blog/BlogExplorePropertiesInGeoZone";
import Suspenser from "_common/loaders/Suspenser";
import { useLocation } from "react-router-dom";

type Props = {
    page: IPageMdl;
};

export function PageRenderer(props: Props) {
    const isHomePage = useHomePage();
    const location = useLocation();
    const items = useGetItemsForBreadCrumb(props.page);
    const localizedPage = props.page.localized[i18nextInstance.language as TLang];
    const isBlogPage = props.page.type === PAGE_TYPE.ARTICLE;
    const geoZoneId = props.page.geoZoneId;
    if (!localizedPage) return null;

    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={props.page} />
            {!isHomePage && !isBlogPage && (
                <Container>
                    <Breadcrumb currentItem={{ itemLabel: localizedPage.title }} items={items} />
                </Container>
            )}
            {isBlogPage ? (
                <>
                    <div className={"flex flex-col md:flex-row md:mx-auto"}>
                        <div className={"flex flex-col md:max-w-[1000px]"}>
                            <BlogHeader localizedPage={localizedPage} page={props.page} />
                            <div className={"mt-5"}>
                                <ComponentRenderer component={localizedPage.component} isRoot />
                            </div>
                            <Suspenser>
                                <div className={"mt-4"}>
                                    <ShareArticle
                                        url={sharedConfig.appUrl + location.pathname}
                                        title={localizedPage.title || ""}
                                    />
                                </div>
                            </Suspenser>
                        </div>

                        <NoSsr>
                            <ArticleSideBar blog={props.page} page={props.page} />
                        </NoSsr>
                    </div>

                    <Container className="!max-w-[1260px] my-[74px] lg:mb-[76px] mt-12">
                        <BlogFeaturedPropertiesSection />
                        <BlogExplorePropertiesInGeoZone geoZoneId={geoZoneId} />
                    </Container>
                </>
            ) : (
                <ComponentRenderer component={localizedPage.component} isRoot />
            )}
        </PageRendererContext>
    );
}
