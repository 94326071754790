import React from "react";
import { CollapseSection } from "_common/ui/components/CollapseSection";
import dayjs from "dayjs";
import calendar from "../../assets/images/icons/calendar.svg";
import { useTranslation } from "react-i18next";
import { TPropertyConstructProgress } from "properties/_models/PropertyConstructProgressMdl";

type Props = {
    constructProgress: TPropertyConstructProgress;
    index: number;
    children: React.ReactNode;
};

export function PhaseCollapsible({ constructProgress, children, index }: Props) {
    const { t } = useTranslation();
    return (
        <CollapseSection
            title={`${t("propertyPage.phases.phase")} ${index} - ${t("propertyPage.phases.deliveryIn")} ${dayjs(
                constructProgress?.date,
            ).format("MMMM YYYY")}`}
            heightOfCollapsable={0}
            icon={calendar}
            className={"bg-[var(--color-primary-background)] h-[27px]"}
            titleClassName={"text-xs font-normal"}
            iconClassName={"!w-3.5 !mr-2"}
        >
            {children}
        </CollapseSection>
    );
}
