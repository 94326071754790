import React from "react";
import styles from "./_css/propertiesListLoader.module.css";

export function PropertiesListLoader() {
    return (
        <>
            {[...Array(6)].map((_item, idx) => {
                return (
                    <div key={idx} className={styles.card}>
                        <div className={styles.img} />
                        <div className={"space-y-2 p-4"}>
                            <h2 />
                            <p />
                        </div>
                    </div>
                );
            })}
        </>
    );
}
