import React, { Fragment, lazy } from "react";
import PropertyViewGalleryModalNavbar from "properties/propertyPage/propertyView/PropertyViewGalleryModalNavbar";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import SwiperCore, { Navigation } from "swiper";
import { PropertyViewGallerySwiper } from "properties/propertyPage/propertyView/PropertyViewGallerySwiper";
import { GALLERY_NAME_MODAL, GALLERY_TABS } from "_configs/propertyConfig";
import { TImage } from "_common/types/baseTypes";
import { toJS } from "mobx";
import { isImage } from "_common/_utils/fileUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Suspenser from "_common/loaders/Suspenser";
import { galleryStore } from "gallery/_store/GalleryStore";
import { Dialog } from "@material-ui/core";
import { observer } from "mobx-react-lite";

SwiperCore.use([Navigation]);

const LazyPropertyViewGalleryEmbed = lazy(() =>
    import("properties/propertyPage/propertyView/PropertyViewGalleryEmbed"),
);

export const PropertyViewGalleryModal = observer(() => {
    const propertyStore = usePropertyStore();
    const property = propertyStore.property;

    const constructProgressImages = property?.constructProgress.reduce(
        (acc: TImage[], progress) => acc.concat(toJS(progress.images)),
        [],
    );

    return (
        <Dialog
            open={galleryStore.openedGalleryModal > -1 && galleryStore.modalName === GALLERY_NAME_MODAL.PROPERTY}
            onClose={() => galleryStore.setOpenedGalleryModal(-1)}
            fullScreen
        >
            <div className="flex flex-col h-full relative select-none">
                <PropertyViewGalleryModalNavbar handleClose={() => galleryStore.closeGalleryModal()} />
                <div className={"sm:h-[75px] h-[140px]"} />
                <div className={"relative h-full py-1"}>
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.GALLERY && (
                        <PropertyViewGallerySwiper images={property?.photos} />
                    )}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.SHOWROOM && (
                        <PropertyViewGallerySwiper images={property?.showroom} />
                    )}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.LEVEL_PLAN &&
                        property.floorPlans.map((picture, index) => {
                            return (
                                <Fragment key={index}>
                                    {isImage(picture.url ?? "") ? (
                                        <LazyLoadImage
                                            key={index}
                                            className="h-[50vw] m-auto cursor-pointer my-3 select-none object-cover"
                                            src={picture.url}
                                            alt={GALLERY_TABS.LEVEL_PLAN}
                                        />
                                    ) : (
                                        <div className={"h-[calc(100vh-140px)] sm:h-[calc(100vh-75px)]"}>
                                            <Suspenser>
                                                <LazyPropertyViewGalleryEmbed
                                                    src={picture.url + "#toolbar=0&navpanes=0"}
                                                    width={"100%"}
                                                    height={"100%"}
                                                />
                                            </Suspenser>
                                        </div>
                                    )}
                                </Fragment>
                            );
                        })}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.VIDEO && (
                        <PropertyViewGallerySwiper videos={[property?.video, property?.video2]} />
                    )}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.FINISHINGS &&
                        property.finishings.map((picture, index) => (
                            <Fragment key={index}>
                                <div className={"h-[calc(100vh-140px)] sm:h-[calc(100vh-75px)]"}>
                                    <Suspenser>
                                        <LazyPropertyViewGalleryEmbed
                                            src={picture.url + "#toolbar=0&navpanes=0"}
                                            width={"100%"}
                                            height={"100%"}
                                        />
                                    </Suspenser>
                                </div>
                            </Fragment>
                        ))}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.PREVIOUS_PROJECTS && (
                        <PropertyViewGallerySwiper images={property?.previousProjects} />
                    )}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.CONSTRUCT_PROGRESS && (
                        <PropertyViewGallerySwiper images={constructProgressImages} />
                    )}
                    {galleryStore.openedGalleryListTab === GALLERY_TABS.TOUR_3D && (
                        <iframe
                            className="h-full select-none w-full"
                            src={property.visit3D}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="3D Tour"
                        />
                    )}
                </div>
            </div>
        </Dialog>
    );
});
