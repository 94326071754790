import React from "react";
import { useTranslation } from "react-i18next";
import chevronLeft from "../assets/images/icons/chevronLeft.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { pagesStore } from "pages/_stores/pagesStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";

type Props = {
    blogType: string;
    odd?: boolean;
};

export function ArticleCategoryCard(props: Props) {
    const { t } = useTranslation();
    const promise = pagesStore.countArticlesByType(props.blogType);
    const { loading } = useLoadingFromPromise(promise);
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, _error, results) => {
                if (!results) return null;
                const { data } = results;
                if (!data) return null;
                const { count } = data;
                if (!count) return null;
                return (
                    <div
                        className={
                            "p_10 py-[5px] mt-[13px] bg-[var(--color-firstLvl-bg)] shadow-[0px_0px_5px_0px_#1A3A4533] rounded-lg"
                        }
                    >
                        <Link
                            to={URLS.blog(i18next.language, props.blogType.toLowerCase())}
                            className={"flex flex-row items-center"}
                        >
                            <div className={"flex-1"}>
                                <div className={"flex flex-row justify-between items-center"}>
                                    <span className={"font-semibold text-lg"}>
                                      {t("blogPage.blogTypes." + props.blogType).charAt(0).toUpperCase() + t("blogPage.blogTypes." + props.blogType).slice(1)}
                                    </span>
                                    <div className="mr-5 text-[var(--color-secondary)] text-sm">
                                        {t("blogPage.resultsByBlogType", { count })}
                                    </div>
                                </div>
                            </div>
                            <LazyLoadImage className={"rotate-180"} src={chevronLeft} />
                        </Link>
                    </div>
                );
            }}
        />
    );
}
