import { action, observable } from "mobx";
import { GALLERY_NAME_MODAL, GALLERY_TABS } from "_configs/propertyConfig";
import { TImage } from "_common/types/baseTypes";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";

export type TGalleryImage = { tabName: GALLERY_TABS; images: TImage[]; label: string };

export type TOptionsForm = {
    title?: string;
    resource: MAIN_RESOURCE;
};

export class GalleryStore {
    @observable openedGalleryListTab: GALLERY_TABS | "NONE" = "NONE";
    @observable openedGalleryModal = -1;
    @observable contactFormIsOpen = false;
    @observable optionsForm: TOptionsForm = { resource: MAIN_RESOURCE.PROPERTIES };
    @observable modalName = GALLERY_NAME_MODAL.NONE;

    constructor() {}

    @action setOpenedGalleryTab(tabName = GALLERY_TABS.GALLERY) {
        this.openedGalleryListTab = tabName;
    }

    @action setOpenedGalleryModal(index = -1) {
        this.openedGalleryModal = index;
    }

    @action setModalName(modalName = GALLERY_NAME_MODAL.NONE) {
        console.log("setModalName", modalName);
        this.modalName = modalName;
    }

    @action closeGalleryTab() {
        this.openedGalleryListTab = "NONE";
    }

    @action closeGalleryModal() {
        this.openedGalleryModal = -1;
    }

    @action openContactForm(options: TOptionsForm) {
        console.log("openContactForm", options);
        this.optionsForm = options;
        this.contactFormIsOpen = true;
    }

    @action closeContactForm() {
        this.contactFormIsOpen = false;
    }
}

const galleryStore = new GalleryStore();
export { galleryStore };
