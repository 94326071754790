import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";
import { PHASE_STATUS, TPhasesMdl } from "admin/phases/_models/PhasesMdl";
import dayjs from "dayjs";
import i18next from "i18next";

type Props = {
    status: INVENTORY_STATUS;
    phases: TPhasesMdl[];
};

export function PhaseEarliestDisplay({ status, phases }: Props) {
    const { t } = useTranslation();

    function getLabelOfPhase(phase: TPhasesMdl) {
        switch (phase.status) {
            case PHASE_STATUS.READY_TO_MOVE_IN:
                return t("propertyPage.inventory.phaseLabel.ready");
            case PHASE_STATUS.UNDER_CONSTRUCTION:
                return dayjs(phase.deliveryDate).locale(i18next.language).format("MMMM YYYY");
            case PHASE_STATUS.AVAILABLE_X_MONTHS_AFTER:
                return t("propertyPage.AVAILABLE_X_MONTHS_AFTER_TEXT", { monthsNumber: phase.monthsNumber });
            default:
                return t("propertyPage.inventory.phaseLabel.ready");
        }
    }

    const INVENTORY_STATUS = {
        AVAILABLE: {
            color: "bg-green-500",
            text: t("propertyPage.status.AVAILABLE"),
        },
        RESERVED: {
            color: "bg-yellow-500",
            text: t("propertyPage.status.RESERVED"),
        },
        SOLD: {
            color: "bg-red-500",
            text: t("propertyPage.status.SOLD"),
        },
        BLOCKED: {
            color: "bg-orange-500",
            text: t("propertyPage.status.BLOCKED"),
        },
        NONE: {
            color: "bg-gray-500",
            text: t("propertyPage.status.NONE"),
        },
    };
    if (!phases.length) return null;
    return (
        <div className={"flex items-center gap-1"}>
            <div
                className={clsx(
                    "h-[10px] w-[10px] bg-green-500 rounded-full text-xs sm:text-sm",
                    INVENTORY_STATUS[status].color,
                )}
            ></div>
            <p className={"text-xs sm:text-sm"}>{INVENTORY_STATUS[status].text} :</p>
            <p className={"text-xs sm:text-sm whitespace-nowrap"}>
                {phases.length > 1 ? "Phases " : "Phase "}
                {phases
                    .map((_, idx) => idx + 1)

                    .join(", ")
                    .replace(/, (\d+)$/, " & $1")}
            </p>
            <p className={"text-xs sm:text-sm whitespace-nowrap"}>-</p>
            <p className={"sm:hidden text-xs sm:text-sm truncate"}>{getLabelOfPhase(phases[0])}</p>
            <p className={"hidden sm:block text-xs sm:text-sm whitespace-nowrap"}>
                {t("propertyPage.inventory.earliestDelivery", {
                    delivery: getLabelOfPhase(phases[0]),
                })}
            </p>
        </div>
    );
}
