import React from "react";
import { CardContainer } from "properties/propertyCard/components/PropertyCardComponents";
import { useTranslation } from "react-i18next";

export function PriceInfoCard() {
    const { t } = useTranslation();
    return (
        <CardContainer className={"w-[245px] border border-[var(--color-primary)] rounded-lg !text-xs"}>
            <div>{t("property.priceInfo.text")}</div>
            <br />
            <div className={"font-semibold"}>{t("property.priceInfo.subText")}</div>
        </CardContainer>
    );
}
