import React from "react";
import shared from "../../../assets/images/icons/shared.svg";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ShareButton() {
    const location = useLocation();
    const { t } = useTranslation();

    const handleCopyUrl = () => {
        const currentUrl = window.location.origin + location.pathname;

        navigator.clipboard.writeText(currentUrl).then(() => {
            toast.success(t("propertyPage.toast.clipboardSuccess"));
        });
    };

    return (
        <div
            className="border border-[#1A3A45] rounded-full p-2 flex items-center justify-center cursor-pointer bg-white"
            onClick={handleCopyUrl}
        >
            <img src={shared} alt={"shared icon"} className="h-4 w-4" />
        </div>
    );
}
