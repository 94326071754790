import React, { useEffect } from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { calculateAndFormatPropertyPrice, isInPesosProperty } from "_common/_utils/propertyUtils";
import { useInventoryContext } from "properties/propertyPage/inventory/InventoryContext";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";
import { useCurrency } from "_common/currency/CurrencyProvider";

type Props = {
    unit: TUnitListingMdl;
};

export function InventoryPriceCell({ unit }: Props) {
    const { currentCurrency } = useCurrency();
    const { currency, setCurrency } = useInventoryContext();
    const propertyStore = usePropertyStore();

    useEffect(() => {
        setCurrency(currentCurrency);
    }, [currentCurrency]);

    const price = calculateAndFormatPropertyPrice(
        {
            priceMin: unit.price,
            priceInPesosMin: unit.priceInPesos,
            priceIsInPesos: isInPesosProperty(propertyStore.property),
        },
        currency,
    );
    return (
        <div className={"text-xs sm:text-sm whitespace-nowrap"}>
            {unit.status !== INVENTORY_STATUS.AVAILABLE ? "-" : price}
        </div>
    );
}
