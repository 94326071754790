import React from "react";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PROPERTY_SUBTYPE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";

export function UnitNameHeader() {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const isLandOrBuildableHouse =
        propertyStore.property.type === PROPERTY_TYPE.land ||
        propertyStore.property.subType === PROPERTY_SUBTYPE.buildableHouse;
    return (
        <div className={"text-sm w-[calc(20vw-26px)] sm:w-auto sm:text-sm whitespace-nowrap"}>
            {t(`propertyPage.inventory.${isLandOrBuildableHouse ? "lot" : "unit"}`)}
        </div>
    );
}
