import React from "react";
import house from "../../assets/images/communities/house.svg";
import doubleHouse from "../../assets/images/communities/doubleHouse.svg";
import surface from "../../assets/images/communities/surface.svg";
import beachFront from "../../assets/images/communities/beachFront.svg";
import gated from "../../assets/images/communities/gated.svg";
import { CommunitiesSectionLogo } from "communities/components/CommunitiesSectionLogo";
import { useCommunityStore } from "communities/CommunityContext";
import { useTranslation } from "react-i18next";

export function CommunitiesSectionCard() {
    const communityStore = useCommunityStore();
    const { t } = useTranslation();

    const LOGO_ARRAY = [
        {
            src: house,
            alt: "house",
            text: `${communityStore.community.propertiesCount} ${t("communitiesPage.propertiesSale")}`,
        },
        {
            src: doubleHouse,
            alt: "doubleHouse",
            text: `${communityStore.community.unitsCount} ${t("communitiesPage.unitsSale")}`,
        },
        {
            src: surface,
            alt: "surface",
            text: `${t("communitiesPage.totalSurface")} ${communityStore.community.totalSurface}`,
        },
        ...(communityStore.community.features && communityStore.community.features.beachfront
            ? [
                  {
                      src: beachFront,
                      alt: "beachFront",
                      text: t("communitiesPage.beachFront"),
                  },
              ]
            : []),
        ...(communityStore.community.features && communityStore.community.features.gatedCommunity
            ? [
                  {
                      src: gated,
                      alt: "beachFront",
                      text: t("communitiesPage.gated"),
                  },
              ]
            : []),
    ];
    return (
        <div
            className={
                "grid grid-rows-3 grid-col-1 mt-5 p-5 gap-6 border border-[var(--color-border-card)] rounded-lg self-start md:mt-0 md:grid-flow-col md:flex-1"
            }
        >
            {LOGO_ARRAY.map((section, index) => (
                <CommunitiesSectionLogo {...section} key={index} size={26} />
            ))}
        </div>
    );
}
