import React from "react";
import clsx from "clsx";

type Props = {
    value?: boolean;
    onChange?: (value: boolean) => void;
    labelOn?: string;
    labelOff?: string;
    className?: string;
    color?: "secondary" | "tertiary";
};

export function SwitchButton({ value, onChange, labelOff, labelOn, className, color }: Props) {
    return (
        <div className={clsx("min-w-24 h-8", className)}>
            <div
                className={clsx(
                    "flex flex-row items-center select-none justify-around text-sm h-full w-full bg-[#f5f5f5] cursor-pointer relative rounded-[999px] border border-solid border-[var(--color-primary)] p-1",
                )}
                onClick={() => onChange?.(!value)}
            >
                <div
                    className={clsx(
                        "absolute h-full w-[51%] border border-[#f5f5f5] bg-[color:var(--color-secondary)] transition-all duration-[0.3s] ease-[ease] rounded-[999px] border-2 border-solid left-0 top-0",
                        { ["left-full -translate-x-full "]: !value },
                        {
                            ["bg-[color:var(--color-tertiary)]"]: color === "tertiary",
                        },
                    )}
                />
                {[labelOn, labelOff].map((label, index) => (
                    <div
                        key={index}
                        className={clsx(
                            "z-10 text-[color:var(--color-primary)] transition-all duration-[0.3s] ease-[ease] text-xs w-full h-full flex justify-center items-center whitespace-nowrap",
                            { ["text-white"]: (value && index === 0) || (!value && index === 1) },
                        )}
                    >
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
}
