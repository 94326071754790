import React from "react";
import { Container } from "@material-ui/core";
import { FooterPopularSearches } from "main/footer/FooterPopularSearches";
import { FooterForm } from "main/footer/FooterForm";
import { FooterDescription } from "main/footer/FooterDescription";
import { FooterContact } from "main/footer/FooterContact";
import { FooterLink } from "main/footer/FooterLink";
import styles from "./_css/footer.module.css";
import clsx from "clsx";
import { FooterSocial } from "main/footer/FooterSocial";

export const FOOTER_REF: { current: HTMLDivElement | null } = { current: null };

export function Footer() {
    return (
        <div ref={(ref) => (FOOTER_REF.current = ref)} className={styles.container}>
            <Container>
                <div className={clsx(styles.content, "flex flex-row justify-between flex-wrap")}>
                    <div className={clsx("flex-1 mb_40")}>
                        <div className={styles.containerDescription}>
                            <FooterDescription />
                        </div>
                    </div>
                    <div className={clsx("flex-1")}>
                        <div className={styles.containerPopular}>
                            <FooterPopularSearches />
                        </div>
                    </div>
                    <div className={clsx("flex-1 mb_40")}>
                        <div className={styles.containerContact}>
                            <FooterContact />
                        </div>
                    </div>
                    <div className={clsx("flex-1 mb_40")}>
                        <div className={styles.containerForm}>
                            <FooterForm />
                        </div>
                    </div>
                    <div className={"mobileDisplay"}>
                        <FooterSocial />
                    </div>
                </div>
                <FooterLink />
                <div className={"textAlign_center text_xxsmall mt_40"}>Copyright © Zisla 2025</div>
            </Container>
        </div>
    );
}
