import React from "react";
import { useTranslation } from "react-i18next";
import { galleryStore } from "gallery/_store/GalleryStore";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

type Props = {
    unit: TUnitListingMdl;
};

export function InventoryRequestInfo({ unit }: Props) {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    return (
        <div>
            <div
                className={"underline cursor-pointer"}
                onClick={() =>
                    galleryStore.openContactForm({
                        resource: MAIN_RESOURCE.UNIT,
                        title: t("unitPage.form.title", { unit: unit.name, property: property.localized.title }),
                    })
                }
            >
                {t("propertyPage.inventory.requestInfo")}
            </div>
        </div>
    );
}
