import React, { createContext, FC, useContext, useState } from "react";
import { CURRENCY_OPTIONS } from "_common/currency/CurrencyTypes";

type InventoryContextType = {
    currency: CURRENCY_OPTIONS;
    setCurrency: (currency: CURRENCY_OPTIONS) => void;
};

type Props = {
    children: React.ReactNode;
    initialCurrency?: CURRENCY_OPTIONS;
};

const InventoryContext = createContext<InventoryContextType | undefined>(undefined);

export const InventoryProvider: FC<Props> = ({ children, initialCurrency }: Props) => {
    const [currency, setCurrency] = useState<CURRENCY_OPTIONS>(initialCurrency ?? CURRENCY_OPTIONS.USD);

    return <InventoryContext.Provider value={{ currency, setCurrency }}>{children}</InventoryContext.Provider>;
};

export const useInventoryContext = () => {
    const context = useContext(InventoryContext);
    if (!context) {
        throw new Error("useInventoryContext must be used within an InventoryProvider");
    }
    return context;
};
