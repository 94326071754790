import React from "react";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContactUsForm } from "contact/ContactUsForm";
import { ContactUsInfo } from "contact/ContactUsInfo";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { Meta } from "_common/_utils/Meta";
import { URLS } from "_configs/URLS";
import { LazyLoadImage } from "react-lazy-load-image-component";
import playa from "../assets/images/contactUs/playa.webp";
import { ContactUsAddresses } from "contact/ContactUsAddresses";

export default function ContactPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const meta = {
        title: i18next.t("contactPage.meta.title"),
        description: i18next.t("contactPage.meta.description"),
        url: sharedConfig.appUrl + history.location.pathname,
        alternateUrls: [] as { url: string; lang: TLang }[],
    };
    for (const lang of Object.keys(sharedConfig.languages)) {
        meta.alternateUrls.push({ url: sharedConfig.appUrl + URLS.contact(lang), lang: lang as TLang });
    }
    return (
        <div className={"md:mx-auto"}>
            <Meta title={meta.title} url={meta.url} alternateUrls={meta.alternateUrls} description={meta.description} />
            <Container className={"mt-5 mb-14 md:mt-10"}>
                <div className="flex flex-col md:flex-row md:gap-[110px]">
                    <div className="mb-6 md:mb-0 md:max-w-[670px] md:flex-2">
                        <h1 hidden className="mb-6">
                            {t("contactPage.h1Title")}
                        </h1>
                        <h2
                            className="font-semibold text-[25px] leading-[30px] mb-4 md:text-[35px]"
                            dangerouslySetInnerHTML={{ __html: t("contactPage.title", { color: "#5FB8DF" }) }}
                        />
                        <div className={"md:text-xl"}>{t("contactPage.firstSubtitle")}</div>
                        <div className={"mt-1 md:mt-2 "}>{t("contactPage.secondSubtitle")}</div>
                        <LazyLoadImage
                            src={playa}
                            alt={"Playa"}
                            title={"Playa"}
                            className={"mb-5 md:mb-0 mt-5 w-[366px] rounded-lg"}
                        />
                        <div className="block md:hidden">
                            <ContactUsForm />
                        </div>
                        <ContactUsInfo />
                        <ContactUsAddresses />
                    </div>
                    <div className="relative hidden md:block">
                        <div className={"sticky top-[130px]"}>
                            <ContactUsForm className={"md:min-w-[469px]"} />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
