import React from "react";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import land from "../../assets/images/icons/land.svg";
import houseBuilding from "../../assets/images/icons/UnitModalHeader/houseBuilding.svg";

import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import PropertyHeaderTextList from "properties/propertyPage/PropertyHeaderTextList";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";

export function PropertyLuxuryLandDetails() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const details = [
        {
            icon: houseBuilding,
            render: (property: TPropertyListingMdl) => {
                if (!property.type) return null;
                return (
                    <div className={"text-lg capitalize"}>
                        {t("propertyPage.header.propertyType", {
                            type: `${property.type}`,
                        })}
                    </div>
                );
            },
        },
        {
            icon: land,
            render: (property: TPropertyListingMdl) => {
                if (!property.squareSurface.min || !property.squareSurface.max) return null;
                return (
                    <div className={"text-lg"}>
                        <div>
                            {t("propertyPage.header.landAreaMin", {
                                ft2: convert(property.squareSurface.min, UNIT_CONVERSION.M2_TO_FT2_BACK),
                                m2: property.squareSurface.min,
                            })}
                        </div>
                        <div>
                            {t("propertyPage.header.landAreaMax", {
                                ft2: convert(property.squareSurface.max, UNIT_CONVERSION.M2_TO_FT2_BACK),
                                m2: property.squareSurface.max,
                            })}
                        </div>
                    </div>
                );
            },
        },
    ];

    return <PropertyHeaderTextList details={details} property={property} />;
}
