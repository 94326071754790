import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    count: number;
};

export function InventoryUnitTypeHeader({ count }: Props) {
    const { t } = useTranslation();

    return (
        <div className={'hidden sm:flex gap-2 text-xl mb-2 "mb-5"'}>
            <div>{t("property.lotsAvailable", { count: count })}</div>
        </div>
    );
}
