import React, { lazy, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "./_css/app.css";
import "./_css/fonts/icomoon.css";
import "./_css/fonts/font.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Suspenser from "_common/loaders/Suspenser";
import favicon from "./assets/images/favicon.jpg";
import { URLS } from "_configs/URLS";
import { ToastContainer } from "react-toastify";
import { OverlayObserver } from "main/overlay/OverlayObserver";
import { NoSsr } from "@material-ui/core";
import { PageTracker } from "pages/PageTracker";
import { Cookie } from "main/cookieBanner/Cookie";
import { useTranslation } from "react-i18next";
import thumbnail from "./assets/images/thumbnail.jpg";
import { useStaticRendering } from "mobx-react";
import sharedConfig from "_configs/sharedConfig";
import { DASHBOARD_URLS } from "users/dashboard/_configs/DASHBOARD_URLS";
import { AuthenticatedRoute } from "_common/_utils/AuthenticatedRoute";
import { UserSelectedOverlay } from "main/customOverlay/UserSelectedOverlay";
import { AppContent } from "AppContent";

const LazyAdminRootPage = lazy(() => import("./admin/AdminRootPage"));
const LazyDashboardRootPage = lazy(() => import("users/DashboardRootPage"));
const LazyAuthPage = lazy(() => import("users/auth/AuthPage"));

export const App: React.FC = () => {
    const { t } = useTranslation();
    useEffect(() => {
        // @typescript-eslint/no-var-requires
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const WebFont = require("webfontloader");
        WebFont.load({
            google: {
                families: ["Roboto", "Material+Icons"],
            },
        });
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStaticRendering(!__BROWSER__);
    return (
        <>
            <Helmet defaultTitle={t("meta.title")} link={[{ rel: "icon", type: "image/png", href: favicon }]}>
                <meta name="robots" content={sharedConfig.isProd ? "INDEX,FOLLOW" : "NOINDEX,NOFOLLOW"} />
                <meta name="description" content={t("meta.description")} />
                <meta name="image" content={thumbnail} />
                <meta name="og:image" content={thumbnail} />
                <meta name="twitter:image" content={thumbnail} />
            </Helmet>
            <StickyContainer>
                <PageTracker>
                    <Switch>
                        <AuthenticatedRoute
                            path={URLS.auth.base()}
                            component={() => {
                                return (
                                    <Suspenser>
                                        <LazyAuthPage />
                                    </Suspenser>
                                );
                            }}
                            reverse={true}
                        />
                        <Route
                            path={URLS.admin()}
                            component={() => (
                                <Suspenser>
                                    <LazyAdminRootPage />
                                </Suspenser>
                            )}
                        />
                        <Route
                            path={DASHBOARD_URLS.home(":lang")}
                            render={() => {
                                return (
                                    <Suspenser>
                                        <LazyDashboardRootPage />
                                    </Suspenser>
                                );
                            }}
                        />
                        <Route path="/" component={AppContent} />
                    </Switch>
                </PageTracker>
            </StickyContainer>
            <NoSsr>
                <Cookie />
                <ToastContainer />
                <OverlayObserver />
                <UserSelectedOverlay />
            </NoSsr>
        </>
    );
};
