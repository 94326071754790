import React from "react";

type Props = {
    size?: number;
    color?: string;
};

export function IconBuildableHouse({ color, size }: Props) {
    return (
        <svg
            width={size ?? "30"}
            height={size ?? "30"}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4548_33766)">
                <path
                    d="M4.58774 15.4202L5.07148 15.0208V20.5046C5.07148 20.8401 5.34319 21.1097 5.67666 21.1097H16.57C16.9056 21.1097 17.1752 20.838 17.1752 20.5046V15.0208L17.659 15.4202C17.7722 15.5128 17.908 15.5581 18.0439 15.5581C18.2189 15.5581 18.3918 15.4819 18.5112 15.3378C18.7232 15.0805 18.6882 14.6976 18.4288 14.4856L11.5062 8.77957C11.2819 8.59431 10.9587 8.59431 10.7364 8.77957L3.81376 14.4856C3.55645 14.6976 3.5194 15.0805 3.73142 15.3378C3.94344 15.5972 4.32631 15.6322 4.58362 15.4202H4.58774ZM9.82034 19.8994V16.3259H12.4264V19.8994H9.82034ZM11.1233 10.0332L15.9648 14.0245V19.9014H13.6388V15.7228C13.6388 15.3872 13.3671 15.1176 13.0336 15.1176H9.21516C8.87963 15.1176 8.60997 15.3893 8.60997 15.7228V19.9014H6.28391V14.0245L11.1254 10.0332H11.1233Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M18.0499 23.2578C17.7144 23.2578 17.4447 23.5295 17.4447 23.863V24.3117H4.81194V23.863C4.81194 23.5275 4.54022 23.2578 4.20675 23.2578C3.87328 23.2578 3.60156 23.5295 3.60156 23.863V25.975C3.60156 26.3105 3.87328 26.5802 4.20675 26.5802C4.54022 26.5802 4.81194 26.3084 4.81194 25.975V25.5262H17.4447V25.975C17.4447 26.3105 17.7164 26.5802 18.0499 26.5802C18.3834 26.5802 18.6551 26.3084 18.6551 25.975V23.863C18.6551 23.5275 18.3834 23.2578 18.0499 23.2578Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M25.5187 0C23.0465 0 21.0375 2.01112 21.0375 4.48127V4.8065H0.605187C0.269658 4.8065 0 5.07822 0 5.41169V29.3928C0 29.7283 0.271717 29.9979 0.605187 29.9979H25.5167C27.9889 29.9979 29.9979 27.9868 29.9979 25.5167V4.48127C29.9979 2.01112 27.9868 0 25.5167 0H25.5187ZM1.21243 28.7876V6.01894H21.0354V25.5167C21.0354 26.8053 21.583 27.9683 22.4557 28.7855H1.21243V28.7876ZM25.5187 28.7876C23.7155 28.7876 22.2499 27.3199 22.2499 25.5187C22.2499 23.7176 23.7176 22.2499 25.5187 22.2499C27.3199 22.2499 28.7876 23.7176 28.7876 25.5187C28.7876 27.3199 27.3199 28.7876 25.5187 28.7876ZM25.5187 21.0354C24.2301 21.0354 23.0671 21.583 22.2499 22.4557V4.48127C22.2499 2.67806 23.7176 1.21243 25.5187 1.21243C27.3199 1.21243 28.7876 2.68012 28.7876 4.48127V22.4578C27.9683 21.585 26.8073 21.0375 25.5187 21.0375V21.0354Z"
                    fill={color ?? "#1A3A45"}
                />
                <path
                    d="M25.5192 24.6094C25.0169 24.6094 24.6094 25.0169 24.6094 25.5192C24.6094 26.0215 25.0169 26.4291 25.5192 26.4291C26.0215 26.4291 26.4291 26.0215 26.4291 25.5192C26.4291 25.0169 26.0215 24.6094 25.5192 24.6094Z"
                    fill={color ?? "#1A3A45"}
                />
            </g>
            <defs>
                <clipPath id="clip0_4548_33766">
                    <rect width="30" height="30" fill={color ?? "#1A3A45"} />
                </clipPath>
            </defs>
        </svg>
    );
}
