import React from "react";
import { TImage } from "_common/types/baseTypes";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet-async";
import { NoSsr } from "@material-ui/core";
import { CardFavoriteButton } from "properties/propertyCard/components/CardFavoriteButton";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";

type Props = {
    images: TImage[];
    unitOrUnitType?: TUnitListingMdl | TUnitTypeListingMdl;
    resource: MAIN_RESOURCE;
};

export function CardGallery({ images, unitOrUnitType, resource }: Props) {
    return (
        <div className={"group relative w-full h-full"}>
            <Helmet>
                <link rel="preload" as="image" href={images?.[0]?.thumbnail} />
                <link rel="preload" as="image" href={images?.[1]?.thumbnail} />
            </Helmet>

            <LazyLoadImage
                src={images?.[1]?.thumbnail ?? images?.[1]?.url}
                alt={"property image 1"}
                title={`photo 1`}
                className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ease-in-out opacity-0 group-hover:opacity-100 rounded-lg"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                }}
            />

            <LazyLoadImage
                src={images?.[0]?.thumbnail ?? images?.[0]?.url}
                alt={"property image 2"}
                title={`photo 2`}
                className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ease-in-out opacity-100 group-hover:opacity-0 rounded-lg"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://placehold.co/300x200?text=Zisla.com`;
                }}
            />
            {unitOrUnitType && (
                <NoSsr>
                    <CardFavoriteButton item={unitOrUnitType} resource={resource} />
                </NoSsr>
            )}
        </div>
    );
}
