import React, { lazy, Suspense } from "react";
import { Loader } from "_common/loaders/Loader";

const LazyMapRenderLeaflet = lazy(() => import("./PropertyMap"));

export function PropertyMapRender() {
    return (
        <div>
            <div className={"relative h-[55vh] w-full"}>
                {__BROWSER__ ? (
                    <Suspense fallback={<Loader />}>
                        <LazyMapRenderLeaflet />
                    </Suspense>
                ) : (
                    <div>
                        <Loader />
                    </div>
                )}
            </div>
            <div className={"mt-10 w-full"} />
        </div>
    );
}
