import React from "react";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { URLS } from "_configs/URLS";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { reformatStringForUrls, reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";
import { useTranslation } from "react-i18next";

export function PropertiesBreadcrumb() {
    const { t } = useTranslation();
    if (!geoZonesStore.geoZone) return null;
    const { isProvince, isCity, isNeighbourhood } = geoZonesStore.isType;

    const items = [
        {
            itemLabel: t("propertyPage.research"),
            url: URLS.buy(),
            forceReload: true,
        },
    ];
    if (!isProvince) {
        items.push({
            itemLabel:
                geoZonesStore.geoZone.address.provinceLong ??
                reformatUrlForOriginalName(geoZonesStore.geoZone.address.province),
            url: URLS.buy(
                undefined,
                reformatStringForUrls(
                    geoZonesStore.geoZone.address.provinceLong ??
                        reformatStringForUrls(geoZonesStore.geoZone.address.province),
                ),
            ),
            forceReload: true,
        });
    }
    if (isNeighbourhood) {
        items.push({
            itemLabel: geoZonesStore.geoZone.address.city,
            url: URLS.buy(
                undefined,
                reformatStringForUrls(geoZonesStore.geoZone.address.province),
                reformatStringForUrls(geoZonesStore.geoZone.address.city),
            ),
            forceReload: true,
        });
    }
    return (
        <Breadcrumb
            items={items}
            currentItem={{
                itemLabel: isCity
                    ? geoZonesStore.geoZone.address.city
                    : isNeighbourhood
                    ? geoZonesStore.geoZone.address.neighbourhood
                    : geoZonesStore.geoZone.address.provinceLong ??
                      reformatUrlForOriginalName(geoZonesStore.geoZone.address.province),
            }}
            noHome
            noMarginBottom
        />
    );
}
