import React from "react";
import { PropertyCardPrice } from "properties/propertyCard/components/PropertyCardPrice";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { useTranslation } from "react-i18next";

interface Props {
    range: "min" | "max";
}

export default function PropertyPriceCardMinMax({ range }: Props) {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const priceRange = range === "min" ? property.price?.min ?? 0 : property.price?.max ?? 0;
    const priceInPesosRange = range === "min" ? property.priceInPesos?.min : property.priceInPesos?.max;
    return (
        <div className="flex items-start">
            <div className="ml-2.5 p-3 rounded-lg border border-1 border-[#1A3A45] flex flex-col">
                <div className="flex">
                    <div className="rounded-lg bg-[#52B5DB] text-[#1A3A45] px-2 text-sm mb-2">
                        {range === "min" ? t("propertyPage.minPrice") : t("propertyPage.maxPrice")}
                    </div>
                </div>
                <div className={"font-semibold text-xl mb-2"}>
                    {property.priceIsNotDisplayed ? (
                        <>{t("propertiesPage.card.priceOnRequest")}</>
                    ) : (
                        <>
                            {isInPesosProperty(property)
                                ? formatPesosPrice(priceInPesosRange ?? 0)
                                : formatPrice(priceRange)}
                        </>
                    )}
                </div>
                <PropertyCardPrice
                    isInPesosProperty={isInPesosProperty(property)}
                    isPriceNotDisplayed={property.priceIsNotDisplayed}
                    priceInPesosMin={property.priceInPesos?.min ?? 0}
                    priceMin={priceRange}
                />
            </div>
        </div>
    );
}
