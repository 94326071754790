import React from "react";
import { PropertiesPage } from "properties/PropertiesPage";
import { PropertiesPageContext } from "properties/PropertiesPageContext";
import { propertiesStore } from "properties/_stores/propertiesStore";

export function BuyPage() {
    return (
        <PropertiesPageContext store={propertiesStore}>
            <PropertiesPage />
        </PropertiesPageContext>
    );
}
