import React from "react";

interface Props {
    title: string;
    description: string;
}

export default function UnitModalHeaderListText({ title, description }: Props) {
    return (
        <div className="flex">
            <p className="font-semibold text-sm text-[#1A3A45]">{title}</p>
            <p className="ml-1 md:ml-2 text-sm text-[#1A3A45]">{description}</p>
        </div>
    );
}
