import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
    description?: string;
};

export function PropertyDescription(props: Props) {
    const { t } = useTranslation();
    let height = 0;
    const ref = useRef<HTMLDivElement>(null);
    const [isCollapsed, setIsCollapsed] = useState(height > 130);

    useEffect(() => {
        if (ref.current) {
            height = ref.current.offsetHeight;
        }
        setIsCollapsed(height > 130);
    }, [ref?.current]);

    if (!props.description) return null;

    return (
        <div className={"flex flex-col"}>
            <Collapse in={!isCollapsed} collapsedHeight={100}>
                <div
                    dangerouslySetInnerHTML={{ __html: props.description }}
                    className={"pr-5 leading-5 text-[var(--color-primary)]"}
                    ref={ref}
                />
            </Collapse>
            <div
                className={"text-center flex items-center justify-center cursor-pointer mt-2.5"}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                {height < 100 && isCollapsed && <div className={"underline text-sm"}>{t("propertyPage.readMore")}</div>}
            </div>
        </div>
    );
}
