import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { toJS } from "mobx";
import { cn } from "_configs/twconfig";
import dayjs from "dayjs";

interface Props {
    onClick: (index: number) => void;
}

export default function PropertyViewConstructProgress({ onClick }: Props) {
    const { property } = usePropertyStore();

    const constructProgressImagesByDate = property?.constructProgress.map((progress) => ({
        date: progress.date,
        images: toJS(progress.images),
    }));

    const getIndex = (phaseNumber: number, index: number) => {
        let finalIndex = 0;
        for (let i = 0; i < phaseNumber; i++) {
            finalIndex += property.constructProgress[i].images.length;
        }
        return finalIndex + index;
    };

    return (
        <div className="my-5 mt-14">
            {constructProgressImagesByDate &&
                constructProgressImagesByDate.map((progress, dateIndex) => (
                    <div key={dateIndex}>
                        <div className="p-2">
                            <h2 className="text-lg my-4 w-full text-center border border-[#1a3a45] text-[#1a3a45] md:mt-0">
                                {dayjs(progress.date).format("MMMM YYYY")}
                            </h2>
                        </div>

                        <div className="flex flex-row flex-wrap justify-between">
                            {progress.images.map((picture, index) => {
                                const relativeIndex = index % 5;
                                const currentIndex = getIndex(dateIndex, index);

                                return (
                                    <img
                                        key={index}
                                        className={cn(
                                            "w-1/2 p-2 h-auto cursor-pointer my-3 select-none object-cover aspect-video",
                                            {
                                                "w-full": relativeIndex === 4,
                                            },
                                        )}
                                        src={picture.url}
                                        alt="Construct_progress"
                                        onClick={() => onClick(currentIndex)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ))}
        </div>
    );
}
