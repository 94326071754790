import React from "react";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { useCommunityStore } from "communities/CommunityContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { ArrowPaginated } from "_common/ui/swiper/ArrowPaginated";

const NAME = "communityFacts";

export function CommunitiesUsefulFacts() {
    const communityStore = useCommunityStore();
    const { t } = useTranslation();

    return (
        <div id={"communityFacts"} className={"mb-10"}>
            <CommunitiesTitle
                title={t("communitiesPage.usefulFacts")}
                delimiter={1}
                className={"!flex-row gap-2.5 md:!mb-8"}
            />
            <div className={"relative"}>
                <Swiper
                    className={"!py-0"}
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    slidesPerView={3}
                    spaceBetween={20}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        690: {
                            slidesPerView: 1.5,
                        },
                        1180: {
                            slidesPerView: 2.5,
                        },
                        1400: {
                            slidesOffsetBefore: 10,
                            slidesPerView: 3,
                        },
                    }}
                >
                    {communityStore.community.facts.map((fact, index) => (
                        <SwiperSlide key={index} className={"md:!w-[393px]"}>
                            <div
                                className={
                                    "flex flex-col p-5 gap-5 border border-[var(--color-secondary)] rounded-lg h-[310px]"
                                }
                            >
                                <p className={"font-semibold"}>{fact.title}</p>
                                <p className={"overflow-auto"}>{fact.description}</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="mt-3.5 mb-5 absolute right-0">
                    <ArrowPaginated numberOfSection={communityStore.community.facts.length} name={NAME} />
                </div>
            </div>
        </div>
    );
}
