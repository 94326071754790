import React from "react";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { useTranslation } from "react-i18next";
import styles from "./_css/typeSubMenu.module.css";
import { PROPERTY_TYPES_WITH_BEACHFRONT } from "_common/propertyTypes/propertyTypes";
import { NavSrpsLink } from "_common/ui/links/NavSrpsLink";
import { Link } from "react-router-dom";

export function TypeSubMenu() {
    const { t } = useTranslation();
    const { city, neighbourhood, province } = geoZonesStore.address;

    return (
        <ul className={styles.container}>
            {PROPERTY_TYPES_WITH_BEACHFRONT.map((propertyType) => {
                const link = city
                    ? URLS.buy(
                          i18next.language,
                          reformatStringForUrls(province),
                          reformatStringForUrls(city),
                          neighbourhood
                              ? reformatStringForUrls(neighbourhood)
                              : t("property.types." + propertyType.name).toLowerCase(),
                      )
                    : URLS.buy(i18next.language, t("property.types." + propertyType.name).toLowerCase());
                return (
                    <li key={propertyType.name}>
                        <NavSrpsLink propertyType={propertyType.name as PROPERTY_TYPE} to={link}>
                            {t("property.types." + propertyType.name)}
                        </NavSrpsLink>
                    </li>
                );
            })}
            <li>
                <Link
                    to={URLS.buy(
                        i18next.language,
                        reformatStringForUrls(province),
                        reformatStringForUrls(city),
                        neighbourhood ? reformatStringForUrls(neighbourhood) : undefined,
                    )}
                >
                    {t("menu.allProperties")}
                </Link>
            </li>
        </ul>
    );
}
