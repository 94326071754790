import React from "react";
import { DialogContent } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { useCommunityStore } from "communities/CommunityContext";

type Props = {
    isOpen: boolean;
    setIsOpen: () => void;
    currentImage: number;
};
SwiperCore.use([Navigation]);
export function CommunitiesDialogSlider(props: Props) {
    const communityStore = useCommunityStore();

    const NAME = "communitiesImageSlider";

    return (
        <Dialog fullScreen closeCross open={props.isOpen} onClose={props.setIsOpen}>
            <DialogContent className={"flex justify-center"}>
                <div id={NAME} className={"relative w-full flex justify-center items-center md:!max-w-[1260px]"}>
                    <Swiper
                        loop
                        slidesPerView={1}
                        centeredSlides
                        navigation={{
                            prevEl: `.swiper-button-prev.${NAME}`,
                            nextEl: `.swiper-button-next.${NAME}`,
                        }}
                        spaceBetween={20}
                        initialSlide={props.currentImage}
                        className={"h-full"}
                    >
                        {communityStore.community.photos?.map((picture, idx) => (
                            <SwiperSlide key={idx} className={"flex items-center justify-center"}>
                                <img
                                    className={"rounded-lg w-full object-cover cursor-pointer"}
                                    src={picture.url}
                                    alt={"community_picture"}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className={`swiper-button-prev ${NAME}`} />
                    <div className={`swiper-button-next ${NAME}`} />
                </div>
            </DialogContent>
        </Dialog>
    );
}
