import React from "react";
import ShareButton from "properties/propertyPage/propertyView/ShareButton";
import ViewFavoriteButton from "properties/propertyPage/propertyView/ViewFavoriteButton";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import clsx from "clsx";
import close from "../../../assets/images/icons/closeModal.svg";
import { Separator } from "_common/ui/components/Separator";
import { GALLERY_TABS } from "_configs/propertyConfig";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { galleryStore } from "gallery/_store/GalleryStore";

interface Props {
    handleClose: () => void;
}

export default function NavigationBar({ handleClose }: Props) {
    const propertyStore = usePropertyStore();
    const property = propertyStore.property;
    const { t } = useTranslation();

    const hasShowroom = property.showroom && property.showroom.length > 0 && property.showroom[0]?.url;
    const hasVideo = !!property.video;
    const hasFloor = property.floorPlans && property.floorPlans.length > 0 && property.floorPlans[0]?.url;
    const hasFinishings = property.finishings && property.finishings.length > 0 && property.finishings[0]?.url;
    const hasPreviousProjects =
        property.previousProjects && property.previousProjects.length > 0 && property.previousProjects[0]?.url;
    const hasConstructProgress =
        property.constructProgress &&
        property.constructProgress.length > 0 &&
        property.constructProgress[0].images[0]?.url;

    const has3dTour = property.visit3D && property.visit3D.length > 0;

    const links = [
        { name: `${t("property.galleryNavbar.mainGallery")} (${property.photos?.length})`, type: GALLERY_TABS.GALLERY },
        ...(hasShowroom
            ? [
                  {
                      name: `${t("property.galleryNavbar.showroom")} (${property.showroom?.length})`,
                      type: GALLERY_TABS.SHOWROOM,
                  },
              ]
            : []),
        ...(hasVideo
            ? [
                  {
                      name: `${t("property.galleryNavbar.video")} (${
                          (property?.video ? 1 : 0) + (property?.video2 ? 1 : 0)
                      })`,
                      type: GALLERY_TABS.VIDEO,
                  },
              ]
            : []),
        ...(hasFloor
            ? [
                  {
                      name: `${t("property.galleryNavbar.levelPlan")} (${property.floorPlans.length})`,
                      type: GALLERY_TABS.LEVEL_PLAN,
                  },
              ]
            : []),
        ...(hasFinishings
            ? [
                  {
                      name: `${t("property.galleryNavbar.finishings")} (${property.finishings.length})`,
                      type: GALLERY_TABS.FINISHINGS,
                  },
              ]
            : []),
        ...(hasPreviousProjects
            ? [
                  {
                      name: `${t("property.galleryNavbar.previousProjects")} (${property.previousProjects.length})`,
                      type: GALLERY_TABS.PREVIOUS_PROJECTS,
                  },
              ]
            : []),
        ...(hasConstructProgress
            ? [
                  {
                      name: `${t("property.galleryNavbar.constructProgress")} (${property.constructProgress.reduce(
                          (totalImages, progressItem) => totalImages + progressItem.images.length,
                          0,
                      )})`,
                      type: GALLERY_TABS.CONSTRUCT_PROGRESS,
                  },
              ]
            : []),
        ...(has3dTour
            ? [
                  {
                      name: `${t("property.galleryNavbar.visit3D")}`,
                      type: GALLERY_TABS.TOUR_3D,
                  },
              ]
            : []),
    ];

    return (
        <div className="fixed top-0 left-0 w-full bg-white z-50">
            <div className="absolute right-5 top-3 z-60 cursor-pointer" onClick={handleClose}>
                <img src={close} className="h-4" alt="close-modal" />
            </div>
            <div className="flex flex-col-reverse justify-between sm:items-center mx-4 sm:mx-14 my-4 gap-4 lg:flex-row md:gap-0">
                <nav className="flex space-x-5 z-50 overflow-x-auto whitespace-nowrap md:overflow-visible no-scrollbar">
                    {links.map((link, idx) => (
                        <p
                            key={idx}
                            className={clsx("cursor-pointer shrink-0", {
                                underline: link.type === galleryStore.openedGalleryListTab,
                            })}
                            onClick={() => galleryStore.setOpenedGalleryTab(link.type)}
                        >
                            {link.name}
                        </p>
                    ))}
                </nav>
                <Separator className={"sm:hidden"} />
                <div className="flex gap-x-5 justify-between items-center">
                    <button
                        className="w-52 text-white bg-[#1A3A45] rounded-full py-2.5"
                        onClick={() => galleryStore.openContactForm({ resource: MAIN_RESOURCE.PROPERTIES })}
                    >
                        {t("property.galleryNavbar.contact")}
                    </button>
                    <div className="flex mr-8 sm:mr-0 gap-x-2">
                        <ShareButton />
                        <ViewFavoriteButton item={property} resource={MAIN_RESOURCE.PROPERTIES} />
                    </div>
                </div>
            </div>
        </div>
    );
}
