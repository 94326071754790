// SurfaceContext.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { SURFACE_OPTIONS } from "_common/surfaces/SurfaceTypes";

type SurfaceContextType = {
    currentSurface: SURFACE_OPTIONS;
    setSurface: (surface: SURFACE_OPTIONS) => void;
};

const SurfaceContext = createContext<SurfaceContextType | undefined>(undefined);

type SurfaceProviderProps = {
    children: ReactNode;
};

export const SurfaceProvider: React.FC<SurfaceProviderProps> = ({ children }: SurfaceProviderProps) => {
    const defaultValue: SURFACE_OPTIONS = __BROWSER__
        ? (localStorage.getItem("surface") as SURFACE_OPTIONS) || SURFACE_OPTIONS.m
        : SURFACE_OPTIONS.m;
    const [currentSurface, setCurrentSurface] = useState<SURFACE_OPTIONS>(defaultValue);

    const setSurface = (surface: SURFACE_OPTIONS) => {
        setCurrentSurface(surface);
    };

    useEffect(() => {
        if (__BROWSER__) {
            localStorage.setItem("surface", currentSurface);
        }
    }, [currentSurface]);

    return <SurfaceContext.Provider value={{ currentSurface, setSurface }}>{children}</SurfaceContext.Provider>;
};

export const useSurface = () => {
    const context = useContext(SurfaceContext);
    if (!context) {
        throw new Error("useSurface must be used within a SurfaceProvider");
    }
    return context;
};
