import React from "react";
import { useFavoriteToggle } from "users/profile/favorite/useFavoriteToggle";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { IconFavorite } from "properties/propertyPage/icons/IconFavorite";
import { TPropertyCard, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";

type Props = {
    resource: MAIN_RESOURCE;
    item: TPropertyCard | TPropertyListingMdl | TUnitListingMdl;
};

export default function ViewFavoriteButton({ resource, item }: Props) {
    const { isFavorite, toggleFavorite } = useFavoriteToggle(resource, item);

    return (
        <div
            className={
                "border border-[#1A3A45] rounded-full p-2 flex items-center justify-center cursor-pointer bg-white"
            }
            onClick={() => toggleFavorite()}
        >
            <IconFavorite color={"black"} size={"15"} isFavorite={isFavorite} />
        </div>
    );
}
