import { TInventory, TPhase, TPropertyCard, TPropertyPage } from "properties/_models/PropertyMdl";
import { action, observable } from "mobx";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import sharedConfig from "_configs/sharedConfig";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import { TUnitAvailableMdl } from "unitAvailable/_models/UnitAvailableMdl";

export class PropertyStore {
    @observable property: TPropertyPage;
    @observable appointmentModalIsOpen = false;
    @observable infosModalIsOpen = false;
    @observable imageModalIsOpen = -1;
    @observable historyModalIsOpen = false;
    @observable typeOfImage = RESOURCE_KEY.PHOTOS;
    @observable selectedPhase: TPhase | undefined;
    @observable selectedInventory: TInventory | undefined;
    @observable isSquareSurfaceInSquareMeter = false;

    @observable similarPropertiesState = new LoadingStateMdl<TPropertyCard[]>();
    @observable similarPremiumPropertiesState = new LoadingStateMdl<TPropertyCard[]>();
    @observable similarProperties: TPropertyCard[] = [];
    @observable similarPremiumProperties: TPropertyCard[] = [];

    @observable logHistory: TUnitAvailableMdl[] = [];
    @observable logHistoryState = new LoadingStateMdl<TUnitAvailableMdl[]>();

    constructor(property: TPropertyPage) {
        this.property = property;
    }

    @action setIsSquareSurfaceInSquareMeter(isSquareSurfaceIsInMeter: boolean) {
        this.isSquareSurfaceInSquareMeter = isSquareSurfaceIsInMeter;
    }

    @action setAppointmentModalClose() {
        this.appointmentModalIsOpen = false;
    }

    @action setAppointmentModalOpen() {
        this.appointmentModalIsOpen = true;
    }

    @action setHistoryModalIsClose() {
        this.historyModalIsOpen = false;
    }

    @action setHistoryModalIsOpen() {
        this.historyModalIsOpen = true;
    }

    @action setInfosModalClose() {
        this.infosModalIsOpen = false;
    }

    @action setInfosModalOpen() {
        this.infosModalIsOpen = true;
    }

    @action setOpenedImageModal(typeOfImage: RESOURCE_KEY, imageIndex?: number) {
        this.typeOfImage = typeOfImage;
        this.imageModalIsOpen = imageIndex ?? 0;
    }

    @action closeImageModal() {
        this.imageModalIsOpen = -1;
    }

    fetchSimilarPremiumProperties() {
        return this.fetchSimilarProperties(this.similarPremiumPropertiesState, true);
    }

    fetchSimilarRegularProperties() {
        return this.fetchSimilarProperties(this.similarPropertiesState);
    }

    private fetchSimilarProperties(state: LoadingStateMdl<TPropertyCard[]>, premium = false) {
        if (!state.isLoading && !state.isSucceeded) {
            state.startLoading();
            const filters = [
                {
                    id: "type",
                    value: this.property.type,
                    type: TFilterType.STRING,
                },
                {
                    id: "premiumPlan",
                    value: 0,
                    type: TFilterType.NUMBER,
                    op: premium ? "gt" : "eq",
                },
            ];
            const sort = {
                "address.neighbourhood": 1,
                "address.city": 1,
                premiumPlan: -1,
                position: 1,
                sold: 1,
            };
            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const sortParam = filters.length > 0 ? `&sort=${JSON.stringify(sort)}` : "";
            const limit = 5;
            const url = `${sharedConfig.apiUrl}/properties/listing?offset=0&limit=${limit}${sortParam}${filtersParam}`;
            const promise = fetchUtils.get<{ items: TPropertyCard[] }>(url);
            promise.then(
                action(({ data }) => {
                    if (premium) {
                        this.similarPremiumProperties = data.items;
                        state.setSuccess(data.items);
                    } else {
                        this.similarProperties = data.items;
                        state.setSuccess(data.items);
                    }
                }),
                action((error) => {
                    state.setError(error);
                }),
            );
        }
    }
}
