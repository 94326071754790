import React from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { UnitHeaderDetails } from "units/UnitHeaderDetails";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { galleryStore } from "gallery/_store/GalleryStore";
import { useTranslation } from "react-i18next";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { UnitHeaderPrices } from "units/UnitHeaderPrices";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";

type Props = {
    unit: TUnitListingMdl;
};

export function UnitHeader({ unit }: Props) {
    const { property } = usePropertyStore();
    const { t } = useTranslation();

    const itemForPrice = {
        title: unit.title,
        name: `#${unit.name}`,
        price: unit.price,
        priceInPesos: unit.priceInPesos,
        isInPesosUnit: isInPesosProperty(property),
        priceIsNotDisplayed: property.priceIsNotDisplayed,
        fees: property?.fees?.applyOnUnits ? property?.fees : unit?.fees,
    };
    const itemForDetails = {
        floor: unit.floor,
        bedrooms: unit.bedrooms,
        bathrooms: unit.bathrooms,
        powderRooms: unit.powderRooms,
        surface: unit.totalConstructArea,
        surfaces: {
            interiorConstruction: unit.interiorConstruction,
            exteriorConstruction: unit.exteriorConstruction, // old terraceSurface
            terraceSurface: unit.terraceSurface,
            rooftopSurface: unit.rooftopSurface,
            yardSize: unit.yardSize,
            poolSurface: unit.poolSurface,
            storageSurface: unit.storageSurface,
            parkingSurface: unit.parkingSurface,
            totalConstructArea: unit.totalConstructArea, // old squareSurface if condo
            lotSurface: unit.lotSurface, // old squareSurface if not condo
            cus: unit.cus,
            cos: unit.cos,
        },
        parkingSurface: unit.parkingSurface,
        views: unit.views,
        unitType: property.type === PROPERTY_TYPE.condo ? unit.type : undefined,
        priceSurface: unit.priceSurface,
    };
    return (
        <div className="flex flex-col mb-32 gap-5 md:gap-0 md:flex-row">
            <UnitHeaderPrices item={itemForPrice} />
            <UnitHeaderDetails item={itemForDetails} />
            <button
                className="w-52 text-white bg-[#1A3A45] rounded-full py-2.5 md:hidden"
                onClick={() =>
                    galleryStore.openContactForm({
                        resource: MAIN_RESOURCE.UNIT,
                        title: t("unitPage.form.title", { unit: unit.title, property: property.localized.title }),
                    })
                }
            >
                {t("property.galleryNavbar.contact")}
            </button>
        </div>
    );
}
