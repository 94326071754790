import React, { useContext, useEffect, useState } from "react";
import { PROPERTY_VIEW_STYLE } from "properties/components/BREAKPOINTS_SECTION_CARDS";

const PropertyPageContext = React.createContext(
    {} as {
        mapOrListSelected: PROPERTY_VIEW_STYLE;
        setMapOrListSelected: (mapOrList: PROPERTY_VIEW_STYLE) => void;
    },
);

export function usePropertyPageContext() {
    return useContext(PropertyPageContext);
}

export function PropertiesViewContext(props: React.PropsWithChildren<any>) {
    const [mapOrListSelected, setMapOrListSelectedContext] = useState<PROPERTY_VIEW_STYLE>(PROPERTY_VIEW_STYLE.LIST);
    useEffect(() => {
        if (localStorage.getItem("viewStyle")) {
            setMapOrListSelectedContext(localStorage.getItem("viewStyle") as PROPERTY_VIEW_STYLE);
        }
    }, []);

    const setMapOrListSelected = (viewStyle: PROPERTY_VIEW_STYLE) => {
        setMapOrListSelectedContext(viewStyle);
        localStorage.setItem("viewStyle", viewStyle);
    };

    return (
        <PropertyPageContext.Provider
            value={{
                mapOrListSelected,
                setMapOrListSelected,
            }}
        >
            {props.children}
        </PropertyPageContext.Provider>
    );
}
