import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { numberFormatter } from "_common/_utils/currencyUtils";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";

export const PropertiesCount = observer(() => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);

    return (
        <div className={"text-xs font-light flex items-center gap-1"}>
            <span className={"truncate"}>
                {t("propertiesPage.listingNumber", {
                    count: listStore.count,
                })}{" "}
                /{" "}
                {t("propertiesPage.listingUnitsMatched", {
                    number: numberFormatter(propertiesStore.countUnits),
                })}
            </span>
        </div>
    );
});
