import React, { Fragment, lazy } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Dialog } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import UnitViewGalleryNavbar from "units/UnitViewGalleryNavbar";
import { galleryStore, TGalleryImage } from "gallery/_store/GalleryStore";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { GALLERY_NAME_MODAL } from "_configs/propertyConfig";
import { isImage } from "_common/_utils/fileUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Suspenser from "_common/loaders/Suspenser";
import { UnitGalleryModal } from "units/UnitGalleryModal";

SwiperCore.use([Navigation]);

type Props = {
    galleryImages: TGalleryImage[];
    item: TUnitListingMdl;
};

const LazyPropertyViewGalleryEmbed = lazy(() =>
    import("properties/propertyPage/propertyView/PropertyViewGalleryEmbed"),
);

export const GalleryListModal = observer(({ galleryImages, item }: Props) => {
    return (
        <>
            <Dialog
                open={
                    galleryStore.openedGalleryListTab !== "NONE" && galleryStore.modalName === GALLERY_NAME_MODAL.UNIT
                }
                onClose={() => galleryStore.closeGalleryTab()}
                fullScreen
            >
                <div className="flex flex-col relative select-none">
                    <UnitViewGalleryNavbar
                        handleClose={() => galleryStore.closeGalleryTab()}
                        galleryImages={galleryImages}
                        item={item}
                    />
                    <div className={"sm:h-[75px] h-[140px]"} />
                    <div className="px-0 md:px-24 lg:px-36">
                        {galleryImages.map((galleryImage) => {
                            if (galleryStore.openedGalleryListTab !== galleryImage.tabName) return null;
                            return (
                                <>
                                    {galleryImage.images.map((picture, index) => {
                                        return (
                                            <Fragment key={index}>
                                                {isImage(picture.url ?? "") ? (
                                                    <LazyLoadImage
                                                        key={index}
                                                        className="h-[50vw] m-auto cursor-pointer my-3 select-none object-cover"
                                                        src={picture.url}
                                                        alt={picture.alt}
                                                        onClick={() => {
                                                            galleryStore.setOpenedGalleryModal(index);
                                                        }}
                                                    />
                                                ) : (
                                                    <div className={"h-[calc(100vh-140px)] sm:h-[calc(100vh-75px)]"}>
                                                        <Suspenser>
                                                            <LazyPropertyViewGalleryEmbed
                                                                src={picture.url + "#toolbar=0&navpanes=0"}
                                                                width={"100%"}
                                                                height={"100%"}
                                                            />
                                                        </Suspenser>
                                                    </div>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </>
                            );
                        })}
                    </div>
                </div>
                <UnitGalleryModal galleryImages={galleryImages} item={item} />
            </Dialog>
        </>
    );
});
