import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import { ArticlesSimilar } from "blog/ArticlesSimilar";
import { ArticlesSearchBar } from "blog/ArticlesSearchBar";
import { ArticleCategories } from "blog/ArticleCategories";
import { ContactUsForm } from "contact/ContactUsForm";
import { AC_TAGS } from "users/_models/UserMdl";

type Props = {
    blog: IPageMdl;
    page: IPageMdl;
};

export function ArticleSideBar(props: Props) {
    return (
        <div className={"mt-8 md:mt-3"}>
            <div className={"md:w-[400px] p-2.5 flex flex-col items-center mx-auto"}>
                <div className={"w-full mb-2.5"}>
                    <ArticlesSearchBar isArticlePage />
                </div>
                <div className={"w-full"}>
                    <ArticleCategories />
                </div>
                <ArticlesSimilar blogId={props.page._id} blogType={props.blog.blogType} />
                <ContactUsForm tag={AC_TAGS.L7_BLOG} />
            </div>
        </div>
    );
}
