import React from "react";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";
import { CardContainer, CardGalleryContainer } from "properties/propertyCard/components/PropertyCardComponents";
import { CardGallery } from "unitTypes/CardGallery";
import { UnitTypeCardHeader } from "unitTypes/UnitTypeCardHeader";
import { UnitTypeCardInfos } from "unitTypes/UnitTypeCardInfos";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";

type Props = {
    unitType: TUnitTypeListingMdl;
    isInPesosProperty: boolean;
    onClick: () => void;
};

export function UnitTypeCard({ unitType, isInPesosProperty, onClick }: Props) {
    return (
        <CardContainer onClick={onClick}>
            <UnitTypeCardHeader unitType={unitType} />
            <CardGalleryContainer>
                <CardGallery images={unitType.photos ?? []} resource={MAIN_RESOURCE.UNIT_TYPES} />
            </CardGalleryContainer>
            <UnitTypeCardInfos unitType={unitType} isInPesosProperty={isInPesosProperty} />
        </CardContainer>
    );
}
