import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    translateKey: string;
    className?: string;
};

export function TranslateText({ translateKey, className }: Props) {
    const { t } = useTranslation();
    return <div className={className}>{t(translateKey)}</div>;
}
