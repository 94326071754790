import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PropertyCollapsibleCommunityCard } from "properties/propertyPage/propertyViewCollapsible/PropertyCollapsibleCommunityCard";

export function PropertyCommunities() {
    const { property } = usePropertyStore();
    if (!property.communities || property.communities.length === 0) return null;
    return (
        <div className={"flex flex-col gap-3"}>
            {property.communities.map((community) => {
                return <PropertyCollapsibleCommunityCard key={community._id} community={community} />;
            })}
        </div>
    );
}
