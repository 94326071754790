import React from "react";
import aboutUs from "../assets/images/homePage/abous-us.png";
import styles from "./_css/homeServiceCard.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type Props = {
    numberOfSection: number;
    content: {
        preTitleKey: string;
        titleKey: string;
        subTitleKey: string;
        textKey: string;
    };
};
export function HomeServiceCard(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx("flex flex-row justify-between", styles.container)}>
            <div className={"flex-3 position_relative"}>
                <div className={styles.preTitle}>{t(props.content.preTitleKey)}</div>
                <div className={clsx(styles.title, "headerHome colorPrimary")}>{t(props.content.titleKey)}</div>
                <div className={clsx(styles.subTitle, "headerHome colorSecondary")}>{t(props.content.subTitleKey)}</div>
                <div className={clsx("textAlign_center mt_20", styles.mobile)}>
                    <img src={aboutUs} alt={"about us image"} className={styles.img} />
                </div>
                <div className={clsx(styles.text, "mv_20 textHome colorPrimary")}>{t(props.content.textKey)}</div>
            </div>
            <div className={clsx("flex-3 textAlign_center", styles.imgContainerdesktop)}>
                <img src={aboutUs} alt={"about us image"} className={styles.img} />
            </div>
        </div>
    );
}
