import React from "react";

type Props = {
    videoLink?: string;
};

export function getYouTubeVideoId(url?: string) {
    if (!url) return "";
    const regExp = /^.*(youtu.be\/|v\/|watch\?v=|\/embed\/|\/watch\?v=|\/watch\?.+&v=)([^#&?]*).*/;
    const match = regExp.exec(url);

    return `https://www.youtube.com/embed/${match && match[2].length === 11 ? match[2] : ""}`;
}

export function CommunitiesDiscover(props: Props) {
    return (
        <div className={"w-full h-[250px] md:h-[615px]"}>
            <iframe
                width="100%"
                height="100%"
                src={getYouTubeVideoId(props.videoLink)}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
            ></iframe>
        </div>
    );
}
