import React from "react";
import { Separator } from "_common/ui/components/Separator";

interface Props {
    title: string;
    children: React.ReactNode;
    noMarginBottomTitle?: boolean;
    activeSeparator?: boolean;
}

export default function UnitSectionLayout({ title, children, noMarginBottomTitle, activeSeparator }: Props) {
    return (
        <div className="">
            <h2 className="text-2xl font-medium text-[#1A3A45]">{title}</h2>
            <div className={noMarginBottomTitle ? "mt-5 mb-12" : "my-12"}>{children}</div>
            {activeSeparator && <Separator className={"mb-10"} />}
        </div>
    );
}
